/* eslint-disable indent */
import { useReactiveVar } from '@apollo/client';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { LocationArchetype, LocationMetadata, Maybe } from '__generated__/graphql';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';
import { useMemo } from 'react';
interface LocationActionsCellProps {
  locationId?: Maybe<string>;
  name?: Maybe<string>;
  locationArchetype?: Maybe<LocationArchetype>;
  parentLocationId?: Maybe<string>;
  locationMetadata?: Maybe<LocationMetadata>;
}

export default function LocationActionsCell({
  locationId,
  name,
  locationArchetype,
  parentLocationId,
  locationMetadata,
}: LocationActionsCellProps) {
  const { dispatchModal } = useModal();
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const showMigrate = useMemo(() => {
    // if a building is at non root
    if (
      parentLocationId !== selectedOrg?.rootLocation?.id &&
      locationArchetype === LocationArchetype.Building
    ) {
      return true;
    }
    // if its a non-builidng location but no v2 attributes set
    return (
      !locationMetadata?.buildingId &&
      !locationMetadata?.floorId &&
      locationArchetype !== LocationArchetype.Building
    );
  }, [locationArchetype, locationMetadata, parentLocationId, selectedOrg?.rootLocation?.id]);
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
      <Typography fontWeight='bold' color='gray' variant='body2'>
        Actions
      </Typography>
      <ButtonGroup id={locationId ?? ''} disableElevation size='small'>
        {showMigrate && (
          <Button
            color='primary'
            onClick={() =>
              dispatchModal({
                type: ModalNotificationTypeEnum.LOCATION_MIGRATE,
                modalProps: {
                  v2: true,
                  accountId: selectedOrg?.id,
                  locationId: locationId,
                  archetype: locationArchetype,
                  name,
                  parentLocationId: parentLocationId,
                  onMigrateLocation: (_: string) => void 0,
                },
              })
            }
          >
            Migrate
          </Button>
        )}
        <Button
          color='error'
          onClick={() =>
            dispatchModal({
              type: ModalNotificationTypeEnum.REMOVE_LOCATION_CONFIRMATION_MODAL,
              modalProps: {
                v2: true,
                accountId: selectedOrg?.id,
                locationId: locationId,
                name,
                parentLocationId: parentLocationId,
                onRemoveLocation: (_: string) => void 0,
              },
            })
          }
        >
          Delete
        </Button>
      </ButtonGroup>
    </Box>
  );
}
