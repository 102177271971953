import { BinInterval, TimeInterval } from '__generated__/graphql';

export function replaceConfigDataIfSkipped(context, value, interval?: TimeInterval) {
  const intervalSteps = {
    [TimeInterval.PerMinute]: 60000,
    [TimeInterval.Hourly]: 3600000,
    [TimeInterval.Daily]: 86400000,
    [TimeInterval.Weekly]: 604800000,
    [TimeInterval.Monthly]: 2721600000,
    default: 1e9,
  };
  const step = intervalSteps[interval ?? 'default'] ?? intervalSteps.default;
  const p0x = context.p0.raw.x;
  const p1x = context.p1.raw.x;
  return p1x - p0x > step || context.p0.skip || context.p1.skip ? value : undefined;
}

export function replaceConfigDataIfSkippedBinPeriod(context, value, interval?: BinInterval) {
  const intervalSteps = {
    [BinInterval.OneMinute]: 60000,
    [BinInterval.FiveMinutes]: 300000,
    [BinInterval.FifteenMinutes]: 900000,
    [BinInterval.TenMinutes]: 600000,
    [BinInterval.ThirtyMinutes]: 1800000,
    [BinInterval.OneHour]: 3600000,
    default: 1e9,
  };
  const step = intervalSteps[interval ?? 'default'] ?? intervalSteps.default;
  const p0x = context.p0.raw.x;
  const p1x = context.p1.raw.x;
  return p1x - p0x > step || context.p0.skip || context.p1.skip ? value : undefined;
}
