/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  Paper,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SearchIcon from '@mui/icons-material/Search';
import type { Dayjs as DayjsType } from 'dayjs';
type Props = {
  onUpdateParentDateRange: (startDate: string, endDate: string) => void;
  isLoading?: boolean;
};

export enum DateRangeEnum {
  PAST_HOUR = 'Past hour',
  PAST_SIX_HOURS = 'Past 6 hours',
  PAST_DAY = 'Past day',
  PAST_THREE_DAYS = 'Past 3 days',
  PAST_WEEK = 'Past week',
  PAST_MONTH = 'Past month',
  PAST_3_MONTH = 'Past 3 months',
  PAST_6_MONTH = 'Past 6 months',
  PAST_YEAR = 'Past Year',
  CUSTOM = 'Custom',
  ALL_TIME = 'All time',
}

const { PAST_HOUR, PAST_SIX_HOURS, PAST_DAY, PAST_THREE_DAYS, PAST_WEEK, PAST_MONTH, PAST_3_MONTH, PAST_6_MONTH, PAST_YEAR, CUSTOM } =
  DateRangeEnum;

export const calculateDateRange = (
  selectedRange: DateRangeEnum
): { startDate: string; endDate: string } => {
  const milliSecondsInDay = 86400000;
  const milliSecondsInHour = 3600000;
  let millisecondOffset = 0;
  switch (selectedRange) {
    case PAST_HOUR:
      millisecondOffset = milliSecondsInHour;
      break;
    case PAST_SIX_HOURS:
      millisecondOffset = 6 * milliSecondsInHour;
      break;
    case PAST_DAY:
      millisecondOffset = milliSecondsInDay;
      break;
    case PAST_THREE_DAYS:
      millisecondOffset = 3 * milliSecondsInDay;
      break;
    case PAST_WEEK:
      millisecondOffset = 7 * milliSecondsInDay;
      break;
    case PAST_MONTH:
      millisecondOffset = 30 * milliSecondsInDay;
      break;
    case PAST_3_MONTH:
      millisecondOffset = 90 * milliSecondsInDay;
      break;
    case PAST_6_MONTH:
      millisecondOffset = 180 * milliSecondsInDay;
      break;
    case PAST_YEAR:
      millisecondOffset = 365 * milliSecondsInDay;
      break;
    case CUSTOM:
      break;
    default:
      break;
  }
  const startDate: string = (() => {
    const now = new Date();
    return new Date(now.getTime() - millisecondOffset).toISOString();
  })();
  const endDate: string = (() => new Date().toISOString())();
  return { startDate, endDate };
};

export default function DateRangePicker({ onUpdateParentDateRange, isLoading = false }: Props) {
  const [startDate, setStartDate] = useState<DayjsType | null>(null);
  const [endDate, setEndDate] = useState<DayjsType | null>(null);
  const [selectedValue, setSelectedValue] = useState<DateRangeEnum>(PAST_DAY);
  const showCustomRange = selectedValue === CUSTOM;

  const isSearchButtonDisabled =
    isLoading || (selectedValue === CUSTOM && (!startDate || !endDate));

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSelectDateRange = () => {
    if (selectedValue === CUSTOM) {
      if (!startDate || !endDate) {
        return;
      }
      const startDateFormatted = startDate.toISOString();
      const endDateFormatted = endDate.toISOString();
      onUpdateParentDateRange(startDateFormatted, endDateFormatted);
    } else {
      if (!showCustomRange && (startDate || endDate)) {
        setStartDate(null);
        setEndDate(null);
      }
      const updatedRangeSelection = calculateDateRange(selectedValue);
      const { startDate: updatedStartDate, endDate: updatedEndDate } = updatedRangeSelection;
      onUpdateParentDateRange(updatedStartDate, updatedEndDate);
    }
  };
  return (
    <Box
      component={Paper}
      flexDirection='column'
      width={550}
      padding={2}
      display='flex'
      gap={2}
      justifyContent='center'
      alignItems='center'
    >
      <Stack direction='row' alignItems='center' justifyContent='flex-start' gap={2}>
        <Typography>Filter by time range:</Typography>
        <FormControl>
          <Select
            size='medium'
            labelId='timeRange-label'
            id='timeRange-select'
            value={selectedValue}
            onChange={handleChange}
            sx={{ width: '100%', minWidth: 150, maxWidth: 200 }}
          >
            <MenuItem value={PAST_HOUR}>{PAST_HOUR}</MenuItem>
            <MenuItem value={PAST_SIX_HOURS}>{PAST_SIX_HOURS}</MenuItem>
            <MenuItem value={PAST_DAY}>{PAST_DAY}</MenuItem>
            <MenuItem value={PAST_THREE_DAYS}>{PAST_THREE_DAYS}</MenuItem>
            <MenuItem value={PAST_WEEK}>{PAST_WEEK}</MenuItem>
            <MenuItem value={PAST_MONTH}>{PAST_MONTH}</MenuItem>
            <MenuItem value={PAST_3_MONTH}>{PAST_3_MONTH}</MenuItem>
            <MenuItem value={CUSTOM}>{CUSTOM}</MenuItem>
          </Select>
        </FormControl>
        <Button
          disabled={isSearchButtonDisabled}
          startIcon={<SearchIcon />}
          onClick={handleSelectDateRange}
          variant='contained'
          color='primary'
        >
          Search
        </Button>
      </Stack>
      {showCustomRange && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack gap={1}>
            <Stack direction='row' gap={1}>
              <DateTimePicker
                label='Start Date'
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
              <DateTimePicker
                label='End Date'
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </Stack>
          </Stack>
        </LocalizationProvider>
      )}
    </Box>
  );
}
