import { useCallback } from 'react';
import { Parser } from '@json2csv/plainjs';
export default function useCSVDownload() {
  const toCSV = useCallback(
    <T extends Array<Record<string, string | number | boolean | null | undefined>>>(data: T) => {
      const fields = Object.keys(data[0] ?? {});
      try {
        const opts = {
          fields,
        };
        const parser = new Parser(opts);
        const csv = parser.parse(data);
        return csv;
      } catch (err) {
        console.error(err);
      }
      return '';
    },
    []
  );
  const download = useCallback(
    <T extends Array<Record<string, string | number | boolean | null | undefined>>>(
      data: T,
      filename: string
    ) => {
      const blob = new Blob([toCSV(data)], { type: 'text/csv' });
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.click();
      // Clean up
      window.URL.revokeObjectURL(a.href);
    },
    [toCSV]
  );
  return download;
}
