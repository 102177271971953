/* eslint-disable indent */
import React, { useCallback, useState } from 'react';
import { Stack, Grid, Typography } from '@mui/material';
import { useMutation, gql, useApolloClient } from '@apollo/client';
import {
  DeviceQuery,
  DeviceType,
  DeviceAir20,
  DeviceAir175,
  Air20_Device_Modes as Air20DeviceMode,
  DeviceUva20,
  DeviceQueryVariables,
} from '__generated__/graphql';
import {
  LocationCard,
  DeviceTypeCard,
  FirstConnectedCard,
  DeviceStateCard,
  DeviceConnectivityCard,
  DeviceFirmwareCard,
  DeviceModeCard,
  DeviceDownlightBrightnessCard,
  DeviceUvCLamp,
  DeviceFansCard,
  DeviceDoorCard,
  DeviceUVTimer,
  DeviceBulbCyclesCard,
  DeviceReplacementKitCard,
  DeviceConnectivityEvents,
  DeviceOTAEvents,
  DeviceLastServiceCard,
} from '../common';
import DeviceDetailsFaults from './DeviceDetailsFaults';
import { deviceMap } from 'Components/AuthedPages/DevicesListPage/PageViews/DevicesListFilters';
import { DateRangeEnum } from 'Components/SharedUI/DateRangePicker';
import { hoursMap } from 'Components/AuthedPages/InfectionPage/PageViews/InfectionFilters';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';
import { GET_DEVICE_DETAILS } from '../DeviceDetailsPage';
import DeviceAirFaultEvents from '../common/DeviceAirFaultEvents';

export const UPDATE_DEVICE_MODE = gql`
  mutation changeTreatmentMode(
    $accountId: ID!
    $serialNumber: String!
    $operationMode: AIR20_DEVICE_MODES!
  ) {
    updateAirDeviceOperationMode(
      accountId: $accountId
      serialNumber: $serialNumber
      operationMode: $operationMode
    ) {
      serialNumber
      type
      __typename
    }
  }
`;

export const UPDATE_DEVICE_DOWNLIGHT_BRIGHTNESS = gql`
  mutation changeDownlightBrightness(
    $accountId: ID!
    $serialNumber: String!
    $brightnessLevel: Int!
  ) {
    changeDownlightBrightness(
      accountId: $accountId
      serialNumber: $serialNumber
      brightnessLevel: $brightnessLevel
    ) {
      serialNumber
      type
      __typename
    }
  }
`;
interface DeviceDetailsViewProps {
  device: DeviceQuery['device'];
  refetch: (newDeviceDetailsVars?: Partial<DeviceQueryVariables>) => Promise<void>;
}

export default React.memo(function DeviceDetailsView({ device, refetch }: DeviceDetailsViewProps) {
  const client = useApolloClient();
  const [mutateDeviceMode] = useMutation(UPDATE_DEVICE_MODE);
  const [mutateDeviceDownlightBrightness] = useMutation(UPDATE_DEVICE_DOWNLIGHT_BRIGHTNESS);
  const [deviceWithEvents, setDeviceWithEvents] = useState<DeviceQuery['device']>();
  const [deviceWithEventsVars, setDeviceWithEventsVars] = useState<DeviceQueryVariables>({
    accountId: device?.account?.id ?? '',
    serialNumber: device?.serialNumber ?? '',
    connectivityEventsStartDate: '',
    connectivityEventsEndDate: '',
    cleaningCyclesStartDate: '',
    cleaningCyclesEndDate: '',
    motionEventsStartDate: '',
    motionEventsEndDate: '',
    infoEventsStartDate: '',
    infoEventsEndDate: '',
    otaEventsStartDate: '',
    otaEventsEndDate: '',
    errorEventsStartDate: '',
    errorEventsEndDate: '',
    airFaultsStartDate: '',
    airFaultsEndDate: '',
    airFanEventsStartDate: '',
    airFanEventsEndDate: '',
  });
  const shouldShowStatus = useCallback((targets: DeviceType[], typename: string) => {
    return targets.some((t) => t === typename);
  }, []);
  const updateDeviceMode = useCallback(
    async (newDeviceMode: Air20DeviceMode) => {
      const orgId = device?.account?.id;
      const serialNumber = device?.serialNumber;
      if (!orgId || !serialNumber) {
        return;
      }
      const args = {
        variables: {
          accountId: orgId,
          serialNumber: serialNumber,
          operationMode: newDeviceMode,
        },
      };
      await mutateDeviceMode(args)
        .then((response) => {
          if (response.errors) {
            throw new Error(response.errors.map((err) => err.toString()).join('\n'));
          }
        })
        .finally(refetch);
    },
    [mutateDeviceMode, device, refetch]
  );
  const updateDeviceDownlightBrightness = useCallback(
    async (brightness: number) => {
      const orgId = device?.account?.id;
      const serialNumber = device?.serialNumber;
      if (!orgId || !serialNumber) {
        return;
      }
      const args = {
        variables: {
          accountId: orgId,
          serialNumber: serialNumber,
          brightnessLevel: brightness,
        },
      };
      await mutateDeviceDownlightBrightness(args)
        .then((response) => {
          if (response.errors) {
            throw new Error(response.errors.map((err) => err.toString()).join('\n'));
          }
        })
        .finally(() => refetch());
    },
    [mutateDeviceDownlightBrightness, device, refetch]
  );
  const getConnectivityEvents = useCallback(
    async (interval: DateRangeEnum, include: boolean) => {
      const [startDate, endDate] = getPastDateTimeRange(hoursMap[interval], 'hours');

      const response = await client.query({
        query: GET_DEVICE_DETAILS,
        variables: Object.assign(deviceWithEventsVars, {
          includeConnectivityEvents: include,
          connectivityEventsStartDate: startDate,
          connectivityEventsEndDate: endDate,
        }),
        fetchPolicy: 'no-cache',
      });
      setDeviceWithEventsVars((prev) =>
        Object.assign(prev, {
          includeConnectivityEvents: include,
          connectivityEventsStartDate: startDate,
          connectivityEventsEndDate: endDate,
        })
      );
      if (response.data.device) {
        setDeviceWithEvents(response.data.device);
      }
    },
    [client, deviceWithEventsVars, setDeviceWithEventsVars]
  );
  const getOTAEvents = useCallback(
    async (interval: DateRangeEnum, include: boolean) => {
      const [startDate, endDate] = getPastDateTimeRange(
        interval !== DateRangeEnum.ALL_TIME ? hoursMap[interval] : 1,
        'hours'
      );
      const response = await client.query({
        query: GET_DEVICE_DETAILS,
        variables: Object.assign(deviceWithEventsVars, {
          includeOtaEvents: include,
          otaEventsStartDate: interval !== DateRangeEnum.ALL_TIME ? startDate : '',
          otaEventsEndDate: endDate,
        }),
        fetchPolicy: 'no-cache',
      });
      setDeviceWithEventsVars((prev) =>
        Object.assign(prev, {
          includeOtaEvents: include,
          otaEventsStartDate: interval !== DateRangeEnum.ALL_TIME ? startDate : '',
          otaEventsEndDate: endDate,
        })
      );
      if (response.data.device) {
        setDeviceWithEvents(response.data.device);
      }
    },
    [client, deviceWithEventsVars, setDeviceWithEventsVars]
  );
  const getAirFaultEvents = useCallback(
    async (interval: DateRangeEnum, include: boolean) => {
      const [startDate, endDate] = getPastDateTimeRange(
        interval !== DateRangeEnum.ALL_TIME ? hoursMap[interval] : 1,
        'hours'
      );
      const response = await client.query({
        query: GET_DEVICE_DETAILS,
        variables: Object.assign(deviceWithEventsVars, {
          includeAirFaults: include,
          airFaultsStartDate: interval !== DateRangeEnum.ALL_TIME ? startDate : '',
          airFaultsEndDate: endDate,
        }),
        fetchPolicy: 'no-cache',
      });
      setDeviceWithEventsVars((prev) =>
        Object.assign(prev, {
          includeAirFaults: include,
          airFaultsStartDate: interval !== DateRangeEnum.ALL_TIME ? startDate : '',
          airFaultsEndDate: endDate,
        })
      );
      if (response.data.device) {
        setDeviceWithEvents(response.data.device);
      }
    },
    [client, deviceWithEventsVars, setDeviceWithEventsVars]
  );
  return (
    <Stack gap={2}>
      <Grid container spacing={2} alignItems='stretch'>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <LocationCard location={device?.fullLocationPath?.map((l) => l?.name).join(' / ')} />
        </Grid>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <DeviceTypeCard deviceType={device?.type} />
        </Grid>
        {shouldShowStatus([DeviceType.Air20, DeviceType.Air175], device?.type ?? '') && (
          <Grid item xs={12} sm={12} lg={3} xl={3}>
            <FirstConnectedCard firstConnected={device?.firstConnection} />
          </Grid>
        )}
        {shouldShowStatus([DeviceType.Air20, DeviceType.Air175], device?.type ?? '') &&
          (device as DeviceAir20 | DeviceAir175 | null | undefined)
            ?.estimatedReplacementKitDueDate && (
            <Grid item xs={12} sm={12} lg={4} xl={4}>
              <DeviceReplacementKitCard
                replacement={
                  (device as DeviceAir20 | DeviceAir175 | null | undefined)
                    ?.estimatedReplacementKitDueDate
                }
              />
            </Grid>
          )}
        {shouldShowStatus([DeviceType.Air20, DeviceType.Air175], device?.type ?? '') &&
          (device as DeviceAir20 | DeviceAir175 | null | undefined)?.lastServiceDate && (
            <Grid item xs={12} sm={12} lg={4} xl={4}>
              <DeviceLastServiceCard
                lastServiceDate={
                  (device as DeviceAir20 | DeviceAir175 | null | undefined)?.lastServiceDate
                }
              />
            </Grid>
          )}
      </Grid>
      <Typography variant='h6'>General</Typography>
      <Grid container spacing={2} alignItems='stretch'>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <DeviceStateCard
            deviceState={device?.state?.state}
            since={
              device?.type === DeviceType.Air20
                ? (device as DeviceAir20 | null | undefined)?.timeInCurrentOperationState?.value
                : Date.now() - new Date(device?.state?.timestamp).getTime()
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <DeviceConnectivityCard deviceConnectivity={device?.connectivity} />
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <DeviceFirmwareCard deviceFirmware={device?.firmwareVersion} />
        </Grid>
      </Grid>
      <Typography variant='h6'>{deviceMap[device?.type ?? ''].value} Status</Typography>
      <Grid container spacing={2}>
        {shouldShowStatus([DeviceType.Air20], device?.type ?? '') && (
          <Grid item xs={12} sm={12} lg={4} xl={3}>
            <DeviceModeCard
              deviceMode={(device as DeviceAir20 | null | undefined)?.settings?.treatmentMode}
              update={updateDeviceMode}
            />
          </Grid>
        )}
        {((shouldShowStatus([DeviceType.Air20], device?.type ?? '') &&
          (device as DeviceAir20 | null | undefined)?.downlightEnabled?.value) ||
          shouldShowStatus([DeviceType.Air175], device?.type ?? '')) && (
          <Grid item xs={12} sm={12} lg={4} xl={3}>
            <DeviceDownlightBrightnessCard
              deviceDownlightBrightness={
                (device as DeviceAir175 | DeviceAir20 | null | undefined)?.settings
                  ?.downlightBrightness
              }
              update={updateDeviceDownlightBrightness}
            />
          </Grid>
        )}
        {shouldShowStatus([DeviceType.Air20, DeviceType.Air175], device?.type ?? '') && (
          <Grid item xs={12} sm={12} lg={4} xl={3}>
            <DeviceUvCLamp
              deviceBulb={
                (device as DeviceAir175 | DeviceAir20 | null | undefined)?.airStatus?.bulbStatus
              }
              remainingBulbLifePercentage={
                (device as DeviceAir175 | DeviceAir20 | null | undefined)?.bulbStats
                  ?.remainingBulbLifePercentage
              }
            />
          </Grid>
        )}
        {shouldShowStatus([DeviceType.Air20, DeviceType.Air175], device?.type ?? '') && (
          <Grid item xs={12} sm={12} lg={4} xl={3}>
            <DeviceFansCard
              deviceFan={(device as DeviceAir175 | DeviceAir20 | undefined)?.airStatus?.fanStatus}
            />
          </Grid>
        )}
        {shouldShowStatus([DeviceType.Air175], device?.type ?? '') && (
          <Grid item xs={12} sm={12} lg={4} xl={3}>
            <DeviceDoorCard
              deviceDoor={(device as DeviceAir175 | null | undefined)?.airStatus?.door1Closed}
            />
          </Grid>
        )}
        {shouldShowStatus([DeviceType.Uva20], device?.type ?? '') && (
          <Grid item xs={12} sm={12} lg={4} xl={3}>
            <DeviceUVTimer
              deviceUvTimer={(device as DeviceUva20 | null | undefined)?.settings?.uv_on_timer_ms}
            />
          </Grid>
        )}
        {shouldShowStatus([DeviceType.Uva20], device?.type ?? '') && (
          <Grid item xs={12} sm={12} lg={4} xl={3}>
            <DeviceBulbCyclesCard
              deviceBulbCycles={(device as DeviceUva20 | null | undefined)?.bulbStats?.bulbCycles}
            />
          </Grid>
        )}
      </Grid>
      {shouldShowStatus([DeviceType.Air20, DeviceType.Air175], device?.type ?? '') && (
        <DeviceDetailsFaults
          deviceType={device?.type}
          activeFaults={(device as DeviceAir175 | DeviceAir20 | undefined)?.airStatus?.activeFaults}
        />
      )}
      <Typography variant='h6'>{deviceMap[device?.type ?? ''].value} Events</Typography>
      <Grid container spacing={2} alignItems='stretch'>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <DeviceConnectivityEvents
            events={deviceWithEvents?.connectivityEvents}
            getEvents={getConnectivityEvents}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <DeviceOTAEvents events={deviceWithEvents?.otaEvents} getEvents={getOTAEvents} />
        </Grid>
        {shouldShowStatus([DeviceType.Air20, DeviceType.Air175], device?.type ?? '') && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <DeviceAirFaultEvents
              events={(deviceWithEvents as DeviceAir175 | DeviceAir20 | undefined)?.airFaultEvents}
              getEvents={getAirFaultEvents}
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
});
