/* eslint-disable no-nested-ternary */
import { gql, useQuery } from '@apollo/client';
import { AddCircleOutline as AddIcon, Build } from '@mui/icons-material';
import { Box, Button, CircularProgress, Stack, useMediaQuery, useTheme } from '@mui/material';
import { DeviceSearchSortColumn, DevicesQuery, OrderEnum } from '__generated__/graphql';
import type { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb';
import RoutePaths from 'Constants/RoutePaths';
import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';
import { useCallback, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import DevicesListMobileView from './PageViews/DeviceListMobileView';
import DevicesListFilters from './PageViews/DevicesListFilters';
import DevicesListView from './PageViews/DevicesListView';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';

export const GET_CURRENT_ORG_DEVICES_COUNT = gql`
  query devicesCount($deviceSearchOptions: DeviceSearchOptions) {
    deviceSearch(deviceSearchOptions: $deviceSearchOptions) {
      totalCount
      __typename
    }
  }
`;

export const GET_CURRENT_ORG_DEVICES = gql`
  query devices(
    $deviceSearchOptions: DeviceSearchOptions
    $pagination: SkipLimitPagination
    $sort: [DeviceSearchSortInput!]
  ) {
    deviceSearch(deviceSearchOptions: $deviceSearchOptions, pagination: $pagination, sort: $sort) {
      devices {
        serialNumber
        type
        state {
          state
          timestamp
          __typename
        }
        connectivity {
          timestamp
          connected
          __typename
        }
        firmwareVersion {
          value
          timestamp
          pendingValue
          pendingTimestamp
          __typename
        }
        fullLocationPath {
          id
          name
          __typename
        }
        firstConnection
        nickname
        installationDate
        shadowReported
        account {
          id
          name
          __typename
        }
        __typename
      }
      totalCount
      __typename
    }
  }
`;

type Props = {
  selectedOrg: SelectedOrgType;
  selectedLocation: SelectedLocationType;
};

const PAGE_SIZE = 15;

export default function DevicesListPage({ selectedOrg, selectedLocation }: Props) {
  const { dispatchModal } = useModal();
  const { dispatchToast } = useToast();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  // Setting filters in URL
  const [searchParams, setSearchParams] = useSearchParams();

  // Don't show spinner when loading next page
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [allDevices, setAllDevices] =
    useState<NonNullable<DevicesQuery['deviceSearch']>['devices']>();
  const [locationId, setLocationId] = useState(
    searchParams.get('location') ?? selectedLocation?.id
  );
  const {
    data: devicesListData,
    loading: isLoading,
    refetch,
    variables: deviceListVariables,
  } = useQuery(GET_CURRENT_ORG_DEVICES, {
    variables: {
      deviceSearchOptions: {
        accountId: selectedOrg?.id ?? '',
        locationId: selectedOrg?.rootLocation?.id ?? '',
      },
      pagination: {
        limit: PAGE_SIZE,
        skip: 0,
      },
      sort: [
        {
          column: DeviceSearchSortColumn.ConnectivityDate,
          order: OrderEnum.Desc,
        },
      ],
    },
    errorPolicy: 'all',
    onCompleted: (devices) => {
      if (!allDevices || allDevices.length === 0) {
        setAllDevices(devices.deviceSearch?.devices);
      }
    },
    onError(error) {
      console.error('Failed to fetch devices', error);
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Failed to fetch devices',
        message: error.message,
      });
    },
  });

  const loadNextPage = useCallback(() => {
    if (!allDevices || allDevices.length === 0) {
      return;
    }
    refetch({
      ...deviceListVariables,
      pagination: {
        limit: PAGE_SIZE,
        skip: (deviceListVariables?.pagination?.skip ?? 0) + PAGE_SIZE,
      },
    }).then((devices) => {
      const newDevices = devices.data?.deviceSearch?.devices;
      setAllDevices((prev) => {
        if (prev && newDevices) {
          return [...prev, ...newDevices];
        }
        return prev;
      });
    });
    // eslint-disable-next-line
  }, [deviceListVariables, allDevices]);

  const filterLocationHandler = useCallback(
    (id: string) => {
      const refetchArgs = {
        ...deviceListVariables,
        deviceSearchOptions: {
          ...deviceListVariables?.deviceSearchOptions,
          locationId: id,
        },
        pagination: {
          limit: PAGE_SIZE,
          skip: 0,
        },
      };
      setLocationId(id);
      setSearchParams((prev) => ({ ...Object.fromEntries(prev.entries()), location: id }), {
        replace: true,
      });
      setLoadingFilter(true);
      refetch(refetchArgs)
        .then((res) => setAllDevices(res.data?.deviceSearch?.devices))
        .finally(() => setLoadingFilter(false));
    },
    // eslint-disable-next-line
    [deviceListVariables, setSearchParams]
  );

  const filterHandler = useCallback(
    (variables: typeof deviceListVariables) => {
      const refetchArgs = {
        ...deviceListVariables,
        ...variables,
        deviceSearchOptions: {
          ...deviceListVariables?.deviceSearchOptions,
          ...variables?.deviceSearchOptions,
          locationId: locationId,
        },
        pagination: {
          limit: PAGE_SIZE,
          skip: 0,
        },
      };
      setLoadingFilter(true);
      refetch(refetchArgs)
        .then((res) => setAllDevices(res.data?.deviceSearch?.devices))
        .finally(() => setLoadingFilter(false));
    },
    // eslint-disable-next-line
    [deviceListVariables, locationId]
  );
  return (
    <PageContainerFrame pageTitles={['Devices']}>
      <SettingsPanelFrame title='Devices'>
        <Box
          display='flex'
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent='space-between'
          alignItems={isMobile ? 'flex-start' : 'center'}
          gap={1}
        >
          <LocationSelectorBreadcrumb
            onLocationSelectCallback={filterLocationHandler}
            defaultLocationId={locationId}
          />
          <Stack direction='row' alignItems='center' spacing={2}>
            <Button
              color='primary'
              variant='outlined'
              onClick={() => dispatchModal({ type: ModalNotificationTypeEnum.ADD_DEVICE_MODAL })}
              endIcon={
                <AddIcon
                  sx={{
                    height: 20,
                    width: 20,
                    [theme.breakpoints.down('md')]: {
                      display: 'none',
                    },
                  }}
                />
              }
            >
              Add Devices
            </Button>
            <RouterLink to={RoutePaths.PATH_DEVICES_MAINT}>
              <Button
                color='primary'
                variant='outlined'
                endIcon={
                  <Build
                    sx={{
                      height: 20,
                      width: 20,
                      [theme.breakpoints.down('md')]: {
                        display: 'none',
                      },
                    }}
                  />
                }
              >
                Maintenance Planning
              </Button>
            </RouterLink>
          </Stack>
        </Box>

        <DevicesListFilters filterHandler={filterHandler} selectedLocationId={locationId} />
        {isLoading || loadingFilter ? (
          <CircularProgress sx={{ alignSelf: 'center', marginTop: 10 }} size={100} />
        ) : isTablet ? (
          <DevicesListMobileView
            devices={allDevices}
            totalCount={devicesListData?.deviceSearch?.totalCount ?? 0}
            selectedOrg={selectedOrg}
            loadNextPage={loadNextPage}
          />
        ) : (
          <DevicesListView
            devices={allDevices}
            totalCount={devicesListData?.deviceSearch?.totalCount ?? 0}
            selectedOrg={selectedOrg}
            loadNextPage={loadNextPage}
          />
        )}
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
