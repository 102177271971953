/* eslint-disable indent */
/* eslint-disable no-magic-numbers */
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Add } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
} from '@mui/material';
import {
  CreateLocationV2Mutation,
  LocationArchetype,
  LocationV2Query,
  LocationV2QueryVariables,
} from '__generated__/graphql';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';
import { useEffect, useMemo } from 'react';
import { capitalize } from 'Utils/stringManipulation';
import { FETCH_LOCATION_V2 } from '../OrgLocationEditor.gql';
import LocationCard from './LocationCard';

export type LocationMode = 'view-only' | 'edit' | 'selector';
interface LocationListViewProps {
  title: string;
  type: LocationArchetype;
  mode: LocationMode;
  parentLocationId?: string | null;
  selectedLocationId?: string | null;
  onSelectLocationId: (locationId?: string | null) => void;
  highlightLocationId?: string | null;
  onHighlightLocationId: (locationId?: string | null) => void;
  buildingId?: string | null;
  floorId?: string | null;
}
export default function LocationListView({
  title,
  type,
  mode,
  parentLocationId,
  selectedLocationId,
  onSelectLocationId,
  highlightLocationId,
  onHighlightLocationId,
  buildingId,
  floorId,
}: LocationListViewProps) {
  const selectedOrg = useReactiveVar(selectedOrgVar);

  const [fetchLocation, { data, loading }] = useLazyQuery<
    LocationV2Query,
    LocationV2QueryVariables
  >(FETCH_LOCATION_V2, {
    notifyOnNetworkStatusChange: true,
  });
  const { dispatchModal } = useModal();
  const dependsOnArchetype = useMemo(() => {
    if (type === LocationArchetype.Floor) return LocationArchetype.Building;
    if (type === LocationArchetype.Room) return LocationArchetype.Floor;
    return null;
  }, [type]);
  const currentLocations = useMemo(() => {
    return data?.location?.immediateSublocations?.filter(
      (location) => location?.archetype === type
    );
  }, [data, type]);
  useEffect(() => {
    if (!parentLocationId || !fetchLocation) return;
    if (data?.location?.id === parentLocationId) return;
    fetchLocation({
      variables: {
        accountId: selectedOrg?.id as string,
        locationId: parentLocationId,
      },
    });
  }, [parentLocationId, fetchLocation, data, selectedOrg]);
  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: 'transparent',
      }}
      elevation={0}
    >
      <CardHeader
        subheader={title}
        subheaderTypographyProps={{ fontWeight: 500 }}
        action={
          mode === 'selector' &&
          parentLocationId &&
          !loading &&
          currentLocations &&
          currentLocations.length > 0 ? (
            <Button
              variant='text'
              color='primary'
              size='small'
              onClick={() => {
                onHighlightLocationId(parentLocationId);
              }}
            >
              Select All
            </Button>
          ) : null
        }
        sx={{ py: 0.5, pl: 0 }}
      />
      <CardContent sx={{ px: 0 }}>
        <Stack spacing={2}>
          {dependsOnArchetype && !parentLocationId && (
            <Alert severity='info'>
              <AlertTitle>Select a {capitalize(dependsOnArchetype.toLocaleLowerCase())}</AlertTitle>
            </Alert>
          )}
          {parentLocationId && loading && (
            <Box width='100%' display='flex' justifyContent='center' sx={{ p: 2 }}>
              <CircularProgress size={20} />
            </Box>
          )}
          {parentLocationId && !loading && currentLocations && currentLocations.length === 0 && (
            <Alert severity='warning'>
              <AlertTitle>
                There are no <b>{title}</b> available
              </AlertTitle>
              Please add a {capitalize(type.toLocaleLowerCase())}
            </Alert>
          )}
          {parentLocationId && !loading && currentLocations && currentLocations.length > 0 && (
            <Stack width='100%' spacing={2}>
              {currentLocations.map((loc) => (
                <LocationCard
                  key={loc?.id}
                  location={loc}
                  mode={mode}
                  parentLocationId={parentLocationId}
                  highlightLocationId={highlightLocationId}
                  onHighlightLocationId={onHighlightLocationId}
                  selectedLocationId={selectedLocationId}
                  onSelectLocationId={onSelectLocationId}
                  refetch={fetchLocation}
                />
              ))}
            </Stack>
          )}
          {mode === 'edit' && parentLocationId && (
            <Box width='100%' sx={{ p: 2, bgcolor: (theme) => theme.palette.background.default }}>
              <Button
                fullWidth
                startIcon={<Add />}
                onClick={() =>
                  dispatchModal({
                    type: ModalNotificationTypeEnum.CREATE_LOCATION_V2,
                    modalProps: {
                      archetype: type,
                      accountId: selectedOrg?.id,
                      parentLocationId,
                      buildingId,
                      floorId,
                      onSuccess: (_: CreateLocationV2Mutation['createLocation']) => {
                        fetchLocation({
                          variables: {
                            accountId: selectedOrg?.id as string,
                            locationId: parentLocationId,
                          },
                          fetchPolicy: 'cache-and-network',
                        });
                      },
                    },
                  })
                }
              >
                Add a {capitalize(type.toLocaleLowerCase())}
              </Button>
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
