import { gql } from '@apollo/client';
import { LOCATION_CORE_FRAGMENT } from 'fragments';

export const GET_LOCATION_METRICS = gql`
  ${LOCATION_CORE_FRAGMENT}
  query GetAggregatedLocationData(
    $accountId: ID!
    $locationIds: [ID!]!
    $timeFrame: TimeFrameInput!
    $includeCO2: Boolean = false
    $includeTemp: Boolean = false
    $includeHumidity: Boolean = false
    $includeVOC: Boolean = false
    $includeIAQ: Boolean = false
  ) {
    report {
      uvangel {
        getAggregatedLocationData(
          accountId: $accountId
          locationIds: $locationIds
          timeFrame: $timeFrame
        ) {
          location {
            ...LocationCore
          }
          locationDataReports {
            timestamp
            environmentalMetrics {
              co2 @include(if: $includeCO2) {
                avg
              }
              temp @include(if: $includeTemp) {
                avg
              }
              humidity @include(if: $includeHumidity) {
                avg
              }
              iaq @include(if: $includeIAQ) {
                avg
              }
              voc @include(if: $includeVOC) {
                avg
              }
            }
          }
        }
      }
    }
  }
`;
