import { DeviceType } from '__generated__/graphql';

export enum ReportType {
  FACILITY = 'FACILITY',
  CONNECTED_DEVICES = 'CONNECTED_DEVICES',
  TEMPERATURE = 'TEMPERATURE',
  HUMIDITY = 'HUMIDITY',
  CO2 = 'CO2',
  VOC = 'TVOC',
  IAQ = 'IAQ',
  MOLD_RISK = 'MOLD_RISK',
  LOCATIONS_NEEDING_ATTENTION = 'LOCATIONS_NEEDING_ATTENTION',
  AIR_TREATMENT = 'AIR_TREATMENT',
  POWER_CONSUMPTION = 'POWER_CONSUMPTION',
  TOTAL_SURFACE_DISINFECTION_TIME = 'TOTAL_SURFACE_DISINFECTION_TIME',
  AVERAGE_SURFACE_INTERACTION_DURATION = 'AVERAGE_SURFACE_INTERACTION_DURATION',
}

const ReportRequiredDeviceType: Record<ReportType, Array<DeviceType> | null> = {
  [ReportType.FACILITY]: null,
  [ReportType.CONNECTED_DEVICES]: null,
  [ReportType.TEMPERATURE]: [DeviceType.Air20],
  [ReportType.HUMIDITY]: [DeviceType.Air20],
  [ReportType.CO2]: [DeviceType.Air20],
  [ReportType.VOC]: [DeviceType.Air20],
  [ReportType.IAQ]: [DeviceType.Air20],
  [ReportType.MOLD_RISK]: [DeviceType.Air20],
  [ReportType.LOCATIONS_NEEDING_ATTENTION]: [DeviceType.Air20],
  [ReportType.AIR_TREATMENT]: [DeviceType.Air20, DeviceType.Air175],
  [ReportType.POWER_CONSUMPTION]: [DeviceType.Air20, DeviceType.Air175],
  [ReportType.TOTAL_SURFACE_DISINFECTION_TIME]: [DeviceType.Uva20],
  [ReportType.AVERAGE_SURFACE_INTERACTION_DURATION]: [DeviceType.Uva20],
};

export default function shouldDisplayCard(
  reportType: ReportType,
  orgDeviceTypes: Array<DeviceType>
): boolean {
  const allowedDeviceTypes = ReportRequiredDeviceType[reportType];
  if (!allowedDeviceTypes) return true;
  return orgDeviceTypes.some((i) => allowedDeviceTypes.includes(i));
}
