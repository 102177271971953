import { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import OrgLocationEditorView from './view/OrgLocationEditorView';
import OrgLocationMigratorView from './view/OrgLocationMigratorView';
import { FeatureEnabled } from 'Components/FeatureEnabled';
import { FeatureFlagFeatures } from 'Utils/FeatureFlags/FeatureFlagRuleDefinitions';
interface OrgLocationEditorPageProps {
  selectedOrg?: SelectedOrgType;
  selectedLocation?: SelectedLocationType;
}

export default function OrgLocationEditorPage({ selectedOrg }: OrgLocationEditorPageProps) {
  return (
    <PageContainerFrame pageTitles={['Locations']}>
      <SettingsPanelFrame title='Locations'>
        <OrgLocationEditorView
          selectedOrgId={selectedOrg?.id}
          rootLocationId={selectedOrg?.rootLocation?.id}
          mode='edit'
        />
        <FeatureEnabled featureFlag={FeatureFlagFeatures.LOCATIONS_V2_MIGRATOR}>
          <OrgLocationMigratorView
            selectedOrgId={selectedOrg?.id}
            rootLocationId={selectedOrg?.rootLocation?.id}
          />
        </FeatureEnabled>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
