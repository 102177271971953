import { LocationCoreFragment, LocationParentsFragment, Maybe } from '__generated__/graphql';
import LocationSelectorBreadcrumb from './LocationSelectorBreadcrumb';
import LocationSelectorBreadcrumbOld from './LocationSelectorBreadcrumbOld';
import { canViewFeature, FeatureFlagFeatures } from 'Utils/FeatureFlags';
import { useReactiveVar } from '@apollo/client';
import { currentUserDataVar, selectedOrgVar } from 'Apollo/ApolloCache';

type Props = {
  preLoadedLocationList?: Array<LocationCoreFragment>;
  onLocationSelectCallback?: (
    arg: string,
    location?: Maybe<LocationCoreFragment & LocationParentsFragment>
  ) => void;
  defaultLocationId?: string;
  dontUpdateGlobal?: boolean;
};

export default function LocationSelectorBreadcrumbMaster(props: Props) {
  const currentUser = useReactiveVar(currentUserDataVar);
  const selectedOrg = useReactiveVar(selectedOrgVar);
  return canViewFeature(FeatureFlagFeatures.LOCATIONS_V2, currentUser, selectedOrg) ? (
    <LocationSelectorBreadcrumb {...props} />
  ) : (
    <LocationSelectorBreadcrumbOld {...props} />
  );
}
