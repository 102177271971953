/* eslint-disable no-magic-numbers */
import { useReactiveVar } from '@apollo/client';
import { ArrowBack as ArrowBackIcon, Place as PlaceIcon } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  LocationArchetype,
  LocationBySearchStringQuery,
  LocationCoreFragment,
  LocationParentsFragment,
  Maybe,
} from '__generated__/graphql';
import { selectedOrgVar, setSelectedLocationVar } from 'Apollo/ApolloCache';
import OrgLocationEditorView from 'Components/AuthedPages/OrgLocationEditorV2/view/OrgLocationEditorView';
import LocationSearch from 'Components/SharedUI/LocationSelector/LocationSearch';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import React, { useEffect, useState } from 'react';
import { getIdByArchetypeInLocationPath } from 'Utils/location';

type Props = {
  preLoadedLocationList?: Array<LocationCoreFragment>;
  onLocationSelectCallback?: (
    locationId: string,
    location?: Maybe<LocationCoreFragment & LocationParentsFragment>
  ) => void;
  defaultLocationId?: string;
  dontUpdateGlobal?: boolean;
};

export default function LocationSelectorBreadcrumb({
  preLoadedLocationList,
  onLocationSelectCallback,
  dontUpdateGlobal,
  defaultLocationId,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [locationDropDownAnchorElement, setLocationDropDownAnchorElement] =
    useState<null | HTMLElement>(null);
  const isLocationSelectorOpen = Boolean(locationDropDownAnchorElement);
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const selectedOrgID = selectedOrg?.id ?? '';
  const rootLocation = selectedOrg?.rootLocation;
  const rootLocationID = rootLocation?.id ?? '';

  const [loading, locationBreadcrumb, reloadBreadcrumb] = useCurrentLocationList(
    defaultLocationId ??
      preLoadedLocationList?.at(preLoadedLocationList?.length - 1)?.id ??
      rootLocationID
  );
  const [locationSearchResults, setLocationSearchResults] =
    useState<LocationBySearchStringQuery['locations']>();

  const handleLocationButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
    // opens the location selector menu
    if (locationDropDownAnchorElement) {
      setLocationDropDownAnchorElement(null);
      setLocationSearchResults(void 0);
    } else {
      setLocationDropDownAnchorElement(event.currentTarget);
    }
  };
  const onNewLocationSelect = (newLocationId: string) => {
    setLocationDropDownAnchorElement(null);
    setLocationSearchResults(void 0);
    reloadBreadcrumb(newLocationId)
      .then(({ data }) => {
        if (onLocationSelectCallback) {
          onLocationSelectCallback(newLocationId, data.location);
        }
        if (!dontUpdateGlobal) {
          setSelectedLocationVar({
            id: data.location?.id as string,
            name: data?.location?.name as string,
            type: data?.location?.type as string,
          });
        }
      })
      .catch(console.error);
  };
  useEffect(() => {
    // onMount we load the selected location
    let selectedLocationOnMount = {
      name: selectedOrg?.rootLocation?.name ?? 'All Locations',
      id: selectedOrg?.rootLocation?.id ?? '',
      type: 'ROOT',
    };
    const selectedLocation = locationBreadcrumb?.at(locationBreadcrumb?.length - 1);
    if (selectedLocation) {
      selectedLocationOnMount = selectedLocation;
    }
    if (!dontUpdateGlobal) {
      setSelectedLocationVar(selectedLocationOnMount);
    }

    // preLoadedLocationList must be excluded as a DEP otherwise it causing infinite re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootLocationID]);

  if (!selectedOrg) return null;
  return (
    <Stack alignItems='center' direction='row' gap={1}>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Breadcrumbs separator='/' color='textSecondary'>
          {locationBreadcrumb.map((location, index) => {
            const { name, id } = location;
            const isLastBreadCrumb = locationBreadcrumb.length - 1 === index;

            const button = (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  if (isLastBreadCrumb) {
                    handleLocationButtonClick(e);
                  } else {
                    onNewLocationSelect(id);
                  }
                }}
                key={id}
                color={isLastBreadCrumb ? 'primary' : 'inherit'}
                endIcon={isLastBreadCrumb ? <PlaceIcon color='primary' /> : null}
              >
                {name}
              </Button>
            );
            return isLastBreadCrumb ? (
              <Tooltip title='Currently viewing data for this location' key={id}>
                {button}
              </Tooltip>
            ) : (
              button
            );
          })}
        </Breadcrumbs>
      )}
      <Dialog
        open={isLocationSelectorOpen}
        onClose={handleLocationButtonClick}
        aria-labelledby='location-selector'
        aria-describedby='location-selector-content'
        fullScreen={isMobile}
        maxWidth={isMobile ? void 0 : 'lg'}
        sx={{
          maxHeight: isMobile ? void 0 : 800,
          overflowY: 'auto',
        }}
        fullWidth={!isMobile}
        PaperProps={{
          sx: {
            position: 'absolute',
            top: isMobile ? 0 : '10%',
          },
        }}
        disableRestoreFocus
      >
        <DialogTitle
          id='location-selector'
          sx={{
            padding: 0,
          }}
        >
          {isMobile ? (
            <Box paddingTop={2} paddingLeft={2}>
              <IconButton onClick={handleLocationButtonClick}>
                <ArrowBackIcon fontSize={'large'} />
              </IconButton>
            </Box>
          ) : null}
          <LocationSearch
            selectedOrgId={selectedOrgID}
            setLocationSearchResults={setLocationSearchResults}
            addLocations={(loc) => {
              onNewLocationSelect(loc[loc.length - 1].id);
            }}
          />
        </DialogTitle>
        {!locationSearchResults ? (
          <DialogContent
            id='location-selector-content'
            sx={{
              marginTop: 1,
            }}
          >
            <OrgLocationEditorView
              selectedOrgId={selectedOrg?.id}
              selectorChain={{
                [LocationArchetype.Root]: selectedOrg.rootLocation?.id,
                [LocationArchetype.Building]: getIdByArchetypeInLocationPath(
                  locationBreadcrumb,
                  LocationArchetype.Building
                ),
                [LocationArchetype.Floor]: getIdByArchetypeInLocationPath(
                  locationBreadcrumb,
                  LocationArchetype.Floor
                ),
                [LocationArchetype.Room]: getIdByArchetypeInLocationPath(
                  locationBreadcrumb,
                  LocationArchetype.Room
                ),
              }}
              mode='selector'
              onSelect={onNewLocationSelect}
            />
          </DialogContent>
        ) : null}
      </Dialog>
    </Stack>
  );
}
