/* eslint-disable no-magic-numbers */
import { gql, useQuery } from '@apollo/client';
import { AutoAwesome as AutoAwesomeIcon } from '@mui/icons-material';
import { Box, Chip, CircularProgress, Grid, Stack } from '@mui/material';
import {
  GetLocationsInfectionRiskByTimeIntervalQueryVariables,
  InfectionRiskScoreTimeInterval,
} from '__generated__/graphql';
import { SelectedLocationType, SelectedOrgType, setSelectedLocationVar } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb';
import moment from 'moment-timezone';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import InfectionFilters from './PageViews/InfectionFilters';
import InfectionQA from './PageViews/InfectionQA';
import InfectionView from './PageViews/InfectionView';

const INFECTION_RISK = gql`
  query GetLocationsInfectionRiskByTimeInterval(
    $input: LocationsInfectionRiskByTimeIntervalInput!
  ) {
    report {
      uvangel {
        getLocationsInfectionRiskByTimeInterval(input: $input) {
          timestamp
          infectionRisk
          infectionRiskRaw
        }
      }
    }
  }
`;
const ERROR_MESSAGE =
  'Cannot return null for non-nullable field ReportUVAngel.getLocationsInfectionRiskByTimeInterval.';
interface InfectionPageProps {
  selectedOrg: SelectedOrgType;
  selectedLocation: SelectedLocationType;
}

export default function InfectionPage({ selectedOrg, selectedLocation }: InfectionPageProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [locationId, setLocationId] = useState<string | null | undefined>(
    searchParams.get('location') ?? selectedLocation?.id ?? selectedOrg?.rootLocation?.id ?? ''
  );
  const [cycleStartDate, cycleEndDate, utcOffset] = useMemo(() => {
    const currDateTime = moment().set('minutes', 0).set('seconds', 0);
    const endDate = currDateTime.toISOString();
    const oneDayBeforeEndDate = currDateTime.subtract(1, 'days').toISOString();
    const utcOffsetMinutes = moment().utcOffset();
    const offsetHours = Math.floor(Math.abs(utcOffsetMinutes) / 60);
    const offsetMinutes = Math.abs(utcOffsetMinutes) % 60;
    const offsetSign = utcOffsetMinutes >= 0 ? '+' : '-';
    return [
      oneDayBeforeEndDate,
      endDate,
      `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(
        2,
        '0'
      )}`,
    ];
  }, []);

  const {
    data: infectionRiskData,
    loading: infectionLoading,
    variables: infectionRiskVars,
    refetch,
    error,
  } = useQuery(INFECTION_RISK, {
    variables: {
      input: {
        accountId: selectedOrg?.id ?? '',
        rootLocationId: locationId ?? '',
        timeFrame: {
          endDate: searchParams.get('end-date') ?? cycleEndDate,
          startDate: searchParams.get('start-date') ?? cycleStartDate,
          timeInterval: searchParams.get('interval') ?? InfectionRiskScoreTimeInterval.Hourly,
          utcOffset: utcOffset,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const filterHandler = useCallback(
    (args: GetLocationsInfectionRiskByTimeIntervalQueryVariables) => {
      refetch(args);
    },
    [refetch]
  );
  if (error) {
    if (error.message === ERROR_MESSAGE) {
      // Allow for Try Again To Work
      setLocationId(selectedOrg?.rootLocation?.id ?? '');
      setSearchParams(
        (prev) => ({
          location: selectedOrg?.rootLocation?.id ?? '',
          ...Object.fromEntries(prev.entries()),
        }),
        {
          replace: true,
        }
      );
      setSelectedLocationVar(selectedOrg?.rootLocation ?? null);
    }
  }

  return (
    <PageContainerFrame pageTitles={['Contamination Spread Risk']}>
      <SettingsPanelFrame
        title='Contamination Spread Risk Analysis'
        description={
          <Box component='span' display='flex' alignItems='center'>
            <Chip label='Beta' variant='outlined' icon={<AutoAwesomeIcon fontSize='inherit' />} />
          </Box>
        }
      >
        <Box alignSelf='center'>
          <LocationSelectorBreadcrumb
            defaultLocationId={locationId as string | undefined}
            onLocationSelectCallback={(id) => {
              setLocationId(id);
              setSearchParams((prev) => ({ ...Object.fromEntries(prev.entries()), location: id }), {
                replace: true,
              });
              filterHandler({
                input: {
                  ...Object.assign(infectionRiskVars?.input ?? {}, {
                    rootLocationId: locationId,
                  }),
                },
              });
            }}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
            {infectionLoading ? (
              <CircularProgress />
            ) : (
              <InfectionView
                report={infectionRiskData?.report?.uvangel?.getLocationsInfectionRiskByTimeInterval}
                selectedLocation={selectedLocation}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Stack width='100%' gap={2}>
              <InfectionFilters
                locationId={locationId}
                timeInterval={infectionRiskVars?.input?.timeFrame?.timeInterval}
                filterHandler={filterHandler}
              />
              <InfectionQA />
            </Stack>
          </Grid>
        </Grid>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
