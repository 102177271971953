import { useCallback, useState, MouseEvent } from 'react';

const COPY_TIMEOUT = 3000;
export default function useCopy() {
  const [copied, setCopied] = useState<string>();
  const copyText = useCallback((e: MouseEvent, copyText: string) => {
    e.stopPropagation();
    e.preventDefault();
    if (!navigator) {
      return;
    }
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setCopied(copyText);
        setTimeout(() => setCopied(void 0), COPY_TIMEOUT);
      })
      .catch((err) => console.error('Failed to Copy to Clipboard', err));
  }, []);
  return [copied, copyText] as [string | undefined, (e: MouseEvent, copyText: string) => void];
}
