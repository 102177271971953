import { Box, Typography } from '@mui/material';
import { Maybe } from '__generated__/graphql';
interface LocationNameCellProps {
  name?: Maybe<string>;
}

export default function LocationNameCell({ name }: LocationNameCellProps) {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
      <Typography fontWeight='bold' color='gray' variant='body2'>
        Name
      </Typography>
      <Typography variant='body2'>{name}</Typography>
    </Box>
  );
}
