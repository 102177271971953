import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Chip,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { LocationArchetype } from '__generated__/graphql';

// Root option is removed as a user option.
// We do this as the root location gets assigned the ROOT archetype upon creation.
// The user should never have a reason to manually assign something as a ROOT archetype.
const archeTypeOptions = Object.values(LocationArchetype).filter(
  (archeType) => archeType !== LocationArchetype.Root
);

import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { UPDATE_LOCATION_DETAILS_MUTATION } from './graphql';

type ModalProps = {
  archetype: LocationArchetype | undefined;
  accountId: string;
  description: string;
  isRootLocation: boolean;
  name: string;
  locationId: string;
  tags: string[];
  type: string;
  metaData?: {
    airVolumeFt3?: number;
    assumedAch?: number;
    desiredQuantaThresholdCm3?: number;
  };
  onSuccess?: (data: unknown) => void;
};

type Props = {
  onModalClose: () => void;
  modalProps: unknown;
};

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

export default function EditLocationDetailsModal({ onModalClose, modalProps }: Props) {
  const {
    archetype,
    accountId,
    name: nameFromProps,
    type: typeFromProps,
    description,
    locationId,
    tags,
    metaData,
    isRootLocation,
    onSuccess,
  } = modalProps as ModalProps;
  const {
    airVolumeFt3: airVolume,
    assumedAch: assumedACH,
    desiredQuantaThresholdCm3: quantaThreshold,
  } = metaData ?? {};
  const [tagsList, setTagsList] = useState<Array<string>>(tags);
  const [tagInput, setTagInput] = useState<string>('');
  const [selectedArchetype, setSelectedArchetype] = useState<LocationArchetype | string>(
    archetype ?? ''
  );

  const [updateLocation, { loading: isMutationInFlight }] = useMutation(
    UPDATE_LOCATION_DETAILS_MUTATION
  );
  const { dispatchToast } = useToast();
  const handleDeleteTag = (targetIndex) => {
    const filteredList = tagsList.filter((tag, index) => targetIndex !== index);
    setTagsList(filteredList);
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid: isFormValid },
  } = useForm({
    defaultValues: {
      airVolume,
      assumedACH,
      description,
      name: nameFromProps,
      type: typeFromProps,
      quantaThreshold,
    },
  });

  const onSubmit = async (data) => {
    try {
      const { assumedACH, airVolume, description, quantaThreshold, name, type } = data;
      // name and type are required by the endpoint schema
      // we pass in the existing values unless changes exist
      const variables = {
        archetype: selectedArchetype === '' ? null : selectedArchetype,
        accountId,
        description: description === '' ? null : description,
        locationId,
        type,
        name,
        tags: tagsList,
      };

      if (selectedArchetype === LocationArchetype.Room) {
        const airVolumeFt3 = airVolume === '' || airVolume == null ? null : Number(airVolume);
        const assumedAch = assumedACH === '' || assumedACH == null ? null : Number(assumedACH);
        const desiredQuantaThresholdCm3 =
          quantaThreshold === '' || quantaThreshold == null ? null : Number(quantaThreshold);
        const metadata = {
          airVolumeFt3,
          assumedAch,
          desiredQuantaThresholdCm3,
        };
        variables['metadata'] = metadata;
      } else {
        // We clear metadata field when archetype !== ROOM
        // as only ROOM type should contain metadata
        variables['metadata'] = null;
      }

      await updateLocation({ variables });
      if (onSuccess) {
        onSuccess(locationId);
      }
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: `Edited details for Location: ${name ?? nameFromProps}`,
      };

      onModalClose();
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Editing Location Details',
        message: 'Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR UPDATE_LOCATION_DETAILS_MUTATION: ', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && tagInput.length) {
      setTagsList([...tagsList, tagInput]);
      setTagInput('');
    }
  };

  const handleSelectArchetype = (event) => {
    setSelectedArchetype(event.target.value);
  };

  return (
    <Modal open={true} onClose={onModalClose}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        alignItems='center'
        component={Paper}
        width={450}
        sx={modalStyle}
        padding={3}
      >
        <FormGroup>
          <Typography variant='h5' gutterBottom>
            Edit Location Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...register('name', { required: 'Name is required' })}
                label='Name (required)'
                fullWidth
                error={!!errors.name}
              />
            </Grid>
            {!isRootLocation && (
              <Grid item xs={12}>
                <TextField
                  label='Location Archetype'
                  disabled={isRootLocation}
                  fullWidth
                  value={selectedArchetype}
                  onChange={handleSelectArchetype}
                  select
                >
                  <MenuItem value={''}>NONE</MenuItem>
                  {archeTypeOptions.map((archetype) => (
                    <MenuItem key={archetype} value={archetype}>
                      {archetype}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                {...register('type', { required: 'Type is required' })}
                label='Type (required)'
                fullWidth
                error={!!errors.type}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField {...register('description')} label='Description' fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='tags'
                fullWidth
                placeholder='Type and press enter to add new tag'
                InputProps={{
                  startAdornment: tagsList.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      onDelete={() => handleDeleteTag(index)}
                      sx={{ marginTop: 1, marginLeft: 0.5 }}
                    />
                  )),
                  style: {
                    display: 'flex',
                    flexWrap: 'wrap',
                  },
                }}
                onKeyDown={handleKeyPress}
                onChange={(e) => setTagInput(e.target.value)}
                value={tagInput}
              />
            </Grid>
            {selectedArchetype === LocationArchetype.Room && (
              <>
                <Grid item xs={12}>
                  <Typography>Metadata (optional)</Typography>
                </Grid>
                <Grid item xs={12} gap={2}>
                  <TextField
                    {...register('airVolume')}
                    label='Air Volume'
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          ft<sup>3</sup>
                        </InputAdornment>
                      ),
                    }}
                    type='number'
                    defaultValue={airVolume ?? ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('assumedACH')}
                    label='Assumed ACH'
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>ach</InputAdornment>,
                    }}
                    type='number'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('quantaThreshold')}
                    label='Quanta Threshold'
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          q/cm<sup>3</sup>
                        </InputAdornment>
                      ),
                    }}
                    type='number'
                    defaultValue={quantaThreshold ?? ''}
                  />
                </Grid>
              </>
            )}

            <Grid display='flex' justifyContent='center' item xs={12}>
              <Button
                color='secondary'
                onClick={onModalClose}
                variant='contained'
                sx={{
                  marginRight: 2,
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isMutationInFlight || !isFormValid}
                type='submit'
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                Update Location
              </Button>
            </Grid>
          </Grid>
        </FormGroup>
      </Box>
    </Modal>
  );
}
