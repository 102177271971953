import { DeviceHubOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Maybe } from '__generated__/graphql';
interface LocationNumDevicesCellProps {
  numDevices?: Maybe<number>;
}

export default function LocationNumDevicesCell({ numDevices }: LocationNumDevicesCellProps) {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
      <Typography fontWeight='bold' color='gray' variant='body2'>
        Devices
      </Typography>
      <Box display='flex' justifyContent='center' alignItems='center' gap={1}>
        <DeviceHubOutlined
          sx={{
            height: 16,
            width: 16,
          }}
        />
        <Typography variant='body2'>{numDevices}</Typography>
      </Box>
    </Box>
  );
}
