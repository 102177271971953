/* eslint-disable indent */
/* eslint-disable no-magic-numbers */
import { gql, useMutation } from '@apollo/client';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { capitalize } from 'Utils/stringManipulation';

import { LocationArchetype } from '__generated__/graphql';

import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';

const UPDATE_LOCATION_DETAILS_MUTATION = gql(`
  mutation UpdateLocation(
    $archetype: LocationArchetype
    $accountId: ID!
    $locationId: ID!
    $type: String!
    $name: String!
    $description: String
    $tags: [String]
    $metadata: MetadataInput
  ) {
    updateLocation(
      archetype: $archetype
      accountId: $accountId
      locationId: $locationId
      type: $type
      name: $name
      description: $description
      tags: $tags
      metadata: $metadata
    ) {
      archetype
      id
      name
      type
      description
      tags
      metadata {
        airVolumeFt3
        assumedAch
        desiredQuantaThresholdCm3
      }
    }
  }
`);

type ModalProps = {
  archetype: LocationArchetype | undefined;
  accountId: string;
  description: string;
  isRootLocation: boolean;
  name: string;
  locationId: string;
  tags: string[];
  type: string;
  metaData?: {
    airVolumeFt3?: number;
    assumedAch?: number;
    desiredQuantaThresholdCm3?: number;
  };
  onSuccess?: (data: unknown) => void;
};

type Props = {
  onModalClose: () => void;
  modalProps: unknown;
};

export default function LocationV2UpdateModal({ onModalClose, modalProps }: Props) {
  const {
    archetype,
    accountId,
    name: nameFromProps,
    type: typeFromProps,
    description,
    locationId,
    tags,
    metaData,
    onSuccess,
  } = modalProps as ModalProps;
  const {
    airVolumeFt3: airVolume,
    assumedAch: assumedACH,
    desiredQuantaThresholdCm3: quantaThreshold,
  } = metaData ?? {};
  const [tagsList, setTagsList] = useState<Array<string>>(tags);
  const [tagInput, setTagInput] = useState<string>('');

  const [updateLocation, { loading: isLoading }] = useMutation(UPDATE_LOCATION_DETAILS_MUTATION);
  const { dispatchToast } = useToast();
  const handleDeleteTag = (targetIndex) => {
    const filteredList = tagsList.filter((tag, index) => targetIndex !== index);
    setTagsList(filteredList);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      airVolume,
      assumedACH,
      description,
      name: nameFromProps,
      type: typeFromProps,
      quantaThreshold,
    },
  });

  const onSubmit = async (data) => {
    try {
      const { assumedACH, airVolume, description, quantaThreshold, name, type } = data;
      // name and type are required by the endpoint schema
      // we pass in the existing values unless changes exist
      const variables = {
        archetype: archetype,
        accountId,
        description: description === '' ? null : description,
        locationId,
        type,
        name,
        tags: tagsList,
      };

      if (archetype === LocationArchetype.Room) {
        const airVolumeFt3 = airVolume === '' || airVolume == null ? null : Number(airVolume);
        const assumedAch = assumedACH === '' || assumedACH == null ? null : Number(assumedACH);
        const desiredQuantaThresholdCm3 =
          quantaThreshold === '' || quantaThreshold == null ? null : Number(quantaThreshold);
        const metadata = {
          airVolumeFt3,
          assumedAch,
          desiredQuantaThresholdCm3,
        };
        variables['metadata'] = metadata;
      } else {
        // We clear metadata field when archetype !== ROOM
        // as only ROOM type should contain metadata
        variables['metadata'] = null;
      }

      await updateLocation({ variables });
      if (onSuccess) {
        onSuccess(locationId);
      }
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: `Edited details for Location: ${name ?? nameFromProps}`,
      };

      onModalClose();
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Editing Location Details',
        message: 'Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR UPDATE_LOCATION_DETAILS_MUTATION: ', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && tagInput.length) {
      setTagsList([...tagsList, tagInput]);
      setTagInput('');
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Edit {capitalize(archetype?.toLocaleLowerCase() ?? '')} Details</DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Stack width='100%' gap={2}>
          <TextField
            {...register('name', { required: 'Name is required' })}
            label='Name'
            fullWidth
            error={!!errors.name}
            helperText={errors.name ? errors.name.message?.toString() : void 0}
          />
          <TextField
            {...register('type', { required: 'Type is required' })}
            label='Type'
            fullWidth
            error={!!errors.type}
            helperText={errors.type ? errors.type.message?.toString() : void 0}
          />
          <TextField
            label='tags'
            fullWidth
            placeholder='Type and press enter to add new tag'
            InputProps={{
              startAdornment: tagsList.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleDeleteTag(index)}
                  sx={{ marginTop: 1, marginLeft: 0.5 }}
                />
              )),
              style: {
                display: 'flex',
                flexWrap: 'wrap',
              },
            }}
            onKeyDown={handleKeyPress}
            onChange={(e) => setTagInput(e.target.value)}
            value={tagInput}
          />
          <TextField
            {...register('description')}
            label='Description'
            fullWidth
            multiline
            rows={4}
          />
          {archetype === LocationArchetype.Room && (
            <Fragment>
              <Typography>Metadata</Typography>
              <TextField
                {...register('airVolume')}
                label='Air Volume'
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      ft<sup>3</sup>
                    </InputAdornment>
                  ),
                }}
                type='number'
                defaultValue={airVolume ?? ''}
              />
              <TextField
                {...register('assumedACH')}
                label='Assumed ACH'
                InputProps={{
                  endAdornment: <InputAdornment position='end'>ach</InputAdornment>,
                }}
                type='number'
                fullWidth
              />
              <TextField
                {...register('quantaThreshold')}
                label='Quanta Threshold'
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      q/cm<sup>3</sup>
                    </InputAdornment>
                  ),
                }}
                type='number'
                defaultValue={quantaThreshold ?? ''}
              />
            </Fragment>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack width='100%' direction='row' gap={1} justifyContent='flex-end'>
          <Button color='error' onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            type='submit'
            color='primary'
            sx={{
              cursor: isLoading ? 'not-allowed' : 'inherit',
            }}
            onClick={(e) => (isLoading ? e.preventDefault() : handleSubmit(onSubmit)(e))}
            endIcon={isLoading && <CircularProgress size={16} />}
          >
            Update {capitalize(archetype?.toLocaleLowerCase() ?? '')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
