import { FeatureFlagRule } from '.';

/**
 * All currently relevant feature flags.
 * review this on regular basis to trim out old / no longer necessary features from the codebase.
 * Note: ensure these all map to strings for proper, explicit usage
 */
export enum FeatureFlagFeatures {
  /**
   * allows user to access occupancy analysis features
   */
  OCCUPANCY_ANALYSIS = 'OCCUPANCY_ANALYSIS',
  /**
   * allows user to access the following on occupancy analysis page
   * 1. Experimental options
   * 2. Toggle between rounded and stepped occupancy chart
   * 3. Change Bin Interval
   */
  ENHANCED_OCCUPANCY = 'ENHANCED_OCCUPANCY',
  /**
   * allows user to access location floorplan features like viewing, uploading, modifying, etc.
   */
  FLOORPLANS = 'FLOORPLANS',
  /**
   * allows user to access organization subscription information
   */
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  /**
   * allows user to access organization integration information - ie 3rd party data providers
   */
  INTEGRATIONS = 'INTEGRATIONS',
  /**
   * allows user to access location outbreak response mode related features - start/stop/view
   */
  LOCATION_OUTBREAK_RESPONSE_MODE = 'LOCATION_OUTBREAK_RESPONSE_MODE',
  /**
   * enables the usage of the new Locations V2 workflows
   */
  LOCATIONS_V2 = 'LOCATIONS_V2',
  /**
   * enables the usage of the new Locations V2 workflows
   */
  LOCATIONS_V2_MIGRATOR = 'LOCATIONS_V2_MIGRATOR',
  /**
   * allows visibility of infection analysis features and reports
   */
  INFECTIONS_ANALYSIS = 'INFECTIONS_ANALYSIS',
  /**
   * allows visibility of mold analysis features and reports
   */
  MOLD_ANALYSIS = 'MOLD_ANALYSIS',
}

/**
 * all feature flag rule definitions are stored here.
 * If providing an array of FeatureFlagRule objects, each distinct FeatureFlagRule object is OR'd (||) together.
 *    meaning that only 1 of the FeatureFlagRule objects has to pass in order for this feature to be enabled.
 * Each FeatureFlagRule object in the array is considered distinct.
 *
 * note: in the future, this can and should come from our API/back-end, but for now its just bundled here
 *
 * @example
 *    EX_SIMPLE_FEATURE: true,
      EX_PERCENTAGE_OF_EXAMPLE: [
        { percentageOfAccounts: 0.25 },
      ],
      EX_ENVIRONMENT: [
        { environment: ['local', 'development] }
      ],
      EX_COMPLEX_MULTIPLE_ALLOWANCES: [ // all must end up being true
        { percentageOfUsers: 0.25, requiredOrgRoles: ['device_read', 'device_write'], requiredGlobalRoles: ['account_read'] },
        { requiredAccountRoles: ['device_read', 'device_write'], },
      ],
 */
export const FEATURE_FLAG_RULE_DEFINITIONS: Record<
  FeatureFlagFeatures,
  FeatureFlagRule[] | boolean
> = {
  [FeatureFlagFeatures.ENHANCED_OCCUPANCY]: [
    {
      eligibleEnvironments: ['local', 'development', 'production'],
      requiredGlobalRoles: ['account_read'],
    },
  ],
  [FeatureFlagFeatures.OCCUPANCY_ANALYSIS]: [
    {
      eligibleEnvironments: ['local', 'development'],
    },
    {
      eligibleEnvironments: ['production'],
      requiredGlobalRoles: ['account_read'],
    },
  ],
  [FeatureFlagFeatures.FLOORPLANS]: [
    {
      eligibleEnvironments: ['local', 'development'],
    },
    {
      eligibleEnvironments: ['production'],
      requiredGlobalRoles: ['account_read'],
    },
  ],
  [FeatureFlagFeatures.SUBSCRIPTIONS]: [{ eligibleEnvironments: ['local', 'development'] }],
  [FeatureFlagFeatures.INTEGRATIONS]: [{ eligibleEnvironments: ['local', 'development'] }],
  [FeatureFlagFeatures.LOCATION_OUTBREAK_RESPONSE_MODE]: [
    {
      eligibleEnvironments: ['local'],
    },
    {
      eligibleEnvironments: ['development'],
      requiredGlobalRoles: ['account_read'],
    },
    {
      eligibleEnvironments: ['production'],
      requiredGlobalRoles: ['account_read'],
    },
  ],
  [FeatureFlagFeatures.LOCATIONS_V2]: false,
  [FeatureFlagFeatures.LOCATIONS_V2_MIGRATOR]: false,
  [FeatureFlagFeatures.INFECTIONS_ANALYSIS]: [
    {
      eligibleEnvironments: ['local'],
    },
    {
      eligibleEnvironments: ['development'],
      requiredGlobalRoles: ['account_read'],
    },
    {
      eligibleEnvironments: ['production'],
      requiredGlobalRoles: ['account_read'],
    },
  ],
  [FeatureFlagFeatures.MOLD_ANALYSIS]: [
    {
      eligibleEnvironments: ['local'],
    },
    {
      eligibleEnvironments: ['development'],
      requiredGlobalRoles: ['account_read'],
    },
    {
      eligibleEnvironments: ['production'],
      requiredGlobalRoles: ['account_read'],
    },
  ],
};
