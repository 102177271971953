import { useTheme, Card, CardHeader, Avatar, CardContent, Typography } from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  WarningAmber as WarningIcon,
} from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { Maybe } from '__generated__/graphql';
import moment from 'moment';

interface DeviceStateCardProps {
  deviceState?: Maybe<string>;
  since?: Maybe<number>;
}

export default function DeviceStateCard({ deviceState, since }: DeviceStateCardProps) {
  const theme = useTheme();
  const catalog = {
    'SPEED 1': {
      ariaLabel: 'speed-1',
      Icon: CheckIcon,
      color: theme.palette.success.dark,
    },
    'SPEED 2': {
      ariaLabel: 'speed-2',
      Icon: CheckIcon,
      color: theme.palette.success.dark,
    },
    'SPEED 3': {
      ariaLabel: 'speed-3',
      Icon: CheckIcon,
      color: theme.palette.success.dark,
    },
    'SPEED 4': {
      ariaLabel: 'speed-4',
      Icon: CheckIcon,
      color: theme.palette.success.dark,
    },
    'SPEED 5': {
      ariaLabel: 'speed-5',
      Icon: CheckIcon,
      color: theme.palette.success.dark,
    },
    NORMAL: {
      ariaLabel: 'normal',
      Icon: CheckIcon,
      color: theme.palette.success.dark,
    },
    DYNAMIC: {
      ariaLabel: 'dynamic',
      Icon: CheckIcon,
      color: theme.palette.success.dark,
    },
    'L-FAULT': {
      ariaLabel: 'l-fault',
      Icon: CloseIcon,
      color: theme.palette.error.dark,
    },
    'F-FAULT': {
      ariaLabel: 'f-fault',
      Icon: CloseIcon,
      color: theme.palette.error.dark,
    },
    MAINT: {
      ariaLabel: 'maint',
      title: 'Maintenance Lock',
      Icon: CloseIcon,
      color: theme.palette.error.dark,
    },
    STANDBY: {
      ariaLabel: 'standby',
      Icon: WarningIcon,
      color: theme.palette.warning.dark,
    },
    UNKNOWN: {
      ariaLabel: 'unknown',
      Icon: CloseIcon,
      color: theme.palette.error.dark,
    },
  };
  const state = catalog[deviceState ?? ''] ?? catalog['UNKNOWN'];

  return (
    <Card
      sx={{
        height: '100%',
        borderLeft: `.5rem solid ${state.color}`,
        // eslint-disable-next-line no-magic-numbers
        bgcolor: alpha(state.color, 0.2),
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: state.color }} aria-label={state.ariaLabel}>
            <state.Icon color='inherit' sx={{ height: 28, width: 28 }} />
          </Avatar>
        }
        title='State'
        titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
        subheader={`Since ${
          typeof since === 'number' && since >= 0 ? moment(Date.now() - since).fromNow() : 'Unknown'
        }`}
      />
      <CardContent>
        <Typography variant='h5' color={state.color} fontWeight={500}>
          {state.title ?? deviceState}
        </Typography>
      </CardContent>
    </Card>
  );
}
