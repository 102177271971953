import { LocationArchetype, Maybe } from '__generated__/graphql';

export function getIdByArchetypeInLocationPath(
  path: Array<{ id: string; archetype?: Maybe<LocationArchetype> }>,
  targetArchetype: LocationArchetype
) {
  for (const locationInPath of path) {
    if (locationInPath.archetype === targetArchetype) {
      return locationInPath.id;
    }
  }
}

export function getExpectedChildArchetype(locationArchetype?: Maybe<LocationArchetype>) {
  switch (locationArchetype) {
    case LocationArchetype.Root: {
      return LocationArchetype.Building;
    }
    case LocationArchetype.Building: {
      return LocationArchetype.Floor;
    }
    case LocationArchetype.Floor: {
      return LocationArchetype.Room;
    }
    default: {
      return null;
    }
  }
}
