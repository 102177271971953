import { gql } from '@apollo/client';
import { ORG_CORE_FRAGMENT } from 'fragments';

export const GET_ORG_ACCOUNT_DATA_QUERY = gql`
  ${ORG_CORE_FRAGMENT}
  query GetOrgAccountData($accountId: ID!) {
    account(accountId: $accountId) {
      ...OrgCore
    }
  }
`;

export const GET_CURRENT_USER_DATA_QUERY = gql`
  ${ORG_CORE_FRAGMENT}
  query GetCurrentUserData {
    currentUser {
      id
      firstName
      lastName
      email
      memberAccounts {
        ...OrgCore
      }
      preferences {
        defaultAccount {
          ...OrgCore
        }
      }
      globalRoles {
        account_create
        account_remove
        account_read
        account_write
        users_read
        users_write
        location_read
        location_write
        report_read
        report_write
        device_read
        device_write
      }
      agreements {
        agreedAt
        type
        version
      }
    }
  }
`;
