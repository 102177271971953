/* eslint-disable indent */
/* eslint-disable no-magic-numbers */
import { ArrowBack } from '@mui/icons-material';
import { Button, Fade, Grid, Hidden, useTheme } from '@mui/material';
import { LocationArchetype } from '__generated__/graphql';
import { Fragment, useCallback, useState } from 'react';
import LocationListView, { LocationMode } from '../components/LocationListView';
interface FadePanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
type OrgLocationEditorViewProps = {
  rootLocationId?: string;
  selectedOrgId?: string;
  mode: Extract<LocationMode, 'view-only' | 'edit'>;
};

type OrgLocationEditorViewPropsWithSelectorMode = {
  selectorChain: Record<LocationArchetype, string | null | undefined>;
  selectedOrgId?: string;
  mode: Extract<LocationMode, 'selector'>;
  onSelect: (locationId: string) => void;
};

export default function OrgLocationEditorView(
  props: OrgLocationEditorViewProps | OrgLocationEditorViewPropsWithSelectorMode
) {
  const { mode } = props;
  const [heirarchySelection, setHeirarchySelection] = useState<
    Record<LocationArchetype, string | null | undefined>
  >(
    mode === 'selector'
      ? props.selectorChain
      : {
          [LocationArchetype.Root]: props.rootLocationId,
          [LocationArchetype.Building]: null,
          [LocationArchetype.Floor]: null,
          [LocationArchetype.Room]: null,
        }
  );
  const [value, setValue] = useState(0);
  const [highlightLocation, setHighLightLocation] = useState<string | null | undefined>(
    Object.values(heirarchySelection).filter(Boolean).reverse().at(0)
  );
  const theme = useTheme();
  const onSelectLocationHandler = useCallback(
    (triggeredByType: LocationArchetype, locationId?: string | null) => {
      setHeirarchySelection((prev) => {
        const out = { ...prev };
        switch (triggeredByType) {
          case LocationArchetype.Building:
            out[LocationArchetype.Building] = locationId;
            out[LocationArchetype.Floor] = null;
            out[LocationArchetype.Room] = null;
            break;
          case LocationArchetype.Floor:
            out[LocationArchetype.Floor] = locationId;
            out[LocationArchetype.Room] = null;
            break;
          case LocationArchetype.Room:
            out[LocationArchetype.Room] = locationId;
            break;
          default:
            break;
        }
        return out;
      });
      switch (triggeredByType) {
        case LocationArchetype.Building:
          setValue(1);
          break;
        case LocationArchetype.Floor:
          setValue(2);
          break;
        default:
          break;
      }
    },
    []
  );
  const onHighlightLocationHandler = useCallback(
    (locationId?: string | null) => {
      setHighLightLocation(locationId);
      if (mode !== 'selector' || !locationId) return;
      props.onSelect(locationId);
    },
    [mode, props]
  );

  const buildingLocationView = (
    <LocationListView
      title='Buildings'
      type={LocationArchetype.Building}
      mode={mode}
      parentLocationId={heirarchySelection[LocationArchetype.Root]}
      selectedLocationId={heirarchySelection[LocationArchetype.Building]}
      onSelectLocationId={(locationId) =>
        onSelectLocationHandler(LocationArchetype.Building, locationId)
      }
      highlightLocationId={highlightLocation}
      onHighlightLocationId={onHighlightLocationHandler}
    />
  );
  const floorLocationView = (
    <LocationListView
      title='Floors'
      type={LocationArchetype.Floor}
      mode={mode}
      parentLocationId={heirarchySelection[LocationArchetype.Building]}
      selectedLocationId={heirarchySelection[LocationArchetype.Floor]}
      onSelectLocationId={(locationId) =>
        onSelectLocationHandler(LocationArchetype.Floor, locationId)
      }
      highlightLocationId={highlightLocation}
      onHighlightLocationId={onHighlightLocationHandler}
      buildingId={heirarchySelection[LocationArchetype.Building]}
    />
  );
  const roomLocationView = (
    <LocationListView
      title='Rooms'
      type={LocationArchetype.Room}
      mode={mode}
      parentLocationId={heirarchySelection[LocationArchetype.Floor]}
      selectedLocationId={heirarchySelection[LocationArchetype.Room]}
      onSelectLocationId={(locationId) =>
        onSelectLocationHandler(LocationArchetype.Room, locationId)
      }
      highlightLocationId={highlightLocation}
      onHighlightLocationId={onHighlightLocationHandler}
      buildingId={heirarchySelection[LocationArchetype.Building]}
      floorId={heirarchySelection[LocationArchetype.Floor]}
    />
  );
  return (
    <Fragment>
      <Hidden lgDown>
        <Grid container alignItems='stretch'>
          <Grid
            item
            lg={4}
            xl={4}
            sx={{ borderRight: `1px solid ${theme.palette.textSecondary.light}`, px: 2 }}
          >
            {buildingLocationView}
          </Grid>
          <Grid
            item
            lg={4}
            xl={4}
            sx={{ borderRight: `1px solid ${theme.palette.textSecondary.light}`, px: 2 }}
          >
            {floorLocationView}
          </Grid>
          <Grid item lg={4} xl={4} sx={{ px: 2 }}>
            {roomLocationView}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        {value > 0 && (
          <Button
            color='info'
            sx={{ width: 'fit-content' }}
            startIcon={<ArrowBack />}
            onClick={() => setValue((prev) => prev - 1)}
          >
            Go Back
          </Button>
        )}
        <FadeListView value={value} index={0}>
          {buildingLocationView}
        </FadeListView>
        <FadeListView value={value} index={1}>
          {floorLocationView}
        </FadeListView>
        <FadeListView value={value} index={2}>
          {roomLocationView}
        </FadeListView>
      </Hidden>
    </Fragment>
  );
}

function FadeListView(props: FadePanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Fade in={true} timeout={500}>
          <span>{children}</span>
        </Fade>
      )}
    </div>
  );
}
