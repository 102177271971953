/* eslint-disable no-magic-numbers */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Breadcrumbs,
  Stack,
  Typography,
  useTheme,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Place as PlaceIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { selectedOrgVar, setSelectedLocationVar } from 'Apollo/ApolloCache';
import useLinkedLocationList from 'Hooks/useLinkedLocationList';
import LocationSelectorPanel from 'Components/SharedUI/LocationSelector/LocationSelectorPanel';
import { LocationBySearchStringQuery, LocationCoreFragment } from '__generated__/graphql';
import LocationSearch from 'Components/SharedUI/LocationSelector/LocationSearch';

type Props = {
  preLoadedLocationList?: Array<LocationCoreFragment>;
  onLocationSelectCallback?: (arg: string) => void;
  dontUpdateGlobal?: boolean;
};

export default function LocationSelectorBreadcrumb({
  preLoadedLocationList,
  onLocationSelectCallback,
  dontUpdateGlobal,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [locationDropDownAnchorElement, setLocationDropDownAnchorElement] =
    useState<null | HTMLElement>(null);
  const isLocationSelectorOpen = Boolean(locationDropDownAnchorElement);
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const selectedOrgID = selectedOrg?.id ?? '';
  const rootLocation = selectedOrg?.rootLocation;
  const rootLocationID = rootLocation?.id ?? '';
  const rootLocationName = selectedOrg?.rootLocation?.name ?? '';

  const { linkedLocationList, addLocation, selectedLocation, addLocations } = useLinkedLocationList(
    rootLocation ?? { name: '', id: '', type: '' },
    preLoadedLocationList ?? undefined
  );
  const [breadCrumbLocationList, setBreadCrumbLocationList] = useState(
    preLoadedLocationList ?? [{ name: rootLocationName ?? 'All Locations', id: rootLocationID }]
  );
  const [locationSearchResults, setLocationSearchResults] =
    useState<LocationBySearchStringQuery['locations']>();

  const [isSelectButtonPressed, setIsSelectButtonPressed] = useState<boolean>(false);

  useEffect(() => {
    if (isSelectButtonPressed) {
      setIsSelectButtonPressed(false);
      setBreadCrumbLocationList([...linkedLocationList]);
    }
  }, [linkedLocationList, isSelectButtonPressed]);

  const handleLocationButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
    // opens the location selector menu
    if (locationDropDownAnchorElement) {
      setLocationDropDownAnchorElement(null);
      setLocationSearchResults(void 0);
    } else {
      setLocationDropDownAnchorElement(event.currentTarget);
    }
  };
  const onLocationSelect = (e) => {
    if (onLocationSelectCallback) {
      onLocationSelectCallback(selectedLocation?.id ?? '');
    }
    if (!dontUpdateGlobal) {
      setSelectedLocationVar(selectedLocation);
    }
    setBreadCrumbLocationList([...linkedLocationList]);
    handleLocationButtonClick(e);
  };

  const locationSearchSelectHandler = useCallback(
    (loc: LocationCoreFragment[]) => {
      const currentLocation = loc[loc.length - 1];
      addLocations(loc);
      if (!dontUpdateGlobal) {
        setSelectedLocationVar(currentLocation);
      }
      setBreadCrumbLocationList(loc);
      setLocationDropDownAnchorElement(null);
      setLocationSearchResults(void 0);
      if (onLocationSelectCallback) onLocationSelectCallback(currentLocation.id);
    },
    [addLocations, onLocationSelectCallback, dontUpdateGlobal]
  );
  useEffect(() => {
    // onMount we load the selected location
    let selectedLocationOnMount = {
      name: selectedOrg?.rootLocation?.name ?? 'All Locations',
      id: selectedOrg?.rootLocation?.id ?? '',
      type: 'ROOT',
    };
    if (selectedLocation) {
      selectedLocationOnMount = selectedLocation;
    } else if (preLoadedLocationList) {
      selectedLocationOnMount = preLoadedLocationList[preLoadedLocationList.length - 1];
    }
    if (!dontUpdateGlobal) {
      setSelectedLocationVar(selectedLocationOnMount);
    }

    // preLoadedLocationList must be excluded as a DEP otherwise it causing infinite re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootLocationID]);

  if (!selectedOrg) return null;
  return (
    <Stack alignItems='center' direction='row' gap={1}>
      <Breadcrumbs separator='/' color='textSecondary'>
        {breadCrumbLocationList.map((location, index) => {
          const { name, id } = location;
          const isLastBreadCrumb = breadCrumbLocationList.length - 1 === index;

          const button = (
            <Button
              onClick={(e) => {
                e.preventDefault();
                if (isLastBreadCrumb) {
                  handleLocationButtonClick(e);
                } else {
                  if (onLocationSelectCallback) {
                    onLocationSelectCallback(id);
                  }
                  setIsSelectButtonPressed(true);
                  if (!dontUpdateGlobal) {
                    setSelectedLocationVar(location);
                  }
                  addLocation(id, name, index);
                }
              }}
              key={id}
              color={isLastBreadCrumb ? 'primary' : 'inherit'}
              endIcon={isLastBreadCrumb ? <PlaceIcon color='primary' /> : null}
            >
              {name}
            </Button>
          );
          return isLastBreadCrumb ? (
            <Tooltip title='Currently viewing data for this location' key={id}>
              {button}
            </Tooltip>
          ) : (
            button
          );
        })}
      </Breadcrumbs>
      <Dialog
        open={isLocationSelectorOpen}
        onClose={handleLocationButtonClick}
        aria-labelledby='location-selector'
        aria-describedby='location-selector-content'
        fullScreen={isMobile}
        maxWidth={isMobile ? void 0 : 'md'}
        fullWidth={!isMobile}
        PaperProps={{
          sx: {
            position: 'absolute',
            top: isMobile ? 0 : '10%',
          },
        }}
        disableRestoreFocus
      >
        <DialogTitle
          id='location-selector'
          sx={{
            padding: 0,
          }}
        >
          {isMobile ? (
            <Box paddingTop={2} paddingLeft={2}>
              <IconButton onClick={handleLocationButtonClick}>
                <ArrowBackIcon fontSize={'large'} />
              </IconButton>
            </Box>
          ) : null}
          <LocationSearch
            selectedOrgId={selectedOrgID}
            setLocationSearchResults={setLocationSearchResults}
            addLocations={locationSearchSelectHandler}
          />
        </DialogTitle>
        {!locationSearchResults ? (
          <DialogContent
            id='location-selector-content'
            sx={{
              marginTop: 1,
            }}
          >
            <Stack gap={1} alignItems={'center'}>
              <Typography>Browse Locations</Typography>
              <LocationSelectorPanel
                selectedOrgID={selectedOrgID}
                linkedLocationList={linkedLocationList}
                onUpdateLinkedLocationList={addLocation}
                rootLocationName={rootLocationName}
              />
              {!locationSearchResults && (
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                  gap={5}
                  marginTop={1}
                >
                  <Button onClick={handleLocationButtonClick} variant='contained' color='error'>
                    Cancel
                  </Button>
                  <Button onClick={(e) => onLocationSelect(e)} variant='contained' color='success'>
                    Select
                  </Button>
                </Box>
              )}
            </Stack>
          </DialogContent>
        ) : null}
      </Dialog>
    </Stack>
  );
}
