/* eslint-disable no-magic-numbers */
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow as TableRowRaw,
} from '@mui/material';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { LocationV2Query, LocationV2QueryVariables } from '__generated__/graphql';
import { Fragment, useEffect, useState } from 'react';
import { FETCH_LOCATION_V2 } from '../OrgLocationEditor.gql';
import {
  LocationActionsCell,
  LocationArchetypeIcon,
  LocationNameCell,
  LocationNumDevicesCell,
  LocationNumImmediateSublocationsCell,
} from '../components/LocationCells';
const TableRow = styled(TableRowRaw)(() => ({
  // hide last border
  ' td,th': {
    borderBottom: 0,
  },
}));
interface MigratorLocationListViewProps {
  selectedOrgId?: string;
  rootLocationId?: string;
}

export default function MigratorLocationTableView(props: MigratorLocationListViewProps) {
  return (
    <Table>
      <TableBody>
        <MigratorLocationsRows parentLocationId={props.rootLocationId} level={1} />
      </TableBody>
    </Table>
  );
}

function MigratorLocationsRows(props: { parentLocationId?: string | null; level: number }) {
  const { parentLocationId, level } = props;

  const [expand, setExpand] = useState<string | null>();
  const selectedOrg = useReactiveVar(selectedOrgVar);

  const [fetchLocation, { data, loading }] = useLazyQuery<
    LocationV2Query,
    LocationV2QueryVariables
  >(FETCH_LOCATION_V2, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!parentLocationId) return;
    if (data?.location?.id === parentLocationId) return;
    fetchLocation({
      variables: {
        accountId: selectedOrg?.id as string,
        locationId: parentLocationId,
      },
    });
  }, [parentLocationId, fetchLocation, data, selectedOrg]);
  return (
    <Fragment>
      {loading && (
        <TableRow>
          <TableCell colSpan={5}>
            <CircularProgress size={16} />
          </TableCell>
        </TableRow>
      )}
      {data?.location?.immediateSublocations?.map((location) => (
        <Fragment key={location?.id}>
          <TableRow hover>
            <TableCell width={75}>
              <IconButton
                onClick={() => setExpand((prev) => (prev === location?.id ? null : location?.id))}
                disabled={!location?.numImmediateSubLocations}
              >
                {expand === location?.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </TableCell>
            <TableCell width={75}>
              <LocationArchetypeIcon archetype={location?.archetype} />
            </TableCell>
            <TableCell>
              <LocationNameCell name={location?.name} />
            </TableCell>
            <TableCell width={100}>
              <LocationNumDevicesCell numDevices={location?.numDevices} />
            </TableCell>
            <TableCell width={225}>
              <LocationNumImmediateSublocationsCell
                numImmediateSubLocations={location?.numImmediateSubLocations}
              />
            </TableCell>
            <TableCell width={225}>
              <LocationActionsCell
                locationId={location?.id}
                name={location?.name}
                locationArchetype={location?.archetype}
                parentLocationId={location?.parentLocation?.id}
                locationMetadata={location?.metadata}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                p: 0,
                paddingLeft: '35px',
              }}
              colSpan={6}
            >
              <Collapse in={expand === location?.id}>
                <Box
                  sx={{
                    borderLeftWidth: 1,
                    borderLeftStyle: 'solid',
                    borderLeftColor: (theme) => theme.palette.info.main,
                  }}
                >
                  <Table>
                    <TableBody>
                      <MigratorLocationsRows parentLocationId={location?.id} level={level + 1} />
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      ))}
    </Fragment>
  );
}
