import React, { useState, createContext, useContext } from 'react';
import SwitchOrgModal from 'Components/Modals/SwitchOrgModal';
import AddUserToOrgModal from 'Components/Modals/AddUserToOrgModal';
import AddSubLocationModal from 'Components/Modals/AddSubLocationModal';
import RemoveLocationConfirmationModal from 'Components/Modals/RemoveLocationConfirmationModal';
import EditUserDetailsModal from 'Components/Modals/EditUserDetailsModal';
import EditLocationDetailsModal from 'Components/Modals/EditLocationDetailsModal';
import EmailSupportModal from 'Components/Modals/EmailSupportModal';
import ImageUploadModal from 'Components/Modals/ImageUploadModal';
import AddDeviceModal from 'Components/Modals/AddDeviceModal';
import ConfirmDeleteAlertConfigModal from 'Components/Modals/ConfirmDeleteAlertConfigModal';
import EditDeviceModal from 'Components/Modals/EditDeviceModal';
import UnregisterDeviceModal from 'Components/Modals/UnregisterDeviceModal';
import RecalibrateDeviceModal from 'Components/Modals/RecalibrateDeviceModal';
import DeviceChangeLocationModal from 'Components/Modals/DeviceChangeLocationModal';
import UserAgreementModal from 'Components/Modals/UserAgreementModal';
import BulkUpdateLEDDownlightBrightness from 'Components/Modals/BulkUpdateLEDDownlight';
import BulkUpdateDeviceMode from 'Components/Modals/BulkUpdateDeviceMode';
import CreateOrganization from 'Components/Modals/CreateOrganization';
import AddLocationV2Modal from 'Components/Modals/LocationV2AddModal';
import LocationV2UpdateModal from 'Components/Modals/LocationV2UpdateModal';
import AdminRegisterDeviceToOrgModal from 'Components/Modals/AdminRegisterDeviceToOrgModal';
import OutbreakStopModal from 'Components/Modals/OutbreakStopModal';
import OutbreakStartModal from 'Components/Modals/OutbreakStartModal';
import OverviewInfoModal from 'Components/Modals/OverviewInfoModal';
import MigrateLocationModal from 'Components/Modals/MigrateLocationModal';
export enum ModalNotificationTypeEnum {
  SWITCH_ORG_MODAL,
  ADD_USER_TO_ORG_MODAL,
  ADD_SUB_LOCATION_MODAL,
  EDIT_USER_DETAILS_MODAL,
  EDIT_LOCATION_DETAILS_MODAL,
  EMAIL_SUPPORT_MODAL,
  REMOVE_LOCATION_CONFIRMATION_MODAL,
  IMAGE_UPLOAD_MODAL,
  ADD_DEVICE_MODAL,
  CONFIRM_DELETE_ALERT_CONFIG,
  DEVICE_SETTINGS_EDIT_DEVICE,
  DEVICE_SETTINGS_UNREGISTER_DEVICE,
  DEVICE_SETTINGS_RECALIBRATE_DEVICE,
  DEVICE_SETTINGS_CHANGE_LOCATION,
  USER_AGREEMENT,
  DEVICE_BULK_UPDATE_LED_DOWNLIGHT,
  DEVICE_BULK_UPDATE_DEVICE_MODE,
  CREATE_ORGANIZATION,
  CREATE_LOCATION_V2,
  UPDATE_LOCATION_V2,
  LOCATION_MIGRATE,
  ADMIN_REGISTER_DEVICE_TO_ORG,
  LOCATION_MODE_OUTBREAK_STOP,
  LOCATION_MODE_OUTBREAK_START,
  OVERVIEW_INFO,
}

type ModalConfigType = {
  type: ModalNotificationTypeEnum;
  modalProps?: unknown;
};

type ModalContextType = {
  dispatchModal: (arg: ModalConfigType) => void;
};

const ModalContext = createContext<ModalContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatchModal: () => {},
});

export const useModal = () => {
  return useContext(ModalContext);
};

type ModalRendererPropsType = {
  config: ModalConfigType;
  onModalClose: () => void;
};

const {
  SWITCH_ORG_MODAL,
  ADD_USER_TO_ORG_MODAL,
  ADD_SUB_LOCATION_MODAL,
  EDIT_USER_DETAILS_MODAL,
  EDIT_LOCATION_DETAILS_MODAL,
  EMAIL_SUPPORT_MODAL,
  REMOVE_LOCATION_CONFIRMATION_MODAL,
  IMAGE_UPLOAD_MODAL,
  ADD_DEVICE_MODAL,
  CONFIRM_DELETE_ALERT_CONFIG,
  DEVICE_SETTINGS_EDIT_DEVICE,
  DEVICE_SETTINGS_UNREGISTER_DEVICE,
  DEVICE_SETTINGS_RECALIBRATE_DEVICE,
  DEVICE_SETTINGS_CHANGE_LOCATION,
  USER_AGREEMENT,
  DEVICE_BULK_UPDATE_LED_DOWNLIGHT,
  DEVICE_BULK_UPDATE_DEVICE_MODE,
  CREATE_ORGANIZATION,
  CREATE_LOCATION_V2,
  UPDATE_LOCATION_V2,
  LOCATION_MIGRATE,
  ADMIN_REGISTER_DEVICE_TO_ORG,
  LOCATION_MODE_OUTBREAK_STOP,
  LOCATION_MODE_OUTBREAK_START,
  OVERVIEW_INFO,
} = ModalNotificationTypeEnum;

function ModalRenderer({ config, onModalClose }: ModalRendererPropsType): JSX.Element | null {
  switch (config.type) {
    case SWITCH_ORG_MODAL:
      return <SwitchOrgModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case ADD_USER_TO_ORG_MODAL:
      return <AddUserToOrgModal onModalClose={onModalClose} />;
    case ADD_SUB_LOCATION_MODAL:
      return <AddSubLocationModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case EDIT_USER_DETAILS_MODAL:
      return <EditUserDetailsModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case DEVICE_SETTINGS_EDIT_DEVICE:
      return <EditDeviceModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case DEVICE_SETTINGS_UNREGISTER_DEVICE:
      return <UnregisterDeviceModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case DEVICE_SETTINGS_RECALIBRATE_DEVICE:
      return <RecalibrateDeviceModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case USER_AGREEMENT:
      return <UserAgreementModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case DEVICE_SETTINGS_CHANGE_LOCATION:
      return (
        <DeviceChangeLocationModal onModalClose={onModalClose} modalProps={config.modalProps} />
      );
    case ADMIN_REGISTER_DEVICE_TO_ORG:
      return (
        <AdminRegisterDeviceToOrgModal onModalClose={onModalClose} modalProps={config.modalProps} />
      );
    case DEVICE_BULK_UPDATE_DEVICE_MODE:
      return <BulkUpdateDeviceMode onModalClose={onModalClose} modalProps={config.modalProps} />;
    case DEVICE_BULK_UPDATE_LED_DOWNLIGHT:
      return (
        <BulkUpdateLEDDownlightBrightness
          onModalClose={onModalClose}
          modalProps={config.modalProps}
        />
      );
    case EDIT_LOCATION_DETAILS_MODAL:
      return (
        <EditLocationDetailsModal onModalClose={onModalClose} modalProps={config.modalProps} />
      );
    case UPDATE_LOCATION_V2:
      return <LocationV2UpdateModal onModalClose={onModalClose} modalProps={config.modalProps} />;

    case CREATE_LOCATION_V2:
      return <AddLocationV2Modal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case LOCATION_MIGRATE:
      return <MigrateLocationModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case ADD_DEVICE_MODAL:
      return <AddDeviceModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case CREATE_ORGANIZATION:
      return <CreateOrganization onModalClose={onModalClose} modalProps={config.modalProps} />;
    case CONFIRM_DELETE_ALERT_CONFIG:
      return (
        <ConfirmDeleteAlertConfigModal onModalClose={onModalClose} modalProps={config.modalProps} />
      );
    case EMAIL_SUPPORT_MODAL:
      return <EmailSupportModal onModalClose={onModalClose} />;
    case IMAGE_UPLOAD_MODAL:
      return <ImageUploadModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case REMOVE_LOCATION_CONFIRMATION_MODAL:
      return (
        <RemoveLocationConfirmationModal
          onModalClose={onModalClose}
          modalProps={config.modalProps}
        />
      );
    case LOCATION_MODE_OUTBREAK_STOP:
      return <OutbreakStopModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case LOCATION_MODE_OUTBREAK_START:
      return <OutbreakStartModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    case OVERVIEW_INFO:
      return <OverviewInfoModal onModalClose={onModalClose} modalProps={config.modalProps} />;
    default:
      return null;
  }
}

type Props = { children: JSX.Element | JSX.Element[] };

export default function ModalProvider({ children }: Props) {
  const [config, setConfig] = useState<ModalConfigType | undefined>(undefined);
  const dispatchModal = (config: ModalConfigType) => {
    setConfig(config);
  };
  // onModalClose unMounts ModalRenderer when config is undefined
  const onModalClose = () => {
    setConfig(undefined);
  };

  return (
    <ModalContext.Provider value={{ dispatchModal }}>
      {children} {config && <ModalRenderer config={config} onModalClose={onModalClose} />}
    </ModalContext.Provider>
  );
}
