/* eslint-disable no-magic-numbers */
import { LazyQueryExecFunction, useReactiveVar } from '@apollo/client';
import {
  Check as CheckIcon,
  ContentCopy as CopyIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  Link as LinkIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  IconButton,
  IconButtonProps,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  LocationArchetype,
  LocationV2Query,
  LocationV2QueryVariables,
} from '__generated__/graphql';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import useCopy from 'Hooks/useCopy';
import { useModal, ModalNotificationTypeEnum } from 'Providers/ModalProvider';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { LocationMode } from './LocationListView';
import { truncateString } from 'Utils/stringManipulation';
import { FeatureEnabled } from 'Components/FeatureEnabled';
import { FeatureFlagFeatures } from 'Utils/FeatureFlags';

export default function LocationCard({
  mode,
  location,
  parentLocationId,
  highlightLocationId,
  onHighlightLocationId,
  selectedLocationId,
  onSelectLocationId,
  refetch,
}: {
  location?:
    | NonNullable<NonNullable<LocationV2Query['location']>['immediateSublocations']>[0]
    | null;
  mode: LocationMode;
  parentLocationId?: string | null;
  selectedLocationId?: string | null;
  onSelectLocationId: (locationId?: string | null) => void;
  highlightLocationId?: string | null;
  onHighlightLocationId: (locationId?: string | null) => void;
  refetch: LazyQueryExecFunction<LocationV2Query, LocationV2QueryVariables>;
}) {
  const [copied, copyText] = useCopy();
  const { dispatchModal } = useModal();
  const selectedOrg = useReactiveVar(selectedOrgVar);

  const [showDetails, setShowDetails] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const deleteDisabled = useMemo(() => {
    const locationHasDevices = location?.numDevices != null && location?.numDevices > 0;
    const locationHasSubLocations =
      location?.numImmediateSubLocations != null && location?.numImmediateSubLocations > 0;
    return locationHasDevices || locationHasSubLocations;
  }, [location]);
  const selector =
    highlightLocationId === location?.id ? (
      <Chip label='Selected' color='info' onClick={(e) => e.stopPropagation()} />
    ) : (
      <Button
        sx={{ height: 32 }} //  make it same size as the chip
        disableElevation
        size='small'
        onClick={(e) => {
          e.stopPropagation();
          onHighlightLocationId(location?.id);
        }}
      >
        select
      </Button>
    );
  return (
    <Card
      key={location?.id}
      elevation={0}
      sx={{
        cursor: 'pointer',
        borderStyle: 'solid',
        borderColor: (theme) =>
          location?.id === selectedLocationId ? theme.palette.primary.main : 'transparent',
        borderWidth: 2,
        '&:hover': {
          borderColor: (theme) => theme.palette.primary.light,
        },
      }}
      onClick={() => onSelectLocationId(location?.id)}
    >
      <CardHeader
        title={location?.name}
        subheader={location?.description ? truncateString(location.description, 80) : void 0}
        titleTypographyProps={{ variant: 'h6', fontWeight: 500 }}
        action={
          <ExpandMore
            expand={showDetails}
            onClick={(e) => {
              e.stopPropagation();
              setShowDetails((prev) => !prev);
            }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={showDetails}>
        <CardContent>
          <Stack direction='column' spacing={2}>
            <Stack direction='column' spacing={0.5}>
              <Typography fontWeight='bold'>Location Identifier</Typography>
              <Stack direction='row' spacing={2} alignItems='center'>
                <span>{location?.id}</span>
                <Tooltip title={copied === location?.id ? 'Copied' : 'Copy Location ID'}>
                  {copied === location?.id ? (
                    <IconButton onClick={(e) => e.preventDefault} size='small'>
                      <CheckIcon color='success' fontSize='small' />
                    </IconButton>
                  ) : (
                    <IconButton onClick={(e) => copyText(e, location?.id ?? '')} size='small'>
                      <CopyIcon fontSize='small' />
                    </IconButton>
                  )}
                </Tooltip>
              </Stack>
            </Stack>
            <Stack direction='column' spacing={0.5}>
              <Typography fontWeight='bold'>Type</Typography>
              <Typography>{location?.type}</Typography>
            </Stack>
            <Stack direction='column' spacing={0.5}>
              <Typography fontWeight='bold'>Total Devices</Typography>
              <Typography>{location?.numDevices}</Typography>
            </Stack>

            {location?.description && (
              <Stack direction='column' spacing={0.5}>
                <Typography fontWeight='bold'>Description</Typography>
                <Typography>{location?.description}</Typography>
              </Stack>
            )}
            {location?.tags && location?.tags.length > 0 && (
              <Stack direction='column' spacing={0.5}>
                <Typography fontWeight='bold'>Tags</Typography>
                <Stack gap={1} direction='row' alignItems='center'>
                  {location?.tags.map((tag, index) => {
                    return <Chip key={index} label={tag} />;
                  })}
                </Stack>
              </Stack>
            )}
            {location?.metadata?.airVolumeFt3 && (
              <Stack direction='column' spacing={0.5}>
                <Typography fontWeight='bold'>Air Volume</Typography>

                <Typography>{location?.metadata.airVolumeFt3}</Typography>
              </Stack>
            )}
            {location?.metadata?.assumedAch && (
              <Stack direction='column' spacing={0.5}>
                <Typography fontWeight='bold'>Assummed ACH</Typography>

                <Typography>{location?.metadata.assumedAch}</Typography>
              </Stack>
            )}
            {location?.metadata?.desiredQuantaThresholdCm3 && (
              <Stack direction='column' spacing={0.5}>
                <Typography fontWeight='bold'>Quanta Threshold</Typography>
                <Typography>{location?.metadata.desiredQuantaThresholdCm3}</Typography>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Collapse>
      <CardActions>
        <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
          {mode === 'selector' && selector}

          <Stack
            flexGrow={1}
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            spacing={1}
          >
            <FeatureEnabled featureFlag={FeatureFlagFeatures.FLOORPLANS}>
              {location?.archetype === LocationArchetype.Floor && (
                <Button
                  disableElevation
                  component={Link}
                  to={`/floorplans?location=${location?.id}`}
                  endIcon={<LinkIcon />}
                >
                  Floor Plan
                </Button>
              )}
            </FeatureEnabled>
            {mode === 'edit' && (
              <Tooltip title='Settings'>
                <ExpandMore
                  expand={!!anchorEl}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <SettingsIcon sx={{ fontSize: 20 }} />
                </ExpandMore>
              </Tooltip>
            )}
            <Menu
              sx={{ width: 200 }}
              id='device-settings-menu'
              MenuListProps={{
                'aria-labelledby': 'device-settings-menu-trigger',
              }}
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
              onClick={(e) => e.stopPropagation()}
            >
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  setAnchorEl(null);
                  dispatchModal({
                    type: ModalNotificationTypeEnum.UPDATE_LOCATION_V2,
                    modalProps: {
                      archetype: location?.archetype,
                      accountId: selectedOrg?.id,
                      description: location?.description,
                      isRootLocation: false,
                      locationId: location?.id,
                      name: location?.name,
                      tags: location?.tags,
                      type: location?.type,
                      metaData: location?.metadata,
                      onSuccess: (_: unknown) => {
                        refetch({
                          variables: {
                            accountId: selectedOrg?.id as string,
                            locationId: parentLocationId as string,
                          },
                          fetchPolicy: 'cache-and-network',
                        });
                      },
                    },
                  });
                }}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                Edit
              </MenuItem>
              <Tooltip
                arrow
                title={
                  deleteDisabled
                    ? 'Locations with sub-locations and or devices can not be removed.'
                    : ''
                }
              >
                <span>
                  <MenuItem
                    disabled={deleteDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      setAnchorEl(null);
                      dispatchModal({
                        type: ModalNotificationTypeEnum.REMOVE_LOCATION_CONFIRMATION_MODAL,
                        modalProps: {
                          accountId: selectedOrg?.id,
                          locationId: location?.id,
                          name: location?.name,
                          parentLocationId: parentLocationId,
                          onRemoveLocation: (_: unknown) => {
                            refetch({
                              variables: {
                                accountId: selectedOrg?.id as string,
                                locationId: parentLocationId as string,
                              },
                              fetchPolicy: 'cache-and-network',
                            });
                          },
                        },
                      });
                    }}
                    sx={{ color: (theme) => theme.palette.error.main }}
                  >
                    <ListItemIcon>
                      <DeleteIcon color='error' />
                    </ListItemIcon>
                    Delete
                  </MenuItem>
                </span>
              </Tooltip>
            </Menu>
          </Stack>
        </Stack>
      </CardActions>
    </Card>
  );
}
const ExpandMore = styled((props: IconButtonProps & { expand: boolean }) => {
  const { expand: _, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
