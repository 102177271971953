/* eslint-disable indent */
/* eslint-disable no-magic-numbers */
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Slider,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Air20_Device_Modes as Air20DeviceMode,
  DeviceSearchOptions,
  DeviceType,
} from '__generated__/graphql';
import { selectedLocationVar, selectedOrgVar } from 'Apollo/ApolloCache';
import { GET_CURRENT_ORG_DEVICES_COUNT } from 'Components/AuthedPages/DevicesListPage/DevicesListPage';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { useCallback, useState } from 'react';

export const BULK_CHANGE_DEVICE_MODE = gql`
  mutation BulkUpdateAirDeviceOperationMode(
    $deviceSearchOptions: DeviceSearchOptions!
    $operationMode: AIR20_DEVICE_MODES!
  ) {
    bulkUpdateAirDeviceOperationMode(
      deviceSearchOptions: $deviceSearchOptions
      operationMode: $operationMode
    ) {
      ... on DeviceUpdateSuccess {
        value {
          nickname
          serialNumber
          ... on DeviceAIR20 {
            settings {
              treatmentMode {
                value
                timestamp
                pendingValue
                pendingTimestamp
              }
            }
          }
        }
      }
      ... on DeviceUpdateError {
        error
      }
    }
  }
`;
interface Props {
  onModalClose: () => void;
  modalProps?: unknown;
}
const LABELS = ['Standby', 'Speed 1', 'Speed 2', 'Speed 3', 'Speed 4', 'Speed 5'];
const ALL_MODES = [
  Air20DeviceMode.AirsysStandby,
  Air20DeviceMode.AirsysSpeed_1,
  Air20DeviceMode.AirsysSpeed_2,
  Air20DeviceMode.AirsysSpeed_3,
  Air20DeviceMode.AirsysSpeed_4,
  Air20DeviceMode.AirsysSpeed_5,
  Air20DeviceMode.AirsysDynamic,
];
export default function BulkUpdateDeviceMode({ onModalClose, modalProps }: Props) {
  const { searchQuery, connectivity, state, activeFaults } = modalProps as DeviceSearchOptions;
  const { dispatchToast } = useToast();
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const selectedLocation = useReactiveVar(selectedLocationVar);

  const [deviceMode, setDeviceMode] = useState(0);
  const theme = useTheme();

  const {
    data: devices,
    loading: devicesLoading,
    variables: devicesVars,
    refetch,
  } = useQuery(GET_CURRENT_ORG_DEVICES_COUNT, {
    variables: {
      deviceSearchOptions: {
        accountId: selectedOrg?.id,
        locationId: selectedLocation?.id,
        searchQuery,
        connectivity,
        state,
        types: [DeviceType.Air20],
        activeFaults,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const [bulkChangeDeviceMode, { loading: bulkChangeLoading }] =
    useMutation(BULK_CHANGE_DEVICE_MODE);
  const bulkChangeDeviceModeHandler = useCallback(() => {
    if (devices?.deviceSearch?.totalCount === 0) {
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.WARNING,
        title: 'No devices found with current criteria',
        message: 'Cannot apply Bulk Change for Device Mode',
      });
      return;
    }
    let operationMode: Air20DeviceMode | null = null;

    if (deviceMode >= 0 && deviceMode < ALL_MODES.length) {
      operationMode = ALL_MODES[deviceMode];
    }
    if (!operationMode) {
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.WARNING,
        title: 'Unable to update Device Mode',
        message: 'Please Try again',
      });
      return;
    }
    bulkChangeDeviceMode({
      variables: {
        deviceSearchOptions: devicesVars?.deviceSearchOptions,
        operationMode,
      },
    })
      .then((d) => {
        if (d.errors) {
          throw new Error('Bulk Update Device Mode Failed');
        }
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Bulk Update Device Mode Successful',
        });
      })
      .catch((e) => {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Failure',
          message: e.message,
        });
      })
      .finally(() => {
        onModalClose();
      });
  }, [devices, devicesVars, deviceMode, dispatchToast, bulkChangeDeviceMode, onModalClose]);
  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>
        Bulk Change Device Mode <br /> <Typography variant='body1'>{selectedOrg?.name}</Typography>
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box display='flex' p={1} bgcolor='background.paper' borderRadius={1}>
          <LocationSelectorBreadcrumb
            onLocationSelectCallback={(id) =>
              refetch({
                deviceSearchOptions: { ...devicesVars?.deviceSearchOptions, locationId: id },
              })
            }
            dontUpdateGlobal
            defaultLocationId={selectedLocation?.id}
          />
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              onChange={(e) =>
                refetch({
                  deviceSearchOptions: {
                    ...devicesVars?.deviceSearchOptions,
                    includeSublocations: e.target.checked,
                  },
                })
              }
            />
          }
          label='Include all devices in sublocations'
        />
        <Box display='flex' alignItems='center' gap={2} mb={5}>
          <Typography variant='body1'>Number of devices to update</Typography>
          {devicesLoading ? (
            <CircularProgress size={16} />
          ) : (
            <Typography variant='body1' fontWeight='bold'>
              {devices?.deviceSearch?.totalCount}
            </Typography>
          )}
        </Box>
        <Box px={4}>
          <Slider
            valueLabelDisplay='on'
            min={0}
            step={1}
            sx={{
              '& .MuiSlider-mark': {
                height: 10,
                width: 10,
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
              },
            }}
            max={LABELS.length - 1}
            marks={[
              {
                value: 0,
                label: LABELS[0],
              },
              {
                value: 1,
              },
              {
                value: 2,
              },
              {
                value: 3,
              },
              {
                value: 4,
              },
              {
                value: LABELS.length - 1,
                label: LABELS[LABELS.length - 1],
              },
            ]}
            valueLabelFormat={valueLabel}
            aria-label='bulk-update-device-mode'
            value={deviceMode}
            onChange={(_, val) => setDeviceMode(val as number)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width='100%' gap={1} display='flex' justifyContent='flex-end' alignItems='center'>
          <Button
            color='primary'
            sx={{ fontWeight: 500 }}
            onClick={(e) => (bulkChangeLoading ? e.preventDefault() : onModalClose())}
          >
            Cancel
          </Button>
          <Button
            color='error'
            sx={{ fontWeight: 500 }}
            endIcon={bulkChangeLoading && <CircularProgress color='inherit' size={16} />}
            onClick={(e) =>
              bulkChangeLoading ? e.preventDefault() : bulkChangeDeviceModeHandler()
            }
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
function valueLabel(value: number) {
  return <Typography variant='subtitle2'>{LABELS[value]}</Typography>;
}
