import { gql } from '@apollo/client';
import {
  LOCATION_MODE_FRAGMENT,
  ORG_CORE_LIGHT_FRAGMENT,
  LOCATION_STATS_FRAGMENT,
  LOCATION_METADATA_FRAGMENT,
  LOCATION_IMMEDIATE_SUBLOCATIONS_FRAGMENT,
  LOCATION_PARENTS_FRAGMENT,
} from 'fragments';
export const GET_AVAILABLE_ACCOUNTS_QUERY = gql`
  ${ORG_CORE_LIGHT_FRAGMENT}
  query GetAvailableAccountsData($pagination: PaginatedInput, $searchString: String) {
    accounts(pagination: $pagination, searchString: $searchString) {
      totalCount
      nextToken
      accounts {
        ...OrgCoreLight
      }
    }
  }
`;

export const UPDATE_USER_ORG = gql`
  mutation updateUserPreferences($preferences: UserPreferencesInput) {
    updateUserPreferences(preferences: $preferences) {
      id
      preferences {
        defaultAccountId
        __typename
      }
      __typename
    }
  }
`;

export const ADMIN_REGISTER_DEVICE_TO_ORG = gql`
  mutation RegisterDeviceToOrg($accountId: ID!, $locationId: ID!, $deviceIdentifiers: [String]!) {
    registerDevices(
      accountId: $accountId
      locationId: $locationId
      deviceIdentifiers: $deviceIdentifiers
    ) {
      numUpdated
      numCreated
      numFailed
      failedDeviceSerials
    }
  }
`;

export const OUTBREAK_START = gql`
  ${LOCATION_MODE_FRAGMENT}
  mutation StartOutbreakResponseMode($locationId: ID!, $accountId: ID!) {
    startOutbreakResponseMode(locationId: $locationId, accountId: $accountId) {
      locationResponseMode {
        ...LocationMode
      }
      message
      success
    }
  }
`;

export const OUTBREAK_STOP = gql`
  mutation StopOutbreakResponseMode($locationId: ID!, $accountId: ID!) {
    stopOutbreakResponseMode(locationId: $locationId, accountId: $accountId) {
      message
      success
    }
  }
`;

export const UPDATE_LOCATION_DETAILS_MUTATION = gql`
  mutation UpdateLocation(
    $archetype: LocationArchetype
    $accountId: ID!
    $locationId: ID!
    $type: String!
    $name: String!
    $description: String
    $tags: [String]
    $metadata: MetadataInput
  ) {
    updateLocation(
      archetype: $archetype
      accountId: $accountId
      locationId: $locationId
      type: $type
      name: $name
      description: $description
      tags: $tags
      metadata: $metadata
    ) {
      archetype
      id
      name
      type
      description
      tags
      metadata {
        airVolumeFt3
        assumedAch
        desiredQuantaThresholdCm3
      }
    }
  }
`;

export const MIGRATE_LOCATION = gql`
  ${LOCATION_IMMEDIATE_SUBLOCATIONS_FRAGMENT}
  ${LOCATION_STATS_FRAGMENT}
  ${LOCATION_METADATA_FRAGMENT}
  ${LOCATION_PARENTS_FRAGMENT}
  mutation MigrateLocation(
    $accountId: ID!
    $locationId: ID!
    $archetype: LocationArchetype
    $metadata: MetadataInput!
  ) {
    updateLocation(
      accountId: $accountId
      locationId: $locationId
      archetype: $archetype
      metadata: $metadata
    ) {
      ...LocationCore
      ...LocationStats
      ...LocationImmediateSublocations
      ...LocationParents
      ...LocationMetadata
    }
  }
`;

export const MOVE_LOCATION = gql`
  ${LOCATION_IMMEDIATE_SUBLOCATIONS_FRAGMENT}
  ${LOCATION_STATS_FRAGMENT}
  ${LOCATION_METADATA_FRAGMENT}
  ${LOCATION_PARENTS_FRAGMENT}
  mutation MoveLocation($accountId: ID!, $locationId: ID!, $newParentLocationId: ID!) {
    moveLocation(
      accountId: $accountId
      locationId: $locationId
      newParentLocationId: $newParentLocationId
    ) {
      ...LocationCore
      ...LocationStats
      ...LocationImmediateSublocations
      ...LocationParents
      ...LocationMetadata
    }
  }
`;
export const REMOVE_LOCATION_MUTATION = gql`
  mutation removeLocationMutation($accountId: ID!, $locationId: ID!) {
    removeLocation(accountId: $accountId, locationId: $locationId) {
      success
      errorMessage
    }
  }
`;
