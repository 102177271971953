/* eslint-disable no-magic-numbers */
import { gql, useMutation } from '@apollo/client';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  CreateLocationV2Mutation,
  CreateLocationV2MutationVariables,
  LocationArchetype,
} from '__generated__/graphql';
import { capitalize } from 'Utils/stringManipulation';

type Props = {
  onModalClose: () => void;
  modalProps?: unknown;
};

export const ADD_SUB_LOCATION_MUTATION = gql`
  mutation CreateLocationV2(
    $accountId: ID!
    $type: String!
    $name: String!
    $parentLocationId: ID!
    $metadata: MetadataInput
    $archetype: LocationArchetype
    $description: String
    $tags: [String]
  ) {
    createLocation(
      accountId: $accountId
      type: $type
      name: $name
      parentLocationId: $parentLocationId
      metadata: $metadata
      archetype: $archetype
      description: $description
      tags: $tags
    ) {
      id
      name
      archetype
      metadata {
        buildingId
        floorId
      }
    }
  }
`;

export default function AddLocationV2Modal({ onModalClose, modalProps }: Props) {
  const { accountId, parentLocationId, buildingId, floorId, onSuccess, archetype } = modalProps as {
    accountId: string;
    parentLocationId: string;
    onSuccess: (newLocation: CreateLocationV2Mutation['createLocation']) => void;
    archetype: LocationArchetype;
    buildingId?: string;
    floorId?: string;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [addSubLocation, { loading: isLoading }] = useMutation<
    CreateLocationV2Mutation,
    CreateLocationV2MutationVariables
  >(ADD_SUB_LOCATION_MUTATION);

  const { dispatchToast } = useToast();
  const [tagsList, setTagsList] = useState<Array<string>>([]);
  const [tagInput, setTagInput] = useState<string>('');

  const handleDelete = (targetIndex: number) => {
    const filteredList = tagsList.filter((_, index) => targetIndex !== index);
    setTagsList(filteredList);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && tagInput.length) {
      setTagsList([...tagsList, tagInput]);
      setTagInput('');
    }
  };
  const onSubmit = async (data) => {
    const { name, description, airVolume, assumedACH, quantaThreshold } = data;
    try {
      const variables: CreateLocationV2MutationVariables = {
        accountId,
        parentLocationId: parentLocationId,
        name,
        type: archetype,
        archetype,
        metadata: {
          usingLocationV2Hierarchy: true,
          buildingId,
          floorId,
        },
      };
      if (archetype === LocationArchetype.Room) {
        const airVolumeFt3 = airVolume === '' || airVolume == null ? null : Number(airVolume);
        const assumedAch = assumedACH === '' || assumedACH == null ? null : Number(assumedACH);
        const desiredQuantaThresholdCm3 =
          quantaThreshold === '' || quantaThreshold == null ? null : Number(quantaThreshold);
        variables.metadata = {
          ...variables.metadata,
          airVolumeFt3,
          assumedAch,
          desiredQuantaThresholdCm3,
        };
      }

      if (description && description.length) {
        variables['description'] = description;
      }
      if (tagsList.length) {
        variables['tags'] = tagsList;
      }
      const res = await addSubLocation({
        variables,
      });
      onSuccess(res.data?.createLocation);

      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: `Added new ${capitalize(archetype.toLocaleLowerCase())}: ${name}`,
      };
      onModalClose();
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: `Error adding ${capitalize(archetype.toLocaleLowerCase())}`,
        message: 'Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR ADD_SUB_LOCATION_MUTATION: ', error);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Add {capitalize(archetype.toLocaleLowerCase())}</DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack width='100%' gap={2}>
          <TextField
            {...register('name', { required: 'Name is required' })}
            label='Name'
            fullWidth
            error={!!errors.name}
            helperText={errors.name ? errors.name.message?.toString() : void 0}
          />
          <TextField
            label='Tags'
            fullWidth
            placeholder='Type and press enter to add new tag'
            InputProps={{
              startAdornment: tagsList.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleDelete(index)}
                  sx={{ marginTop: 1, marginLeft: 0.5 }}
                />
              )),
              style: {
                display: 'flex',
                flexWrap: 'wrap',
              },
            }}
            onKeyDown={handleKeyPress}
            onChange={(e) => setTagInput(e.target.value)}
            value={tagInput}
            helperText='Optional'
          />
          <TextField
            {...register('description')}
            label='Description'
            fullWidth
            multiline
            rows={4}
            helperText='Optional'
          />
          {archetype === LocationArchetype.Room && (
            <Fragment>
              <Typography>Metadata</Typography>
              <TextField
                {...register('airVolume')}
                label='Air Volume'
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      ft<sup>3</sup>
                    </InputAdornment>
                  ),
                }}
                type='number'
              />
              <TextField
                {...register('assumedACH')}
                label='Assumed ACH'
                InputProps={{
                  endAdornment: <InputAdornment position='end'>ach</InputAdornment>,
                }}
                type='number'
                fullWidth
              />
              <TextField
                {...register('quantaThreshold')}
                label='Quanta Threshold'
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      q/cm<sup>3</sup>
                    </InputAdornment>
                  ),
                }}
                type='number'
              />
            </Fragment>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack width='100%' direction='row' gap={1} justifyContent='flex-end'>
          <Button color='error' onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            type='submit'
            color='primary'
            sx={{
              cursor: isLoading ? 'not-allowed' : 'inherit',
            }}
            onClick={(e) => (isLoading ? e.preventDefault() : handleSubmit(onSubmit)(e))}
            endIcon={isLoading && <CircularProgress size={16} />}
          >
            Add {capitalize(archetype.toLocaleLowerCase())}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
