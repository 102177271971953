import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useReactiveVar } from '@apollo/client';

import { selectedOrgVar, currentUserDataVar, selectedLocationVar } from 'Apollo/ApolloCache';
import ContinuumAppPagesEnums from 'Constants/ContinuumAppPagesEnums';
import OrgAPIKeyPage from './AuthedPages/OrgAPIKeyPage/OrgAPIKeyPage';
import OrgDetailsPage from './AuthedPages/OrgDetailsPage/OrgDetailsPage';
import OrgIntegrationsPage from './AuthedPages/OrgIntegrationsPage/OrgIntegrationsPage';
import OrgLocationEditorPage from './AuthedPages/OrgLocationEditorPage/OrgLocationEditorPage';
import OrgLocationEditorPageV2 from './AuthedPages/OrgLocationEditorV2/OrgLocationEditorPage';
import OrgSubscriptionPage from './AuthedPages/OrgSubscriptionPage/OrgSubscriptionPage';
import OrgSubscriptionPricingPage from './AuthedPages/OrgSubscriptionPricingPage/OrgSubscriptionPricingPage';
import OrgUsersPage from './AuthedPages/OrgUsersPage/OrgUsersPage';
import OverviewPage from './AuthedPages/OverviewPage/OverviewPage';
import UserSettingsPage from './AuthedPages/UserSettingsPage/UserSettingsPage';
import ReportsPage from './AuthedPages/ReportsPage/ReportsPage';
import DevicesListPage from './AuthedPages/DevicesListPage/DevicesListPage';
import DeviceDetailsPage from './AuthedPages/DeviceDetailsPage/DeviceDetailsPage';
import OrgAlertConfigPage from './AuthedPages/OrgAlertConfigurationsPage/OrgAlertConfigPage';
import AlertDetails from './AuthedPages/AlertDetails/AlertDetailsPage';
import InfectionPage from './AuthedPages/InfectionPage/InfectionPage';
import OccupancyPage from './AuthedPages/OccupancyPage/OccupancyPage';
import ReportsAirPage from './AuthedPages/ReportsAirPage/ReportsAirPage';
import DeviceMaintenancePlanningPage from './AuthedPages/DevicesMaintenancePlanningPage/DevicesMaintenancePlanning';
import FloorPlanPage from './AuthedPages/FloorPlanPage/FloorPlanPage';
import AdminDevicesListPage from './AuthedPages/AdminDeviceList/AdminDeviceListPage';
import ReportsSurfacePage from './AuthedPages/ReportsSurfacePage/ReportsSurfacePage';
import MoldSpreadPage from './AuthedPages/MoldSpreadPage/MoldSpreadPage';
import AddDeviceGuard from './AddDeviceWrapper/AddDeviceWrapper';
type Props = { currentPage: ContinuumAppPagesEnums; isUserDataLoading: boolean };

const {
  HOME_PAGE,
  OVERVIEW_PAGE,
  REPORTS_PAGE,
  REPORTS_AIR_PAGE,
  REPORTS_SURFACE_PAGE,
  DEVICES_PAGE,
  DEVICE_DETAILS_PAGE,
  FLOORPLAN_PAGE,
  DEVICES_MAINT_PAGE,
  USER_SETTINGS_PAGE,
  ORG_DETAILS_PAGE,
  ORG_API_KEY_LIST_PAGE,
  ORG_ALERT_CONFIGURATION_LIST_PAGE,
  ORG_INTEGRATIONS_PAGE,
  ORG_LOCATION_EDITOR_PAGE,
  ORG_LOCATION_SUBSCRIPTION_PAGE,
  ORG_LOCATION_SUBSCRIPTION_PRICING_PAGE,
  ORG_USERS_PAGE,
  ALERT_DETAILS_PAGE,
  INFECTION_PAGE,
  OCCUPANCY_PAGE,
  ORG_LOCATION_PAGE,
  ADMIN_DEVICES_PAGE,
  MOLD_PAGE,
} = ContinuumAppPagesEnums;

const viewComponentMap = {
  // Overview page is default landing page
  [HOME_PAGE]: (props) => <OverviewPage {...props} />,
  [OVERVIEW_PAGE]: (props) => <OverviewPage {...props} />,
  [ORG_API_KEY_LIST_PAGE]: (props) => <OrgAPIKeyPage {...props} />,
  [ORG_ALERT_CONFIGURATION_LIST_PAGE]: (props) => <OrgAlertConfigPage {...props} />,
  [ORG_DETAILS_PAGE]: (props) => <OrgDetailsPage {...props} />,
  [ORG_INTEGRATIONS_PAGE]: (props) => <OrgIntegrationsPage {...props} />,
  [ORG_LOCATION_EDITOR_PAGE]: (props) => <OrgLocationEditorPage {...props} />,
  [ORG_LOCATION_PAGE]: (props) => <OrgLocationEditorPageV2 {...props} />,
  [ORG_LOCATION_SUBSCRIPTION_PAGE]: (props) => <OrgSubscriptionPage {...props} />,
  [ORG_LOCATION_SUBSCRIPTION_PRICING_PAGE]: (props) => <OrgSubscriptionPricingPage {...props} />,
  [ORG_USERS_PAGE]: (props) => <OrgUsersPage {...props} />,
  [USER_SETTINGS_PAGE]: (props) => <UserSettingsPage {...props} />,
  [REPORTS_PAGE]: (props) => <ReportsPage {...props} />,
  [REPORTS_AIR_PAGE]: (props) => <ReportsAirPage {...props} />,
  [REPORTS_SURFACE_PAGE]: (props) => <ReportsSurfacePage {...props} />,
  [DEVICES_PAGE]: (props) => <DevicesListPage {...props} />,
  [DEVICE_DETAILS_PAGE]: (props) => <DeviceDetailsPage {...props} />,
  [ALERT_DETAILS_PAGE]: (props) => <AlertDetails {...props} />,
  [INFECTION_PAGE]: (props) => <InfectionPage {...props} />,
  [OCCUPANCY_PAGE]: (props) => <OccupancyPage {...props} />,
  [DEVICES_MAINT_PAGE]: (props) => <DeviceMaintenancePlanningPage {...props} />,
  [FLOORPLAN_PAGE]: (props) => <FloorPlanPage {...props} />,
  [ADMIN_DEVICES_PAGE]: (props) => <AdminDevicesListPage {...props} />,
  [MOLD_PAGE]: (props) => <MoldSpreadPage {...props} />,
};

export default function ContinuumCurrentPageRenderer({ currentPage }: Props) {
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const selectedLocation = useReactiveVar(selectedLocationVar);
  const currentUser = useReactiveVar(currentUserDataVar);
  // TODO: Add a fallback view component
  const pageComponent = viewComponentMap[currentPage] ?? null;
  const hasInitAppDataLoaded: boolean = !!selectedOrg && !!currentUser;
  return (
    <Box paddingLeft={3} paddingRight={3}>
      {hasInitAppDataLoaded ? (
        <React.Fragment key={selectedOrg?.id}>
          <AddDeviceGuard currentPath={currentPage}>
            {pageComponent({ selectedOrg, selectedLocation })}
          </AddDeviceGuard>
        </React.Fragment>
      ) : (
        <CircularProgress size={50} />
      )}
    </Box>
  );
}
