import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as TableRowRaw,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fragment, useCallback, useMemo, useState } from 'react';

import { AirDeviceFaultEvent, Maybe } from '__generated__/graphql';
import { DateRangeEnum } from 'Components/SharedUI/DateRangePicker';
import debounce from 'debounce';
import moment from 'moment-timezone';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
const DEBOUNCE_INTERVAL = 700;
const TableRow = styled(TableRowRaw)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
interface DeviceAirFaultEventsProps {
  events?: Maybe<Array<Maybe<AirDeviceFaultEvent>>>;
  getEvents: (interval: DateRangeEnum, include: boolean) => Promise<void>;
}
export default function DeviceAirFaultEvents({ events, getEvents }: DeviceAirFaultEventsProps) {
  const [loading, setLoading] = useState(false);
  const [interval, setInterval] = useState(DateRangeEnum.PAST_HOUR);
  const { dispatchToast } = useToast();
  const fetchEventsHandler = useCallback(
    async (interval: DateRangeEnum, include: boolean) => {
      try {
        setLoading(true);
        setInterval(interval);
        await getEvents(interval, include);
      } catch (err: unknown) {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.WARNING,
          title: 'Could not get Air Fault events',
        });
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getEvents, dispatchToast]
  );
  const debouncedFetchEventsHandler = useMemo(
    () => debounce(fetchEventsHandler, DEBOUNCE_INTERVAL),
    [fetchEventsHandler]
  );
  return (
    <Accordion
      onChange={(e, expanded) =>
        loading ? e.preventDefault() : debouncedFetchEventsHandler(interval, expanded)
      }
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='air-fault-events-content'
        id='air-fault-events-header'
      >
        <Typography variant='body1'>Recent Air Device Fault Events</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack width='100%' gap={2}>
          <FormControl fullWidth disabled={loading}>
            <InputLabel id='time-period'>Time Period</InputLabel>
            <Select
              label='time-period'
              id='time-period-selector'
              value={interval}
              onChange={(e) => debouncedFetchEventsHandler(e.target.value as DateRangeEnum, true)}
            >
              <MenuItem value={DateRangeEnum.PAST_HOUR}>Past hour</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_DAY}>Past 24 hours</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_THREE_DAYS}>Past 3 days</MenuItem>
              <MenuItem value={DateRangeEnum.ALL_TIME}>All time</MenuItem>
            </Select>
          </FormControl>
          {loading ? (
            <Box width='100%' display='flex' justifyContent='center'>
              <CircularProgress size={18} />
            </Box>
          ) : (
            <TableContainer sx={{ maxHeight: 400, overflowY: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRowRaw>
                    <TableCell>Fault Description</TableCell>
                    <TableCell>Firmware</TableCell>
                    <TableCell>Timestamp</TableCell>
                  </TableRowRaw>
                </TableHead>
                <TableBody>
                  {events && events.length > 0 ? (
                    <Fragment>
                      {events.map((event) => (
                        <TableRow key={event?._id}>
                          <TableCell>
                            {event?.faultDescription}
                          </TableCell>
                          <TableCell>
                            {event?.firmwareVersion}
                          </TableCell>
                          <TableCell>
                            {moment(event?.timestamp).format('MMM DD, YYYY, hh:mm:ss A')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </Fragment>
                  ) : (
                    <TableRowRaw>
                      <TableCell colSpan={2}>
                        <Box width='100%' p={2} textAlign='center'>
                          <Typography
                            sx={{
                              fontWeight: 500,
                            }}
                            variant='body1'
                          >
                            There are no events that match your current filters
                          </Typography>
                          <Typography variant='body2'>
                            Modify the selected filters to find more events
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRowRaw>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
