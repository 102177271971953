/* eslint-disable no-magic-numbers */
import { gql, useQuery } from '@apollo/client';
import { AutoAwesome as AutoAwesomeIcon } from '@mui/icons-material';
import { Box, Chip, CircularProgress, Grid, Stack } from '@mui/material';
import {
  GetHistoricalLocationMoldRiskQuery,
  GetHistoricalLocationMoldRiskQueryVariables,
  TimeInterval,
} from '__generated__/graphql';
import { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import moment from 'moment-timezone';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';
import { autoSelectedInterval } from 'Utils/manageIntervals';
import MoldSpreadFilters from './PageViews/MoldSpreadFilters';
import MoldSpreadSources from './PageViews/MoldSpreadSources';
import MoldSpreadView from './PageViews/MoldSpreadView';

const MOLD_RISK = gql`
  query GetHistoricalLocationMoldRisk($input: HistoricalLocationMoldRiskInput!) {
    report {
      uvangel {
        getHistoricalLocationMoldRisk(input: $input) {
          timestamp
          normalizedMoldRisk
          avgTempCelsius
          avgHumidityPercentage
        }
      }
    }
  }
`;
interface MoldSpreadPageProps {
  selectedOrg: SelectedOrgType;
  selectedLocation: SelectedLocationType;
}

export default function MoldSpreadPage({ selectedOrg, selectedLocation }: MoldSpreadPageProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [locationId, setLocationId] = useState<string | null | undefined>(
    searchParams.get('location') ?? selectedLocation?.id ?? selectedOrg?.rootLocation?.id ?? ''
  );
  const [cycleStartDate, cycleEndDate, utcOffset] = useMemo(() => {
    const startDateFromParams = searchParams.get('start-date') as string | undefined;
    const endDateFromParams = searchParams.get('end-date') as string | undefined;

    const [startDate, endDate] = getPastDateTimeRange(1, 'day');
    const utcOffsetMinutes = moment().utcOffset();
    const offsetHours = Math.floor(Math.abs(utcOffsetMinutes) / 60);
    const offsetMinutes = Math.abs(utcOffsetMinutes) % 60;
    const offsetSign = utcOffsetMinutes >= 0 ? '+' : '-';
    return [
      startDateFromParams ?? startDate,
      endDateFromParams ?? endDate,
      `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(
        2,
        '0'
      )}`,
    ];
  }, [searchParams]);

  const currentInterval: TimeInterval = useMemo(() => {
    const intervalFromParams = searchParams.get('interval') as TimeInterval | 'Auto';
    if (intervalFromParams !== 'Auto') {
      return intervalFromParams;
    }
    return autoSelectedInterval(cycleStartDate, cycleEndDate);
  }, [searchParams, cycleStartDate, cycleEndDate]);
  const [isLocationSelectorLoading, initLocationList] = useCurrentLocationList(locationId);

  const {
    data: MoldSpreadRiskData,
    loading: moldSpreadLoading,
    variables: moldSpreadRiskVars,
    refetch,
  } = useQuery<GetHistoricalLocationMoldRiskQuery, GetHistoricalLocationMoldRiskQueryVariables>(
    MOLD_RISK,
    {
      variables: {
        input: {
          accountId: selectedOrg?.id ?? '',
          locationId: locationId ?? '',
          timeFrame: {
            endDate: cycleEndDate,
            startDate: cycleStartDate,
            timeInterval: currentInterval,
            utcOffset: utcOffset,
          },
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const filterHandler = useCallback(
    (args: GetHistoricalLocationMoldRiskQueryVariables) => {
      refetch(args);
    },
    [refetch]
  );

  return (
    <PageContainerFrame pageTitles={['Mold Spread Risk']}>
      <SettingsPanelFrame
        title='Mold Spread Risk Analysis'
        description={
          <Box component='span' display='flex' alignItems='center'>
            <Chip label='Beta' variant='outlined' icon={<AutoAwesomeIcon fontSize='inherit' />} />
          </Box>
        }
      >
        <Box alignSelf='center'>
          {!isLocationSelectorLoading && (
            <LocationSelectorBreadcrumb
              preLoadedLocationList={initLocationList}
              onLocationSelectCallback={(id) => {
                setLocationId(id);
                setSearchParams(
                  (prev) => ({ ...Object.fromEntries(prev.entries()), location: id }),
                  {
                    replace: true,
                  }
                );
                if (!moldSpreadRiskVars?.input) return; // To avoid type issues, this condition would never be true
                filterHandler({
                  input: {
                    ...Object.assign(moldSpreadRiskVars?.input, {
                      locationId: locationId,
                    }),
                  },
                });
              }}
            />
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
            {moldSpreadLoading ? (
              <CircularProgress />
            ) : (
              <MoldSpreadView
                report={MoldSpreadRiskData?.report?.uvangel?.getHistoricalLocationMoldRisk}
                selectedLocation={selectedLocation}
                selectedOrg={selectedOrg}
                selectedInterval={currentInterval}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Stack width='100%' gap={2}>
              <MoldSpreadFilters
                locationId={locationId}
                timeInterval={moldSpreadRiskVars?.input?.timeFrame?.timeInterval}
                filterHandler={filterHandler}
              />
              <MoldSpreadSources />
            </Stack>
          </Grid>
        </Grid>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
