/* eslint-disable no-magic-numbers */
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';

import {
  LocationArchetype,
  LocationCoreFragment,
  LocationParentsFragment,
  Maybe,
  MigrateLocationMutation,
  MigrateLocationMutationVariables,
  MoveLocationMutation,
  MoveLocationMutationVariables,
} from '__generated__/graphql';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb';
import { MIGRATE_LOCATION, MOVE_LOCATION } from './graphql';
import { getExpectedChildArchetype, getIdByArchetypeInLocationPath } from 'Utils/location';
import { capitalize } from 'Utils/stringManipulation';

type Props = {
  onModalClose: () => void;
  modalProps: unknown;
};

export default function MigrateLocationModal({ modalProps, onModalClose }: Props) {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [migrationLoading, setMigrationLoading] = useState(false);

  const { accountId, locationId, name, onMigrateLocation, parentLocationId } = modalProps as {
    accountId: string;
    locationId: string;
    archetype?: LocationArchetype;
    name: string;
    parentLocationId: string;
    onMigrateLocation: (id: string) => void;
  };

  const [selectedParentLocation, setSelectedParentLocation] =
    useState<Maybe<LocationCoreFragment & LocationParentsFragment>>();
  const [migrateLocation] = useMutation<MigrateLocationMutation, MigrateLocationMutationVariables>(
    MIGRATE_LOCATION
  );
  const [moveLocation] = useMutation<MoveLocationMutation, MoveLocationMutationVariables>(
    MOVE_LOCATION,
    {
      update(cache) {
        // Removing location from current parent
        cache.modify({
          id: cache.identify({ __typename: 'Location', id: parentLocationId }),
          fields: {
            immediateSublocations(existingSublocations = [], { readField }) {
              return existingSublocations.filter(
                (sublocation) => readField('id', sublocation) !== locationId
              );
            },
            numImmediateSubLocations(existingCount = 0) {
              return existingCount - 1;
            },
            numDevices(existingNumDevices = 0) {
              // Read the `numDevices` field of the location being removed
              const locationToRemove: { numDevices?: Maybe<number> } | null = cache.readFragment({
                id: cache.identify({ __typename: 'Location', id: locationId }),
                fragment: gql`
                  fragment LocationDevices on Location {
                    numDevices
                  }
                `,
              });
              const devicesToRemove = locationToRemove?.numDevices || 0;
              return existingNumDevices - devicesToRemove;
            },
          },
        });
        // Migrating to a different parent location
        cache.modify({
          id: cache.identify({ __typename: 'Location', id: selectedParentLocation?.id }),
          fields: {
            immediateSublocations(existingSublocations = [], { readField }) {
              const newSublocationRef = cache.writeFragment({
                data: { __typename: 'Location', id: locationId },
                fragment: gql`
                  fragment NewSublocation on Location {
                    id
                  }
                `,
              });
              // Add the new sublocation if it's not already in the list
              if (
                !existingSublocations.some(
                  (sublocation) => readField('id', sublocation) === locationId
                )
              ) {
                return [...existingSublocations, newSublocationRef];
              }
              return existingSublocations;
            },
            numImmediateSubLocations(existingCount = 0) {
              return existingCount + 1;
            },
            numDevices(existingNumDevices = 0) {
              // Read the `numDevices` field of the location being added
              const locationToAdd: { numDevices?: Maybe<number> } | null = cache.readFragment({
                id: cache.identify({ __typename: 'Location', id: locationId }),
                fragment: gql`
                  fragment LocationDevices on Location {
                    numDevices
                  }
                `,
              });
              const devicesToAdd = locationToAdd?.numDevices || 0;
              return existingNumDevices + devicesToAdd;
            },
          },
        });
      },
    }
  );
  const { dispatchToast } = useToast();

  const handleMigrate = async () => {
    setMigrationLoading(true);
    try {
      if (!selectedParentLocation) {
        throw new Error('No Parent Selected');
      }
      const locationTreePath: Array<{ id: string; archetype?: Maybe<LocationArchetype> }> = [
        ...(selectedParentLocation.fullLocationPath?.map((prnt) => ({
          id: prnt?.id as string,
          archetype: prnt?.archetype,
        })) ?? []),
        {
          id: selectedParentLocation.id,
          archetype: selectedParentLocation.archetype,
        },
      ].reverse();
      // if immediate a room is found in the parents, throw an error because child of room is not allowed
      if (getIdByArchetypeInLocationPath(locationTreePath, LocationArchetype.Room)) {
        throw new Error('Cannot allow location to be a child of a room');
      }
      const variables: MigrateLocationMutationVariables = {
        accountId,
        locationId,
        metadata: {
          usingLocationV2Hierarchy: true,
        },
      };
      const buildingIdInPath = getIdByArchetypeInLocationPath(
        locationTreePath,
        LocationArchetype.Building
      );
      const floorIdInPath = getIdByArchetypeInLocationPath(
        locationTreePath,
        LocationArchetype.Floor
      );
      const immediateParentArchetype = locationTreePath[0].archetype;

      if (immediateParentArchetype === LocationArchetype.Room) {
        throw new Error('Cannot add a child of a room');
      } else if (immediateParentArchetype === LocationArchetype.Floor) {
        // Need building and floor id, and building id, archetype changed to room
        if (!buildingIdInPath || !floorIdInPath) {
          throw new Error('Could not find building or floor in the location path');
        }
        variables.metadata.buildingId = buildingIdInPath;
        variables.metadata.floorId = floorIdInPath;
        variables.archetype = LocationArchetype.Room;
      } else if (immediateParentArchetype === LocationArchetype.Building) {
        // Need only buildiing id, archetype changed to floor
        if (!buildingIdInPath) {
          throw new Error('Could not find building in the location path');
        }
        variables.metadata.buildingId = buildingIdInPath;
        variables.archetype = LocationArchetype.Floor;
      } else if (immediateParentArchetype === LocationArchetype.Root) {
        // change archetype to building
        variables.archetype = LocationArchetype.Building;
      } else {
        throw new Error('Unknown Error: No Archetype found for Immediate Parent');
      }
      await migrateLocation({
        variables,
      });
      await moveLocation({
        variables: {
          accountId,
          locationId,
          newParentLocationId: selectedParentLocation.id,
        },
      });

      onMigrateLocation(selectedParentLocation.id);

      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.SUCCESS,
        title: `Successfully Migrated Location: ${name}`,
      };
      onModalClose();
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Migrating Location',
        message: 'Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR MIGRATE_LOCATION_MUTATION: ', error);
    } finally {
      setMigrationLoading(false);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>
        Migrate Location{' '}
        <Typography variant='body1' color='text.secondary'>
          {name}
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography>Select new Parent Location</Typography>
        <Box display='flex' mt={2} p={1} bgcolor='background.paper' borderRadius={1}>
          <LocationSelectorBreadcrumb
            dontUpdateGlobal
            onLocationSelectCallback={(_, location) => setSelectedParentLocation(location)}
            defaultLocationId={parentLocationId}
          />
        </Box>
        {selectedParentLocation && (
          <Typography sx={{ mt: 2 }} variant='body2'>
            Based on the selected parent, this location will be classified as a{' '}
            <b>
              {capitalize(
                getExpectedChildArchetype(selectedParentLocation.archetype)?.toLocaleLowerCase()
              )}
            </b>
          </Typography>
        )}
        <FormControlLabel
          sx={{ mt: 3 }}
          control={<Checkbox checked={isChecked} onClick={() => setIsChecked(!isChecked)} />}
          label='I understand and wish to continue'
        />
      </DialogContent>
      <Divider style={{ width: '100%' }} />
      <DialogActions>
        <Stack alignItems='center' justifyContent='flex-end' direction='row' gap={1}>
          <Button onClick={onModalClose} color='info'>
            Cancel
          </Button>
          <Button
            disabled={!isChecked || migrationLoading || !selectedParentLocation}
            onClick={(e) => (migrationLoading ? e.preventDefault() : handleMigrate())}
            color='error'
            endIcon={migrationLoading && <CircularProgress size={16} />}
          >
            Migrate
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
