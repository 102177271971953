import { useCallback, useMemo } from 'react';
import { useQuery, useReactiveVar, gql, ApolloQueryResult } from '@apollo/client';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import {
  LocationCoreFragment,
  GetInitLocationPathQuery,
  GetInitLocationPathQueryVariables,
} from '__generated__/graphql';
import { LOCATION_CORE_FRAGMENT, LOCATION_PARENTS_FRAGMENT } from 'fragments';

export const GET_INIT_LOCATION_PATH = gql`
  ${LOCATION_CORE_FRAGMENT}
  ${LOCATION_PARENTS_FRAGMENT}
  query getInitLocationPath($accountId: ID!, $locationId: ID!) {
    location(accountId: $accountId, locationId: $locationId) {
      ...LocationCore
      ...LocationParents
    }
  }
`;

const LOCATION_NOT_FOUND_ERR = 'Location not found';
const LOCATION_INVALID_ID = 'invalid ID format';

export default function useCurrentLocationList(
  locationId?: string | null
): [
  boolean,
  LocationCoreFragment[],
  (newLocationId: string) => Promise<ApolloQueryResult<GetInitLocationPathQuery>>
] {
  const selectedOrg = useReactiveVar(selectedOrgVar);

  const {
    loading,
    data: locationData,
    refetch,
    error: locationError,
  } = useQuery<GetInitLocationPathQuery, GetInitLocationPathQueryVariables>(
    GET_INIT_LOCATION_PATH,
    {
      skip: !locationId || locationId === selectedOrg?.rootLocation?.id,
      variables: {
        accountId: selectedOrg?.id ?? '',
        locationId: locationId as string,
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const initLocationList = useMemo(() => {
    const { name, id, archetype, type } = locationData?.location ?? {};
    const locationPathList = locationData?.location?.fullLocationPath;
    let initLocationList_: LocationCoreFragment[] = [];
    if (locationPathList?.length) {
      initLocationList_ = [
        ...locationPathList,
        { name, id, archetype, type },
      ] as LocationCoreFragment[];
    } else {
      initLocationList_ = [
        {
          name: selectedOrg?.rootLocation?.name ?? 'All Locations',
          id: selectedOrg?.rootLocation?.id as string,
          type: selectedOrg?.rootLocation?.type ?? 'ROOT',
          archetype: selectedOrg?.rootLocation?.archetype,
        },
      ];
    }
    if (
      locationError?.message &&
      [LOCATION_NOT_FOUND_ERR, LOCATION_INVALID_ID].includes(locationError?.message)
    ) {
      // If location not found in the org, remove query params
      window.location.href = window.location.pathname;
    }
    return initLocationList_;
  }, [locationData, selectedOrg, locationError]);
  const reloadLocationLinkedList = useCallback(
    (newLocationId: string) => {
      return refetch({ accountId: selectedOrg?.id ?? '', locationId: newLocationId });
    },
    [refetch, selectedOrg?.id]
  );
  return [loading, initLocationList, reloadLocationLinkedList];
}
