import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  DeviceType,
  GetConnectedDevicesQuery,
  GetConnectedDevicesQueryVariables,
} from '__generated__/graphql';
import { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ConnectedDevicesCardContainer from '../OverviewPage/DashboardCardContainers/ConnectedDevicesCardContainer';
import { GET_CONNECTED_DEVICES_QUERY } from '../ReportsAirPage/ReportsAirPage';
import {
  AverageDisinfectionPercentage,
  AverageSurfaceInteractionTime,
  DisinfectionHeatmap,
  SurfaceInteractionsHeatmap,
  TotalSurfaceDisinfectionTime,
  TotalSurfaceInteractions,
} from './SurfaceReportCards';
type Props = {
  selectedOrg: SelectedOrgType;
  selectedLocation: SelectedLocationType;
};

export default function ReportsSurfacePage({ selectedOrg, selectedLocation }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [locationId, setLocationId] = useState<string | null | undefined>(
    searchParams.get('location') ?? selectedLocation?.id ?? selectedOrg?.rootLocation?.id ?? ''
  );
  const [islocationSelectorLoading, initLocationList] = useCurrentLocationList(locationId);

  const {
    data: connectedDevices,
    loading: connectedDevicesLoading,
    variables: connectedDevicesVar,
    refetch: refetchConnectedDevices,
  } = useQuery<GetConnectedDevicesQuery, GetConnectedDevicesQueryVariables>(
    GET_CONNECTED_DEVICES_QUERY,
    {
      variables: {
        accountId: selectedOrg?.id ?? '',
        locationId: locationId ?? '',
        deviceTypes: [DeviceType.Uva10, DeviceType.Uva20],
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    const currentLocationId = searchParams.get('location');
    if (!currentLocationId && locationId) {
      setSearchParams((prev) => ({ ...Object.fromEntries(prev.entries()), location: locationId }), {
        replace: true,
      });
    }
  }, [searchParams, setSearchParams, locationId]);
  return (
    <PageContainerFrame pageTitles={['Surface Treatment']}>
      <SettingsPanelFrame title='Surface Treatment'>
        {!islocationSelectorLoading && (
          <LocationSelectorBreadcrumb
            preLoadedLocationList={initLocationList}
            onLocationSelectCallback={(id) => {
              setLocationId(id);
              setSearchParams((prev) => ({ ...Object.fromEntries(prev.entries()), location: id }), {
                replace: true,
              });
              refetchConnectedDevices(
                Object.assign(connectedDevicesVar ?? {}, { locationId: locationId ?? '' })
              );
            }}
          />
        )}
        <Grid container spacing={3}>
          <ConnectedDevicesCardContainer
            title='Surface Devices'
            data={connectedDevices?.report?.uvangel?.activelyConnectedDevices}
            isLoading={connectedDevicesLoading}
            gridConfig={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 4,
              xl: 3,
            }}
          />
          <TotalSurfaceInteractions
            selectedOrgId={selectedOrg?.id}
            selectedLocationId={locationId}
            gridConfig={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 8,
              xl: 9,
            }}
          />
          <TotalSurfaceDisinfectionTime
            selectedOrgId={selectedOrg?.id}
            selectedLocationId={locationId}
            gridConfig={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            }}
          />
          <AverageSurfaceInteractionTime
            selectedOrgId={selectedOrg?.id}
            selectedLocationId={locationId}
            gridConfig={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            }}
          />
          <AverageDisinfectionPercentage
            selectedOrgId={selectedOrg?.id}
            selectedLocationId={locationId}
            gridConfig={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            }}
          />
          <SurfaceInteractionsHeatmap
            selectedOrgId={selectedOrg?.id}
            selectedLocationId={locationId}
            gridConfig={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            }}
          />
          <DisinfectionHeatmap
            selectedOrgId={selectedOrg?.id}
            selectedLocationId={locationId}
            gridConfig={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            }}
          />
        </Grid>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
