import { useState } from 'react';
import { CssBaseline } from '@mui/material';
import { useIdleTimer } from 'react-idle-timer';
import { ThemeProvider } from '@mui/material';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useReactiveVar } from '@apollo/client';
import '@aws-amplify/ui-react/styles.css';

import { appThemeVar } from 'Apollo/ApolloCache';
import { useSignOut } from 'Hooks/useSignOut';
import NotificationProvider from 'Providers/NotificationProvider';
import ErrorPageNotFound from 'UnAuthedPages/ErrorPageNotFound';
import AccessDeniedPage from 'UnAuthedPages/AccessDeniedPage';
import ExternalApp from 'ExternalApp';
import ContinuumApp from './ContinuumApp';
import RoutePaths from 'Constants/RoutePaths';
import RouteStatusEnum from 'Constants/RouteStatusEnum';
import ErrorBoundary from 'Components/SharedUI/ErrorBoundary';
import Redirect from 'Components/Redirect';

const { STATUS_AUTHENTICATED } = RouteStatusEnum;
const {
  PATH_HOME,
  PATH_LOGIN,
  PATH_PRICING,
  PATH_DOCS,
  PATH_PAGE_NOT_FOUND,
  PATH_REPORTS,
  PATH_REPORTS_AIR,
  PATH_REPORTS_SURFACE,
  PATH_OVERVIEW,
  PATH_DEVICES,
  PATH_FLOORPLANS,
  PATH_DEVICE_DETAILS,
  PATH_DEVICE_DETAILS_OLD,
  PATH_ORGANIZATION,
  PATH_ORGANIZATION_DETAILS,
  PATH_ORGANIZATION_ALERT_CONFIGS,
  PATH_ORGANIZATION_API_KEYS,
  PATH_ORGANIZATION_INTEGRATIONS,
  PATH_ORGANIZATION_LOCATION_EDITOR,
  PATH_ORGANIZATION_SUBSCRIPTION,
  PATH_ORGANIZATION_SUBSCRIPTION_PRICING,
  PATH_ORGANIZATION_USERS,
  PATH_USER,
  PATH_USER_ACCESS_DENIED,
  PATH_USER_SETTINGS,
  PATH_ALERT_DETAILS,
  PATH_INFECTION,
  PATH_DEVICE_INFO,
  PATH_OCCUPANCY,
  PATH_MOLD,
  PATH_DEVICES_MAINT,
  PATH_ORGANIZATION_LOCATION,
  PATH_ADMIN_DEVICES,
} = RoutePaths;

const MAX_IDLE_USER_TIME_LIMIT = Number(process.env.REACT_APP_MAX_IDLE_USER_TIME_LIMIT);

export default function AuthRouter() {
  const { route } = useAuthenticator((context) => [context.route]);
  const [timeout, setTimeout] = useState<number>(MAX_IDLE_USER_TIME_LIMIT);
  const handleSignOut = useSignOut();
  const onIdle = () => {
    handleSignOut();
  };
  const onAction = () => {
    setTimeout(MAX_IDLE_USER_TIME_LIMIT);
  };

  useIdleTimer({ onAction, onIdle, timeout });

  const theme = useReactiveVar(appThemeVar);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <CssBaseline />
          <Routes>
            <Route
              path={PATH_HOME}
              element={
                route === STATUS_AUTHENTICATED ? <Navigate to={PATH_OVERVIEW} /> : <ExternalApp />
              }
            />
            <Route path={PATH_ADMIN_DEVICES} element={<ContinuumApp />} />
            <Route path={PATH_OVERVIEW} element={<ContinuumApp />} />
            <Route path={PATH_REPORTS_AIR} element={<ContinuumApp />} />
            <Route path={PATH_REPORTS_SURFACE} element={<ContinuumApp />} />
            <Route path={PATH_FLOORPLANS} element={<ContinuumApp />} />
            <Route path={PATH_REPORTS} element={<ContinuumApp />} />
            <Route path={PATH_ALERT_DETAILS} element={<ContinuumApp />} />
            <Route path={PATH_INFECTION} element={<ContinuumApp />} />
            <Route path={PATH_OCCUPANCY} element={<ContinuumApp />} />
            <Route path={PATH_MOLD} element={<ContinuumApp />} />
            <Route
              path={PATH_DEVICE_DETAILS_OLD}
              element={
                <Redirect
                  to={PATH_DEVICE_DETAILS}
                  redirectComponents={{ device_sr: 'device_sr' }}
                />
              }
            />
            <Route path={PATH_DEVICE_DETAILS} element={<ContinuumApp />} />
            <Route path={PATH_DEVICES_MAINT} element={<ContinuumApp />} />
            <Route path={PATH_DEVICES} element={<ContinuumApp />} />
            <Route path={PATH_ORGANIZATION}>
              <Route
                path={PATH_ORGANIZATION}
                element={<Navigate to={PATH_ORGANIZATION_DETAILS} />}
              />
              <Route path={PATH_ORGANIZATION_DETAILS} element={<ContinuumApp />} />
              <Route path={PATH_ORGANIZATION_API_KEYS} element={<ContinuumApp />} />
              <Route path={PATH_ORGANIZATION_ALERT_CONFIGS} element={<ContinuumApp />} />
              <Route path={PATH_ORGANIZATION_INTEGRATIONS} element={<ContinuumApp />} />
              <Route path={PATH_ORGANIZATION_LOCATION_EDITOR} element={<ContinuumApp />} />
              <Route path={PATH_ORGANIZATION_LOCATION} element={<ContinuumApp />} />
              <Route path={PATH_ORGANIZATION_USERS} element={<ContinuumApp />} />
              <Route path={PATH_ORGANIZATION_SUBSCRIPTION} element={<ContinuumApp />} />
              <Route path={PATH_ORGANIZATION_SUBSCRIPTION_PRICING} element={<ContinuumApp />} />
            </Route>
            <Route path={PATH_USER}>
              <Route path={PATH_USER} element={<Navigate to={PATH_USER_SETTINGS} />} />
              <Route path={PATH_USER_SETTINGS} element={<ContinuumApp />} />
            </Route>
            <Route path={PATH_LOGIN} element={<ExternalApp />} />
            <Route path={PATH_PRICING} element={<ExternalApp />} />
            <Route path={PATH_DOCS} element={<ExternalApp />} />
            <Route path={PATH_DEVICE_INFO} element={<ExternalApp />} />
            <Route path={PATH_PAGE_NOT_FOUND} element={<ErrorPageNotFound />} />
            <Route path={PATH_USER_ACCESS_DENIED} element={<AccessDeniedPage />} />
            <Route path='*' element={<Navigate to={PATH_PAGE_NOT_FOUND} />} />
          </Routes>
        </NotificationProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
