import { ArrowDownward as ArrowDownwardIcon } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import MigratorLocationTableView from '../components/MigratorLocationListView';
type OrgLocationMigratorViewProps = {
  selectedOrgId?: string;
  rootLocationId?: string;
};

export default function OrgLocationMigratorView({
  selectedOrgId,
  rootLocationId,
}: OrgLocationMigratorViewProps) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowDownwardIcon fontSize='small' />}>
        <Typography fontWeight={500}>Migrate to v2 Locations</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <MigratorLocationTableView selectedOrgId={selectedOrgId} rootLocationId={rootLocationId} />
      </AccordionDetails>
    </Accordion>
  );
}
