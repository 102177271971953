import { LocationOn } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Maybe } from '__generated__/graphql';
interface LocationNumImmediateSublocationsCellProps {
  numImmediateSubLocations?: Maybe<number>;
}

export default function LocationNumImmediateSublocationsCell({
  numImmediateSubLocations,
}: LocationNumImmediateSublocationsCellProps) {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
      <Typography fontWeight='bold' color='gray' variant='body2'>
        Immediate Sub-Locations
      </Typography>
      <Box display='flex' justifyContent='center' alignItems='center' gap={1}>
        <LocationOn
          sx={{
            height: 16,
            width: 16,
          }}
        />
        <Typography variant='body2'>{numImmediateSubLocations}</Typography>
      </Box>
    </Box>
  );
}
