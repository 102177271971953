/* eslint-disable no-magic-numbers */
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { gql, useMutation } from '@apollo/client';

import { GET_LOCATION_EDITOR_DATA } from 'Components/AuthedPages/OrgLocationEditorPage/OrgLocationEditorPage';
import { GET_ALL_SUB_LOCATIONS_QUERY } from 'Components/SharedUI/LocationSelector/LocationSelectorDropdown';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';

import {
  Maybe,
  RemoveLocationMutationMutation,
  RemoveLocationMutationMutationVariables,
} from '__generated__/graphql';
import { REMOVE_LOCATION_MUTATION } from './graphql';

type Props = {
  onModalClose: () => void;
  modalProps: unknown;
};

export default function RemoveLocationConfirmationModal({ modalProps, onModalClose }: Props) {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const { accountId, locationId, name, onRemoveLocation, parentLocationId, v2 } = modalProps as {
    accountId: string;
    locationId: string;
    name: string;
    parentLocationId: string;
    v2?: boolean;
    onRemoveLocation: (id: string) => void;
  };
  const [removeLocation, { loading: isMutationInFlight }] = useMutation<
    RemoveLocationMutationMutation,
    RemoveLocationMutationMutationVariables
  >(REMOVE_LOCATION_MUTATION, {
    update(cache) {
      cache.modify({
        id: cache.identify({ __typename: 'Location', id: parentLocationId }),
        fields: {
          immediateSublocations(existingSublocations = [], { readField }) {
            return existingSublocations.filter(
              (sublocation) => readField('id', sublocation) !== locationId
            );
          },
          numImmediateSubLocations(existingCount = 0) {
            return existingCount - 1;
          },
          numDevices(existingNumDevices = 0) {
            // Read the `numDevices` field of the location being removed
            const locationToRemove: { numDevices?: Maybe<number> } | null = cache.readFragment({
              id: cache.identify({ __typename: 'Location', id: locationId }),
              fragment: gql`
                fragment LocationDevices on Location {
                  numDevices
                }
              `,
            });
            const devicesToRemove = locationToRemove?.numDevices || 0;
            return existingNumDevices - devicesToRemove;
          },
        },
      });
      cache.evict({ id: cache.identify({ __typename: 'Location', id: locationId }) });
      cache.gc();
    },
  });
  const { dispatchToast } = useToast();

  const handleDelete = async () => {
    try {
      const variables = {
        accountId,
        locationId,
      };
      if (v2) {
        await removeLocation({
          variables,
        });
      } else {
        await removeLocation({
          variables,
          refetchQueries: [
            {
              query: GET_ALL_SUB_LOCATIONS_QUERY,
              variables: {
                accountId,
                locationId: parentLocationId,
              },
            },
            {
              query: GET_LOCATION_EDITOR_DATA,
              variables: {
                accountId,
                locationId: parentLocationId,
              },
            },
          ],
        });
      }
      onRemoveLocation(locationId);
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.SUCCESS,
        title: `Successfully removed Location: ${name}`,
      };
      onModalClose();
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Removing Location',
        message: 'Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR REMOVE_LOCATION_MUTATION: ', error);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>
        Delete Location{' '}
        <Typography variant='body1' color='text.secondary'>
          {name}
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography>
          Deleting a location is a permanent operation and cannot be un-done. <br />
          {v2
            ? 'Any data associated with this location will be assigned to the parent location.'
            : 'Any data associated with this location will also be removed.'}
        </Typography>

        <FormControlLabel
          sx={{ mt: 3 }}
          control={<Checkbox checked={isChecked} onClick={() => setIsChecked(!isChecked)} />}
          label='I understand and wish to continue'
        />
      </DialogContent>
      <Divider style={{ width: '100%' }} />
      <DialogActions>
        <Stack alignItems='center' justifyContent='flex-end' direction='row' gap={1}>
          <Button onClick={onModalClose} color='info'>
            Cancel
          </Button>
          <Button
            disabled={!isChecked || isMutationInFlight}
            onClick={(e) => (isMutationInFlight ? e.preventDefault() : handleDelete())}
            color='error'
            endIcon={isMutationInFlight && <CircularProgress size={16} />}
          >
            Delete
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
