/* eslint-disable no-magic-numbers */
import { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Button,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { gql, useMutation } from '@apollo/client';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import { useSignOut } from 'Hooks/useSignOut';
import { UserAgreement, UserAgreementType } from '__generated__/graphql';
import { Link as RouterLink } from 'react-router-dom';
import Footer from 'Components/Footer';
interface Props {
  onModalClose: () => void;
  modalProps?: unknown;
}

const ACCEPT_USER_AGREEMENT = gql`
  mutation AgreeOnUserAgreement($userAgreement: UserAgreementInput!) {
    agreeOnUserAgreement(userAgreement: $userAgreement) {
      agreements {
        agreedAt
        type
        version
      }
    }
  }
`;

interface UserAgreementProps {
  agreements?: UserAgreement[] | null;
  onSuccess: () => void;
}

const AGREEMENT_VERSION = '1.0';

export default function UserAgreementModal({ onModalClose, modalProps }: Props) {
  const { agreements, onSuccess } = modalProps as UserAgreementProps;
  const { dispatchToast } = useToast();
  const handleSignout = useSignOut();
  const [currAgr, setCurrAgr] = useState<[boolean | undefined, boolean | undefined]>([
    agreements?.some((ag) => ag.type === UserAgreementType.PrivacyPolicy),
    agreements?.some((ag) => ag.type === UserAgreementType.PortalAccessAgreement),
  ]);

  const [acceptAgreement, { loading }] = useMutation(ACCEPT_USER_AGREEMENT);
  const cancelAgreement = useCallback(() => {
    onModalClose();
    handleSignout();
    dispatchToast({
      severity: ToastNotificationSeverityTypeEnum.WARNING,
      title: 'You must accept the agreements to access the portal',
    });
  }, [handleSignout, onModalClose, dispatchToast]);
  const acceptAgeementHandler = useCallback(() => {
    const leftoverAgreements = [
      UserAgreementType.PortalAccessAgreement,
      UserAgreementType.PrivacyPolicy,
    ].filter((t) =>
      agreements && agreements.length > 0 ? agreements?.some((ag) => ag.type !== t) : true
    );
    Promise.all(
      leftoverAgreements.map((t) =>
        acceptAgreement({
          variables: {
            userAgreement: {
              type: t,
              version: AGREEMENT_VERSION,
            },
          },
        })
      )
    )
      .then((d) => {
        if (d.some((r) => r.errors)) {
          throw new Error('Agreement Acceptance Failed');
        }
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Agreement Accepted Successfully',
        });
        onSuccess();
      })
      .catch((e) => {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Could not accept agreement',
          message: e.message,
        });
      })
      .finally(() => {
        onModalClose();
      });
  }, [dispatchToast, acceptAgreement, onModalClose, agreements, onSuccess]);
  return (
    <Dialog open={true} onClose={cancelAgreement} fullWidth maxWidth='sm'>
      <DialogTitle>User Agreement</DialogTitle>
      <IconButton
        onClick={cancelAgreement}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography mb={2} variant='body1'>
          Accepting the following agreements is required for continued use of this service.
        </Typography>
        <Stack>
          <FormControlLabel
            control={
              <Checkbox
                checked={currAgr[0]}
                onChange={(e) => setCurrAgr((prev) => [e.target.checked, prev[1]])}
              />
            }
            label={
              <Typography variant='body2'>
                I acknowledge that I have read and agree to{' '}
                <Link
                  component={RouterLink}
                  to='https://uvangel.com/privacy-policy/'
                  target='_blank'
                  rel='noopener noreferrer'
                  underline='none'
                  fontWeight={500}
                >
                  UV Angel Cloud™ Privacy Policy
                </Link>
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currAgr[1]}
                onChange={(e) => setCurrAgr((prev) => [prev[0], e.target.checked])}
              />
            }
            label={
              <Typography variant='body2'>
                I acknowledge that I have read and agree to{' '}
                <Link
                  component={RouterLink}
                  to='https://uvangel.com/portal-access-agreement/'
                  target='_blank'
                  rel='noopener noreferrer'
                  underline='none'
                  fontWeight={500}
                >
                  UV Angel Cloud™ Access Agreement
                </Link>
              </Typography>
            }
          />
          <Footer />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box width='100%' gap={1} display='flex' justifyContent='flex-end' alignItems='center'>
          <Button
            color='primary'
            sx={{ fontWeight: 500 }}
            onClick={cancelAgreement}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            disabled={loading || !currAgr.every((ag) => ag)}
            color='error'
            sx={{ fontWeight: 500 }}
            onClick={acceptAgeementHandler}
            endIcon={loading && <CircularProgress color='inherit' size={16} />}
          >
            Agree
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
