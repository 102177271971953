/* eslint-disable no-magic-numbers */
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { deviceMap } from 'Components/AuthedPages/DevicesListPage/PageViews/DevicesListFilters';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { useCallback, useState } from 'react';

interface Props {
  onModalClose: () => void;
  modalProps?: unknown;
}

type DeviceChangeLocationModalProps = {
  serialNumber?: string | null;
  type?: string | null;
  locationId?: string | null;
  onSuccess: () => Promise<void>;
};

const REGISTER_DEVICES = gql`
  mutation registerDevices($accountId: ID!, $locationId: ID!, $deviceIdentifiers: [String]!) {
    registerDevices(
      accountId: $accountId
      locationId: $locationId
      deviceIdentifiers: $deviceIdentifiers
    ) {
      numUpdated
      numCreated
      numFailed
      failedDeviceSerials
      __typename
    }
  }
`;

export default function DeviceChangeLocationModal({ onModalClose, modalProps }: Props) {
  const { serialNumber, onSuccess, type, locationId } =
    modalProps as DeviceChangeLocationModalProps;
  const { dispatchToast } = useToast();
  const selectedOrg = useReactiveVar(selectedOrgVar);

  const [selectedLocationId, setSelectedLocationId] = useState(locationId);

  const [deviceChangeLocation, { loading }] = useMutation(REGISTER_DEVICES);
  const changeLocationHandler = useCallback(() => {
    if (!selectedOrg?.id || !serialNumber) {
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.WARNING,
        title: 'Unexpected error occurred',
        message: 'Please contact support',
      });
      return;
    }
    deviceChangeLocation({
      variables: {
        accountId: selectedOrg?.id,
        locationId: selectedLocationId,
        deviceIdentifiers: [serialNumber],
      },
    })
      .then((d) => {
        if (d.errors) {
          throw new Error('Changing Device Location Failed');
        }
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Device Location Updated',
        });
        onSuccess();
      })
      .catch((e) => {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Could not update device location',
          message: e.message,
        });
      })
      .finally(() => {
        onModalClose();
      });
  }, [
    serialNumber,
    selectedOrg,
    dispatchToast,
    onSuccess,
    deviceChangeLocation,
    onModalClose,
    selectedLocationId,
  ]);
  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Change Device Location</DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography variant='body1'>
          Change location for the following <b>{deviceMap[type ?? '']?.value ?? ''}</b> device
        </Typography>
        <Typography variant='subtitle1'>{serialNumber}</Typography>
        <Box display='flex' mt={2} p={1} bgcolor='background.paper' borderRadius={1}>
          <LocationSelectorBreadcrumb
            onLocationSelectCallback={(id) => setSelectedLocationId(id)}
            dontUpdateGlobal
            defaultLocationId={locationId as string | undefined}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width='100%' gap={1} display='flex' justifyContent='flex-end' alignItems='center'>
          <Button
            color='primary'
            sx={{ fontWeight: 500 }}
            onClick={onModalClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            color='error'
            sx={{ fontWeight: 500 }}
            onClick={changeLocationHandler}
            endIcon={loading && <CircularProgress color='inherit' size={16} />}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
