/* eslint-disable indent */
/* eslint-disable no-magic-numbers */
import { useTheme } from '@emotion/react';
import type { GaugeColor, GaugeConfigType, RangeConfigType } from 'Constants/OverViewTypes';
import { Doughnut } from 'react-chartjs-2';

type Props = {
  gaugeConfig: GaugeConfigType
};

function sumArray(arr): number {
  return arr.reduce((acc, val) => acc + val, 0);
}

const convertRangeConfigToChartJSData = (rangeConfig: RangeConfigType, minValue: number, maxValue: number): {
  labels: string[],
  colors: GaugeColor[],
  data: number[]
} => {
  let sectionsArray: { labels: string[], colors: GaugeColor[], data: number[] } = {
    labels: [],
    colors: [],
    data: [],
  };
  if (!rangeConfig || rangeConfig.length === 0) {
    return sectionsArray;
  }
  if (rangeConfig.length === 1) {
    // if only one range, then the whole gauge is that range
    sectionsArray = {
      labels: [rangeConfig[0].tooltip?.defaultText || ''],
      colors: [rangeConfig[0].color],
      data: [maxValue - minValue],
    };
    return sectionsArray;
  }

  // multi-section gauge, must build the array of sections
  const gaugeSections = rangeConfig.map((range, index) => {
    const sectionLabel = range.tooltip?.defaultText || '';
    let sectionData: number;
    if (index === 0) {
      sectionData = range.limit - minValue;
    } else {
      sectionData = range.limit - rangeConfig[index - 1].limit;
      if (index === rangeConfig.length - 1 && maxValue > range.limit) {
        // if maxValue is greater than the last limit, then we need to increase last section for the remainder
        sectionData = maxValue - rangeConfig[index - 1].limit;
      }
    }
    
    return {
      label: sectionLabel,
      color: range.color,
      data: sectionData,
    };
  });
  sectionsArray.labels = gaugeSections.map((section) => section.label);
  sectionsArray.colors = gaugeSections.map((section) => section.color);
  sectionsArray.data = gaugeSections.map((section) => section.data);

  return sectionsArray;
};

const convertRangeConfigToAvgChartJSData = (gaugeSections: {
    labels: string[];
    colors: GaugeColor[];
    data: number[];
  }, highlightRange: {
    minValue: number;
    maxValue: number;
    label: string;
  },
  minValue: number): number[] => {

  const totalGaugeSections = sumArray(gaugeSections.data);
  const firstAvgLimit = highlightRange.minValue - minValue;
  const secondAvgLimit = highlightRange.maxValue - highlightRange.minValue;
  const thirdAvgLimit = totalGaugeSections - (firstAvgLimit + secondAvgLimit);
  return [firstAvgLimit, secondAvgLimit, thirdAvgLimit];
};

const POINTER_WIDTH = 10;
const CIRCUMFERENCE = 180;
const ROTATION = 270;

export default function MetricGauge({ gaugeConfig }: Props) {
  const {
    rangeConfig,
    minValue,
    maxValue,
    valueSuffix,
    value,
    mainGaugeLabel, // if this is provided, we'll use if, otherwise we'll use 'value + suffix' like we do for labels
    highlightRange
  } = gaugeConfig;

  const theme = useTheme();
  const gaugeSections = convertRangeConfigToChartJSData(rangeConfig, minValue, maxValue);

  const showAvgRange = highlightRange !== undefined ? true : false;
  let avgGaugeSections: number[] = [];
  if (showAvgRange && highlightRange?.minValue !== undefined && highlightRange?.maxValue !== undefined) {
    if (highlightRange.minValue > highlightRange.maxValue) { // order is wrong, swap them to render and fix mistake
      const originalMinHighlighValue = highlightRange.minValue;
      highlightRange.minValue = highlightRange.maxValue;
      highlightRange.maxValue = originalMinHighlighValue;
    }
    if (highlightRange.minValue < minValue) {
      highlightRange.minValue = minValue;
    }
    if (highlightRange.maxValue > maxValue) {
      highlightRange.maxValue = maxValue;
    }
    avgGaugeSections = convertRangeConfigToAvgChartJSData(gaugeSections, highlightRange, minValue);
  }

  const doughnutPointer = {
    id: 'doughnutPointer',
    // pointer value + text in middle: https://www.youtube.com/watch?v=6HC-PiSYbUk
    afterDatasetsDraw: (chart, _args, _plugins) => {
      const {
        ctx, data
      } = chart;

      // Setup initial values for the pointer
      const centerX = chart.getDatasetMeta(0).data[0].x;
      const centerY = chart.getDatasetMeta(0).data[0].y;
      const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
      const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
      const doughnutThickness = outerRadius - innerRadius;
      const angle = Math.PI / CIRCUMFERENCE;

      const dataPointArray = data.datasets[0].data.map((datapoint, _index) => {
        return datapoint;
      });

      
      if (value === null || value === undefined) {
        return;
      }
      let datapointValue = value - minValue;
      const totalSum = sumArray(dataPointArray);
      if (datapointValue > totalSum) {
        datapointValue = totalSum;
      } else if (datapointValue < 0) {
        datapointValue = 0;
      }
      // used to be - 90 (experimenting)
      const targetPointerRotation = ((datapointValue / totalSum) * CIRCUMFERENCE) - (360 - ROTATION);

      ctx.save();
      // set the center point of the chart as the origin
      ctx.translate(centerX, centerY);
      ctx.rotate(angle * targetPointerRotation);

      // now we draw the indicator
      ctx.beginPath();
      ctx.fillStyle = theme['palette'].text.primary;
      const pointerExtensionMultiplier = 1.5;
      const pointerHeight = doughnutThickness * pointerExtensionMultiplier;
      const pointerHeightOffset = (pointerHeight - doughnutThickness) / 2;
      const pointerCornerRadius = 5;
      ctx.roundRect(0 - (POINTER_WIDTH / 2), 0 - outerRadius - pointerHeightOffset, POINTER_WIDTH, pointerHeight, pointerCornerRadius);
      ctx.fill();

      // restore the default context stuff, resetting center points
      ctx.restore();

      // draw the center text
      ctx.font = `30px ${theme['typography'].fontFamily}`;
      ctx.fillStyle = theme['palette'].text.secondary;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      if (mainGaugeLabel) {
        ctx.fillText(mainGaugeLabel, centerX, centerY);
      } else {
        ctx.fillText(`${value} ${valueSuffix}`, centerX, centerY);
      }
    }
  };

  return (
    <Doughnut
      redraw={true}
      data={{
        labels: gaugeSections.labels,
        datasets: [
          {
            label: 'Range',
            data: gaugeSections.data,
            backgroundColor: gaugeSections.colors.map((gaugeValue, index) => {
              if (value === undefined || index >= rangeConfig.length) {
                return gaugeValue.default;
              }
              if (index === 0) {
                return value < rangeConfig[index].limit ? gaugeValue.selected : gaugeValue.default;
              }
              if (index === (rangeConfig.length - 1)) {
                return value >= rangeConfig[index - 1].limit ? gaugeValue.selected : gaugeValue.default;
              }
              return value >= rangeConfig[index-1].limit && value < rangeConfig[index].limit ? gaugeValue.selected : gaugeValue.default;
            }),
            borderColor: theme['palette'].background.paper,
            borderWidth: 3,
            borderRadius: 10,
          },
          {
            label: 'Historical Avg Range',
            data: avgGaugeSections,
            backgroundColor: 'transparent',
            borderColor: ['transparent', 'lightgray', 'transparent'],
            borderRadius: 10,
            offset: -10,
            hidden: !showAvgRange,
          }
        ]
      }}
      plugins={[doughnutPointer]}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        // high resolution screen support - not entirely sure why we need it for this chart and not others
        devicePixelRatio: 4,
        rotation: ROTATION, // start angle in degrees
        circumference: CIRCUMFERENCE, // sweep angle in degrees
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            filter: (tooltipItem) => {
              if (tooltipItem.datasetIndex === 1) {
                if (tooltipItem.dataIndex === 1) {
                  return true;
                }
                return false;
              }
              return true;
            },
            callbacks: {
              title: (context) => {
                const datasetIndex = context?.[0]?.datasetIndex;
                if (datasetIndex === 1) {
                  return highlightRange?.label || '';
                }
              },
              label: (context) => {
                const datasetIndex = context.datasetIndex;
                if (datasetIndex === 0) {
                  // the regular index
                  const index = context.dataIndex;
                  if (index >= rangeConfig.length) {
                    console.error('out of bounds - cannot display range');
                    return;
                  }
                  if (index === 0) {
                    return `<${rangeConfig[index].limit}  ${valueSuffix}`;
                  }
                  if (index === (rangeConfig.length - 1)) {
                    return `${rangeConfig[index - 1].limit}+  ${valueSuffix}`;
                  }
                  return `${rangeConfig[index - 1].limit} - ${rangeConfig[index].limit} ${valueSuffix}`;
                } else if (datasetIndex === 1) {
                  // avg set index - only have to worry about the range, because otherwise this is filtered out by title callback
                  return `${highlightRange?.minValue} - ${highlightRange?.maxValue} ${valueSuffix}`;
                }
              },
            },
          },
        },
        layout: {
          padding: {
            bottom: POINTER_WIDTH / 2,
            // note: these should really be the pointerHeightOffset, but thats calculated inside plugin at this time
            left: 10,
            right: 10,
          }
        },
        cutout: showAvgRange ? '60%' : '80%',
      }}
    />
  );
}
