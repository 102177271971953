import { CurrentUserDataType, SelectedOrgType } from 'Apollo/ApolloCache';
import { MAX_HASH_UINT_32, murmurhash } from './murmurhash';
import { AccountRoles, GlobalRoles, Maybe } from '__generated__/graphql';
import { FEATURE_FLAG_RULE_DEFINITIONS, FeatureFlagFeatures } from './FeatureFlagRuleDefinitions';

type Environments = 'local' | 'development' | 'production';

// re-exports flag features definitions from here to help with clean looking imports throughout codebase
export { FeatureFlagFeatures } from './FeatureFlagRuleDefinitions';

/**
 * Object that lists all the requirements in order for this rule to pass.
 * All rules specified in a FeatureFlagRule object must be true for the rule to enable the feature.
 */
export type FeatureFlagRule = {
  // percentage of users that this feature is visible to
  percentageOfUsers?: number;
  // percentage of orgs that this feature is visible to
  percentageOfOrgs?: number;
  // note: inclusive. all provided roles must be present on user
  requiredGlobalRoles?: (keyof GlobalRoles)[];
  // note: inclusive. all provided roles must be present on user
  requiredOrgRoles?: (keyof AccountRoles)[];
  // eligible environments for this feature. note: exclusive - only 1 must be true
  eligibleEnvironments?: Environments[];
  //
} & (
  | {
      percentageOfUsers: number;
    }
  | {
      percentageOfOrgs: number;
    }
  | { requiredGlobalRoles: (keyof GlobalRoles)[] }
  | { requiredOrgRoles: (keyof AccountRoles)[] }
  | { eligibleEnvironments: Environments[] }
);

/**
 * Uses defined rules set to determine if the specified user/organization can access a certain feature.
 * If a feature is defined in Account.featureFlags then that will take precedence over rules defined in src/Utils/FeatureFlags/FeatureFlagRuleDefinitions.ts
 * @param featureName the feature name in question
 * @param user the current user
 * @param organization the currently active organization
 * @returns `true` if feature can be viewed, `false` otherwise.

 */
export function canViewFeature(
  featureName: FeatureFlagFeatures,
  user?: CurrentUserDataType,
  organization?: SelectedOrgType
) {
  const rules = FEATURE_FLAG_RULE_DEFINITIONS[featureName];
  if (rules === undefined || rules === null) {
    console.error(
      `invalid feature flag "${featureName}" - feature flag must exist within context. disabling feature by default.`
    );
    return false;
  }
  if (
    organization?.featureFlags &&
    organization.featureFlags.length > 0 && // Org has some feature definitions
    organization.featureFlags.some((feature) => feature.key === featureName) // org has a feature definition matching the currently requested feature
  ) {
    return checkOrgFeatureEnabled(featureName, organization);
  }
  if (typeof rules === 'boolean') return rules;
  return rules.some((rule) => checkRule(rule, featureName, user, organization));
}

/**
 * validate a single rule object for a feature
 * @param FeatureFlagRule
 * @param featureName
 * @param user
 * @param organization
 * @returns
 */
function checkRule(
  {
    requiredOrgRoles,
    requiredGlobalRoles,
    percentageOfUsers,
    percentageOfOrgs,
    eligibleEnvironments,
  }: FeatureFlagRule,
  featureName: FeatureFlagFeatures,
  user?: CurrentUserDataType,
  organization?: SelectedOrgType
) {
  return (
    user && // user is always required
    organization && // (org) is always required
    userHasRequiredRoles(
      requiredGlobalRoles,
      requiredOrgRoles,
      user.currentUser?.globalRoles,
      organization.userAccountScope?.roles
    ) &&
    userIsWithinPercentage(featureName, percentageOfUsers, user.currentUser?.id) &&
    orgIsWithinPercentage(featureName, percentageOfOrgs, organization.id) &&
    environmentIsEligible(eligibleEnvironments)
  );
}

/**
 * roles-based feature rules engine check.
 * ensures all global roles are explicitly defined, allows account level roles to be superceded by global ones if present.
 * @param requiredGlobalRoles
 * @param requiredOrgRoles
 * @param currentUserGlobalRoles
 * @param currentUserOrgRoles
 * @returns `true` if roles rule is passed, `false` otherwise
 */
function userHasRequiredRoles(
  requiredGlobalRoles: (keyof GlobalRoles)[] | undefined,
  requiredOrgRoles: (keyof AccountRoles)[] | undefined,
  currentUserGlobalRoles: Maybe<GlobalRoles> | undefined,
  currentUserOrgRoles: Maybe<AccountRoles> | undefined
) {
  if (!requiredGlobalRoles && !requiredOrgRoles) {
    // no required roles to check - allow!
    return true;
  }

  const usersGlobalRoles = currentUserGlobalRoles ?? {};
  const usersOrgRoles = currentUserOrgRoles ?? {};

  if (requiredGlobalRoles?.length) {
    // required to check all these global roles
    if (!requiredGlobalRoles.every((role) => usersGlobalRoles[role])) {
      return false;
    }
  }

  if (requiredOrgRoles?.length) {
    if (!requiredOrgRoles.every((role) => usersGlobalRoles[role] || usersOrgRoles[role])) {
      return false;
    }
  }

  return true;
}

/**
 * "randomly" determines whether a certain user should be able to see a feature, based on % visibility defined in rule.
 * "randomly" = hash the feature + userId to determine constant value for a particular user, so features are consistent from session to session.
 * @param featureName
 * @param allowedPercent
 * @param userId
 * @returns `true` if user is within defined percentage, `false` otherwise
 */
function userIsWithinPercentage(
  featureName: FeatureFlagFeatures,
  allowedPercent: number | undefined,
  userId?: string
) {
  if (allowedPercent == null || !userId) return true;

  return murmurhash(`${featureName}-${userId}`) / MAX_HASH_UINT_32 < allowedPercent;
}

/**
 * "randomly" determines whether a certain organization should be able to see a feature, based on % visibility defined in rule.
 * "randomly" = hash the feature + orgId to determine constant value for a particular account so it is consistent for all in the account.
 * @param featureName
 * @param allowedPercent
 * @param orgId
 * @returns `true` if org is within defined percentage, `false` otherwise
 */
function orgIsWithinPercentage(
  featureName: FeatureFlagFeatures,
  allowedPercent: number | undefined,
  orgId?: string
) {
  if (allowedPercent == null || !orgId) return true;

  return murmurhash(`${featureName}-${orgId}`) / MAX_HASH_UINT_32 < allowedPercent;
}

/**
 * whether or not the current environment is eligible based on the rule definition
 * @param eligibleEnvironments
 * @returns `true` if environment is eligible, `false` otherwise
 */
function environmentIsEligible(eligibleEnvironments?: Environments[]) {
  if (!eligibleEnvironments) return true;

  return eligibleEnvironments.some((env) => env === process.env.REACT_APP_ENV);
}
/**
 *
 * @param featureName
 * @param organization
 * @returns `true` if feature is enabled in `org.featureFlags` or `false` otherwise
 */
function checkOrgFeatureEnabled(featureName: FeatureFlagFeatures, organization: SelectedOrgType) {
  return organization.featureFlags
    .filter((features) => features.enabled)
    .some((features) => featureName === features.key);
}
