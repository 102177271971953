import {
  Business as BuildingIcon,
  Map as FloorIcon,
  MeetingRoom,
  Warning,
} from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { LocationArchetype, Maybe } from '__generated__/graphql';
import { capitalize } from 'Utils/stringManipulation';

interface LocationArchetypeIconProps {
  archetype?: Maybe<LocationArchetype>;
}

const ARCHETYPE_ICONS = {
  [LocationArchetype.Building]: BuildingIcon,
  [LocationArchetype.Floor]: FloorIcon,
  [LocationArchetype.Room]: MeetingRoom,
};

export default function LocationArchetypeIcon({ archetype }: LocationArchetypeIconProps) {
  const ArchetypeIcon = archetype ? ARCHETYPE_ICONS[archetype] : void 0;
  return (
    <Tooltip
      title={capitalize(
        archetype?.toLocaleLowerCase() ?? 'No Archetype! Please move to V2 or delete.'
      )}
    >
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        {ArchetypeIcon ? <ArchetypeIcon /> : <Warning color='warning' />}
      </Box>
    </Tooltip>
  );
}
