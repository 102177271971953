import { useEffect, useState } from 'react';
import QuickMetricsCard from 'Components/MetricCards/QuickMetricsCard';
import { gaugeColors, metricCardIconColor } from 'Constants/OverviewConsts';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import RoutePaths from 'Constants/RoutePaths';
import { MetricReportTypeEnum } from 'Constants/FloorsViewEnums';
import type { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';

import type { GaugeConfigType, RangeConfigType } from 'Constants/OverViewTypes';
import { useTheme, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { cache } from 'Apollo/ApolloCache';
import { LOCATION_CORE_FRAGMENT } from 'fragments';
import { LocationCoreFragment } from '__generated__/graphql';
import MetricGauge from 'Components/MetricCards/ChartComponents/MetricGauge';

export type LocationHumidityCardContainerProps = {
  humidityDataToAverage?: (number | null | undefined)[];
  isLoading: boolean;
  startDate: string;
  endDate: string;
  selectedLocationID: string | undefined;
  selectedOrgID: string | undefined;
  gridConfig: GridConfigType;
  error?: boolean;
};

const TITLE = 'Relative Humidity';
const INFO_TEXT =
  'Relative humidity is a measure of how much moisture is in the air compared to how much it could hold at that temperature. Ideally, indoor humidity should be around 30-50%. When it’s too high, it can feel sticky, and mold or dust mites can grow more easily; when it’s too low, the air feels dry, which can irritate skin and lungs. Keeping humidity balanced creates a more comfortable indoor environment.';

export default function LocationHumidityCardContainer({
  humidityDataToAverage,
  isLoading,
  startDate,
  endDate,
  selectedLocationID,
  gridConfig,
  error,
}: LocationHumidityCardContainerProps) {
  const theme = useTheme();
  const [gaugeConfig, setGaugeConfig] = useState<GaugeConfigType>();

  useEffect(() => {
    const report = humidityDataToAverage ?? [];
    const percentHumidity =
      Math.floor(report.reduce((total, value) => (value ? value + (total ?? 0) : total), 0) ?? 0) /
      report.length;
    const { red, yellow, green } = gaugeColors;

    const rangeConfig: RangeConfigType = [
      {
        color: red,
        limit: 10,
        tooltip: {
          defaultText: 'poor humidity range',
        },
      },
      {
        color: yellow,
        limit: 20,
        tooltip: {
          defaultText: 'medium humidity range',
        },
      },
      {
        color: green,
        limit: 60,
        tooltip: {
          defaultText: 'ideal humidity range',
        },
      },
      {
        color: yellow,
        limit: 70,
        tooltip: {
          defaultText: 'medium humidity range',
        },
      },
      {
        color: red,
        limit: 80,
        tooltip: {
          defaultText: 'poor humidty range',
        },
      },
    ];

    setGaugeConfig({
      rangeConfig,
      value: isNaN(percentHumidity) ? undefined : percentHumidity,
      valueSuffix: '%',
      minValue: 0,
      maxValue: 100,
    });
  }, [humidityDataToAverage]);
  const selectedLocation = cache.readFragment<LocationCoreFragment>({
    id: `Location:${selectedLocationID}`,
    fragment: LOCATION_CORE_FRAGMENT
  });
  const numHoursDataRange = moment(endDate).diff(startDate, 'hour');
  return (
    <QuickMetricsCard
      isLoading={isLoading}
      icon={
        <WaterDropIcon sx={{ color: error ? theme.palette.error.main : metricCardIconColor }} />
      }
      title={TITLE}
      infoText={INFO_TEXT}
      link={`${RoutePaths.PATH_REPORTS}?locationID=${selectedLocationID}&selectedMetric=${MetricReportTypeEnum.HUMIDITY}`}
      gridConfig={gridConfig}
      error={error}
      showInfoInModal
    >
      {(gaugeConfig && (gaugeConfig.value !== null && gaugeConfig.value !== undefined)) ? <MetricGauge gaugeConfig={gaugeConfig} /> : null}
      {(!gaugeConfig ||
        (gaugeConfig && (gaugeConfig.value === null || gaugeConfig.value === undefined))) && (
        <>
          <Typography alignContent={'center'} color={'InactiveCaptionText'}>
            No data available for <b>{selectedLocation?.name}</b>
          </Typography>
          <Typography alignContent={'center'} fontStyle={'italic'} color={'InactiveCaptionText'}>
            in the last{' '}
            <b>
              {numHoursDataRange} {numHoursDataRange > 1 ? 'hours' : 'hour'}
            </b>
          </Typography>
        </>
      )}
    </QuickMetricsCard>
  );
}
