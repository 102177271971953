import { gql } from '@apollo/client';
import {
  LOCATION_CORE_FRAGMENT,
  LOCATION_METADATA_FRAGMENT,
  LOCATION_PARENTS_FRAGMENT,
  LOCATION_STATS_FRAGMENT,
} from 'fragments';

export const FETCH_LOCATION_V2 = gql`
  ${LOCATION_CORE_FRAGMENT}
  ${LOCATION_STATS_FRAGMENT}
  ${LOCATION_METADATA_FRAGMENT}
  ${LOCATION_PARENTS_FRAGMENT}
  query LocationV2($accountId: ID!, $locationId: ID!) {
    location(accountId: $accountId, locationId: $locationId) {
      ...LocationCore
      immediateSublocations {
        ...LocationCore
        ...LocationStats
        ...LocationParents
        ...LocationMetadata
      }
    }
  }
`;
