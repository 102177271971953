/* eslint-disable no-magic-numbers */
import { gql, useQuery } from '@apollo/client';
import { Grain as GrainIcon } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import { GetLocationMoldRiskQuery, GetLocationMoldRiskQueryVariables } from '__generated__/graphql';
import MetricGauge from 'Components/MetricCards/ChartComponents/MetricGauge';
import QuickMetricsCard, { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';
import { gaugeColors, metricCardIconColor } from 'Constants/OverviewConsts';
import { GaugeConfigType } from 'Constants/OverViewTypes';
import RoutePaths from 'Constants/RoutePaths';
import moment from 'moment-timezone';
import { Fragment, useMemo } from 'react';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';

export const LOCATION_MOLD_RISK = gql`
  query GetLocationMoldRisk($input: LocationsMoldRiskInput!) {
    report {
      uvangel {
        getLocationMoldRisk(input: $input) {
          normalizedMoldRisk
          minimumDaysToGrow
        }
      }
    }
  }
`;

const TITLE = 'Mold Risk';
const INFO_TEXT =
  'Mold is a type of fungus that grows in damp or humid areas, like bathrooms, kitchens, or basements. It can release tiny spores into the air that can trigger allergies, worsen asthma, and even cause respiratory issues. Keeping indoor spaces dry and well-ventilated helps prevent mold growth and keeps the air healthier and safer to breathe.';

export interface MoldRiskProps {
  selectedOrgId: string;
  selectedLocation: Record<'id' | 'name', string>;
  gridConfig: GridConfigType;
  startDate?: string;
  endDate?: string;
}

export default function MoldRiskCardContainer({
  selectedOrgId,
  selectedLocation,
  gridConfig,
  startDate: startDateFromProps,
  endDate: endDateFromProps,
}: MoldRiskProps) {
  const theme = useTheme();
  const [startDate, endDate] = useMemo(() => {
    if (
      (!startDateFromProps && !!endDateFromProps) ||
      (!!startDateFromProps && !endDateFromProps)
    ) {
      throw new Error('Please Provide both startDate and endDate');
    }

    if (startDateFromProps && endDateFromProps) {
      return [startDateFromProps, endDateFromProps];
    }

    return getPastDateTimeRange(1, 'hour');
  }, [startDateFromProps, endDateFromProps]);

  const {
    data: moldRiskReport,
    loading: isMoldRiskReportLoading,
    error: moldRiskReportError,
  } = useQuery<GetLocationMoldRiskQuery, GetLocationMoldRiskQueryVariables>(LOCATION_MOLD_RISK, {
    variables: {
      input: {
        accountId: selectedOrgId,
        locationId: selectedLocation.id,
        timeRange: {
          startDate,
          endDate,
        },
      },
    },
  });

  const gaugeConfig: GaugeConfigType | undefined = useMemo(() => {
    const normalizedMoldRisk =
      moldRiskReport?.report?.uvangel?.getLocationMoldRisk?.normalizedMoldRisk;
    if (!normalizedMoldRisk && normalizedMoldRisk !== 0) return;
    let mainGaugeLabel = '';
    if (normalizedMoldRisk <= 33) {
      mainGaugeLabel = 'Low';
    } else if (normalizedMoldRisk <= 66) {
      mainGaugeLabel = 'Moderate';
    } else if (normalizedMoldRisk > 66) {
      mainGaugeLabel = 'High';
    }
    const rangeConfig = [
      {
        color: gaugeColors.green,
        limit: 33,
        tooltip: {
          defaultText: 'Low Mold Risk range',
        },
      },
      {
        color: gaugeColors.yellow,
        limit: 66,
        tooltip: {
          defaultText: 'Moderate Mold risk range',
        },
      },
      {
        color: gaugeColors.red,
        limit: 100,
        tooltip: {
          defaultText: 'High Mold risk range',
        },
      },
    ];
    return {
      rangeConfig,
      value: normalizedMoldRisk,
      minValue: 0,
      maxValue: 100,
      valueSuffix: '%',
      mainGaugeLabel: mainGaugeLabel,
    };
  }, [moldRiskReport]);

  return (
    <QuickMetricsCard
      title={TITLE}
      infoText={INFO_TEXT}
      link={RoutePaths.PATH_MOLD}
      icon={
        <GrainIcon
          sx={{ color: moldRiskReportError ? theme.palette.error.main : metricCardIconColor }}
        />
      }
      isLoading={isMoldRiskReportLoading}
      gridConfig={gridConfig}
      error={!!moldRiskReportError}
      showInfoInModal
    >
      {gaugeConfig ? (
        <MetricGauge gaugeConfig={gaugeConfig} />
      ) : (
        <Fragment>
          <Typography alignContent={'center'} color={'InactiveCaptionText'}>
            No data available for <b>{selectedLocation?.name}</b>
          </Typography>
          <Typography alignContent={'center'} fontStyle={'italic'} color={'InactiveCaptionText'}>
            since {moment(startDate).fromNow()}
          </Typography>
        </Fragment>
      )}
    </QuickMetricsCard>
  );
}
